import { Color } from '../../../../color.enum'
import { SURVEY_STATUS } from '../../../../common/constants'
import Button, { ButtonVariant, ButtonSize } from '../../../../components/Button'
import Icon from '../../../../components/Icon/Icon'
import { Badge } from '@plusplusminus/plusplusdash'
import { formatDate } from '../../../../services/formatDate'
import { navigate } from '@reach/router'
import { gql, useMutation } from '@apollo/client'
import RemoveItemModal from '../components/RemoveItemModal'
import { useState } from 'react'

interface ItemCtaData {
  color: Color
  id: string
  surveyStatus?: string
  refetch?: any
}

interface ItemMetaData {
  createdAt: Date
  status?: string
  locale?: string
 
}

export const ListItemCta: React.FC<ItemCtaData> = ({ color, id, surveyStatus, refetch }) => {
  const [isOpen, setIsOpen] = useState(false)

  const [deleteStatement, { loading}] = useMutation(DELETE_STATEMENT, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      refetch()
      setIsOpen(false)
    }
  })

  
  const handleCloseRemoveStatement = () => {
    setIsOpen(false)
  }

  const handleRemoveStatement = (id: string) => {
    deleteStatement({
      variables:{
        id: id
      }
    })
  }

  return (
    <div className="flex space-x-2">
      <RemoveItemModal
        isOpen={isOpen}
        handleClose={handleCloseRemoveStatement}
        handleConfirm={() => handleRemoveStatement(id)}
      />
      {surveyStatus !== 'PUBLISHED' && (
        <Button variant={ButtonVariant.PLAIN} color={color} size={ButtonSize.SMALL} onClick={() => setIsOpen(true)}>
          {loading ? 'Removing Statement...' : 'Remove Statement' }
        </Button>
      )}
      <Button
        variant={ButtonVariant.PRIMARY}
        color={color}
        size={ButtonSize.SMALL}
        onClick={() => navigate(`/dashboard/admin/statement/${id}`)}
        className="mr-3"
        iconRight="arrowRight"
      >
        View details
      </Button>
    </div>
  )
}

export const ListItemMeta: React.FC<ItemMetaData> = ({ createdAt, status, locale }: ItemMetaData) => {
  return (
    <div className="space-y-1">
      <p className="text-sm text-gray-900">
        Date Created: <time dateTime={'2020-01-07'}>{formatDate(createdAt)}</time>
      </p>
      <p className="flex items-center text-sm text-gray-500">
        <Badge color={status === SURVEY_STATUS.PUBLISHED ? 'green' : status === SURVEY_STATUS.CLOSED ? 'red' : 'blue'}>
          {status === SURVEY_STATUS.PUBLISHED && <Icon name="checkSolid" className="mr-2" />} {status}
        </Badge>
      </p>
      <p className="text-sm text-gray-900">Locale: {locale}</p>
    </div>
  )
}

const DELETE_STATEMENT = gql`
  mutation deleteStatement($id: String!) {
    deleteStatement(id: $id) {
      status
      message
    }
  }
`
