import React, { useEffect } from 'react'
import { RouteComponentProps, Redirect, useMatch } from '@reach/router'
import { useUserQuery } from '../../hooks/useUserQuery'
import Loader from '../Loader/Loader'
import { Color } from '../../color.enum'
import { USER_ROLES } from '../../common/constants'
import { Unauthorized } from './Unauthorized'

interface IAdminPrivateRoute extends RouteComponentProps {
  component: React.ElementType
  redirect?: string
  fetch?: boolean
}

const AdminPrivateRoute: React.FC<IAdminPrivateRoute> = ({ component: Component, ...props }) => {
  const march = useMatch('/dashboard/admin')
  const { loading, data, error, refetch } = useUserQuery()

  useEffect(() => {
    if (march) {
      refetch()
    }
  }, [])

  if (loading) return <Loader color={Color.PURPLE} />
  if (error) return <Redirect noThrow to={'/login'} />

  const role = data?.me?.role

  if (role === USER_ROLES.ADMIN) {
    return <Component {...props} user={data.me} />
  } else {
    return <Unauthorized />
  }
}

export { AdminPrivateRoute }
