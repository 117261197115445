import React, { FC } from 'react'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { classNames } from '../../utils/class-names'

// When adding a new icon, also update the IconType file
import { IconType } from './Icon.options'
import {
  faCircle1,
  faCircle2,
  faCircle3,
  faCircle4,
  faCircle5,
  faCircle6,
  faCircle7,
  faCircle8,
  faCircle9,
  faAlarmClock,
  faArrowRight,
  faArrowLeft,
  faArrowUp,
  faArrowDown,
  faArrowToBottom,
  faBars,
  faChartPie,
  faCheck,
  faClock,
  faCheckCircle,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faExclamationCircle,
  faExclamationTriangle,
  faGripLinesVertical,
  faInfoCircle,
  faPlus,
  faSearch,
  faSignOut,
  faSignIn,
  faSync,
  faTimes,
  faUnlockKeyhole,
  faHome,
  faHomeLgAlt,
  faFilePdf
} from '@fortawesome/pro-light-svg-icons'
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
import { faCheck as faCheckSolid } from '@fortawesome/pro-solid-svg-icons'

export interface IconProps {
  /** Name of the icon */
  name: IconType
  /** FontAwesomeIcon Props */
  fontAwesomeIconProps?: Omit<FontAwesomeIconProps, 'icon'>
  /** Aditional Styles for the container */
  className?: string
}
const renderIcon = (name: IconType, fontAwesomeIconProps?: Omit<FontAwesomeIconProps, 'icon'>): React.ReactNode => {
  switch (name) {
    case 'one':
      return <FontAwesomeIcon icon={faCircle1} {...fontAwesomeIconProps} />
    case 'two':
      return <FontAwesomeIcon icon={faCircle2} {...fontAwesomeIconProps} />
    case 'three':
      return <FontAwesomeIcon icon={faCircle3} {...fontAwesomeIconProps} />
    case 'four':
      return <FontAwesomeIcon icon={faCircle4} {...fontAwesomeIconProps} />
    case 'five':
      return <FontAwesomeIcon icon={faCircle5} {...fontAwesomeIconProps} />
    case 'six':
      return <FontAwesomeIcon icon={faCircle6} {...fontAwesomeIconProps} />
    case 'seven':
      return <FontAwesomeIcon icon={faCircle7} {...fontAwesomeIconProps} />
    case 'eight':
      return <FontAwesomeIcon icon={faCircle8} {...fontAwesomeIconProps} />
    case 'nine':
      return <FontAwesomeIcon icon={faCircle9} {...fontAwesomeIconProps} />
    case 'alarm':
      return <FontAwesomeIcon icon={faAlarmClock} {...fontAwesomeIconProps} />
    case 'arrowRight':
      return <FontAwesomeIcon icon={faArrowRight} {...fontAwesomeIconProps} />
    case 'arrowLeft':
      return <FontAwesomeIcon icon={faArrowLeft} {...fontAwesomeIconProps} />
    case 'arrowUp':
      return <FontAwesomeIcon icon={faArrowUp} {...fontAwesomeIconProps} />
    case 'arrowDown':
      return <FontAwesomeIcon icon={faArrowDown} {...fontAwesomeIconProps} />
    case 'check':
      return <FontAwesomeIcon icon={faCheck} {...fontAwesomeIconProps} />
    case 'checkSolid':
      return <FontAwesomeIcon icon={faCheckSolid} {...fontAwesomeIconProps} />
    case 'clock':
      return <FontAwesomeIcon icon={faClock} {...fontAwesomeIconProps} />
    case 'chevronUp':
      return <FontAwesomeIcon icon={faChevronUp} {...fontAwesomeIconProps} />
    case 'chevronDown':
      return <FontAwesomeIcon icon={faChevronDown} {...fontAwesomeIconProps} />
    case 'chevronLeft':
      return <FontAwesomeIcon icon={faChevronLeft} {...fontAwesomeIconProps} />
    case 'chevronRight':
      return <FontAwesomeIcon icon={faChevronRight} {...fontAwesomeIconProps} />
    case 'close':
      return <FontAwesomeIcon icon={faTimes} {...fontAwesomeIconProps} />
    case 'download':
      return <FontAwesomeIcon icon={faArrowToBottom} {...fontAwesomeIconProps} />
    case 'error':
      return <FontAwesomeIcon icon={faExclamationCircle} {...fontAwesomeIconProps} />
    case 'home':
      return <FontAwesomeIcon icon={faHome} {...fontAwesomeIconProps} />
    case 'homeLgAlt':
      return <FontAwesomeIcon icon={faHomeLgAlt} {...fontAwesomeIconProps} />
    case 'grip':
      return <FontAwesomeIcon icon={faGripLinesVertical} {...fontAwesomeIconProps} />
    case 'info':
      return <FontAwesomeIcon icon={faInfoCircle} {...fontAwesomeIconProps} />
    case 'loader':
      return <FontAwesomeIcon icon={faSpinnerThird} {...fontAwesomeIconProps} />
    case 'menu':
      return <FontAwesomeIcon icon={faBars} {...fontAwesomeIconProps} />
    case 'plus':
      return <FontAwesomeIcon icon={faPlus} {...fontAwesomeIconProps} />
    case 'private':
      return <FontAwesomeIcon icon={faUnlockKeyhole} {...fontAwesomeIconProps} />
    case 'results':
      return <FontAwesomeIcon icon={faChartPie} {...fontAwesomeIconProps} />
    case 'search':
      return <FontAwesomeIcon icon={faSearch} {...fontAwesomeIconProps} />
    case 'signIn':
      return <FontAwesomeIcon icon={faSignIn} {...fontAwesomeIconProps} />
    case 'signOut':
      return <FontAwesomeIcon icon={faSignOut} {...fontAwesomeIconProps} />
    case 'success':
      return <FontAwesomeIcon icon={faCheckCircle} {...fontAwesomeIconProps} />
    case 'sync':
      return <FontAwesomeIcon icon={faSync} {...fontAwesomeIconProps} />
    case 'warning':
      return <FontAwesomeIcon icon={faExclamationTriangle} {...fontAwesomeIconProps} />
    case 'pdf':
      return <FontAwesomeIcon icon={faFilePdf} {...fontAwesomeIconProps} />
  }
}

const Icon: FC<IconProps> = ({ name, fontAwesomeIconProps, className }) => {
  return (
    <span className={classNames(className, 'Icon flex items-center justify-center')}>
      {renderIcon(name, fontAwesomeIconProps)}
    </span>
  )
}

export default Icon
