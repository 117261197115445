import { RouteComponentProps, useNavigate, useParams } from '@reach/router'
import HeadingPage from '../../../../../components/HeadingPage'
import { Color } from '../../../../../color.enum'
import Button from '../../../../../components/Button'
import { ButtonVariant, ButtonSize } from '../../../../../components/Button'
import { gql, useQuery } from '@apollo/client'
import Loader from '../../../../../components/Loader/Loader'
import DemographicQuestionOptionsList from './demographicQuestionsOptionsList'
import { useBreadcrumbs } from '../../../../../hooks/useBreadcrumbs'
import BreadCrumbs from '../../../../../components/BreadCrumbs/BreadCrumbs'
import {Helmet} from 'react-helmet';

interface SinglePostSurveyQuestionInterface extends RouteComponentProps<any> {
  history: any
}

interface DetailsInterface {
  code: string
}

const Details: React.FC<DetailsInterface> = ({ code }) => {
  return (
    <div className="mt-5 space-y-1">
      {code && (
        <div>
          <span className="inline text-brand-blue font-medium">Code:</span>
          <span className="ml-1">{code}</span>
        </div>
      )}
    </div>
  )
}

const SinglePostSurveyQuestion: React.FC<SinglePostSurveyQuestionInterface> = () => {
  const navigate = useNavigate()
  const { questionId, surveyId, postSurveyId } = useParams()
  const [getBreadCrumbs] = useBreadcrumbs()
  const { loading, data, refetch } = useQuery(GET_SINGLE_POST_SURVEY_QUESTION, {
    fetchPolicy: 'no-cache',
    variables: { id: questionId }
  })

  if (loading) return <Loader color={Color.PURPLE} />
  const { postSurveyQuestion } = data

  const bc = getBreadCrumbs('demographicQuestion', surveyId, '', 'Participant Questionaires', postSurveyId)

  return (
    <div className="page-container-lg">
      <Helmet>
        <title>{postSurveyQuestion?.text}</title>
        <meta name={postSurveyQuestion?.text}/>
      </Helmet>
      <main>
        <BreadCrumbs pages={bc} className="py-0 pb-10" />
        <HeadingPage
          headline={postSurveyQuestion?.text}
          color={Color.BLUE}
          description={<Details code={postSurveyQuestion?.code} />}
          children={() => {
            return (
              <div className="flex">
                <Button
                  variant={ButtonVariant.PRIMARY}
                  color={Color.BLUE}
                  size={ButtonSize.SMALL}
                  onClick={() => {
                    navigate(
                      `/dashboard/admin/survey/${surveyId}/postSurvey/${postSurveyId}/question/${questionId}/edit`
                    )
                  }}
                >
                  Edit Question
                </Button>
              </div>
            )
          }}
        />
        <DemographicQuestionOptionsList
          options={postSurveyQuestion?.postSurveyOption}
          surveyId={surveyId}
          postSurveyId={postSurveyId}
          questionId={questionId}
          surveyStatus={postSurveyQuestion?.postSurvey?.survey?.status}
          type="participant"
          refetch={refetch}
        />
        <Button
          variant={ButtonVariant.PRIMARY}
          color={Color.BLUE}
          size={ButtonSize.MEDIUM}
          iconRight="plus"
          onClick={() => {
            navigate(`/dashboard/admin/survey/${surveyId}/postSurvey/${postSurveyId}/question/${questionId}/create`)
          }}
        >
          Add option
        </Button>
      </main>
    </div>
  )
}

export default SinglePostSurveyQuestion

const GET_SINGLE_POST_SURVEY_QUESTION = gql`
  query postSurveyQuestion($id: String!) {
    postSurveyQuestion(id: $id) {
      text
      code
      position
      id
      description
      postSurvey {
        survey {
          status
        }
      }
      postSurveyOption {
        id
        text
        code
      }
    }
  }
`
