import { Box, Input } from '@plusplusminus/plusplusdash'
import { useForm } from 'react-hook-form'
import { gql, useLazyQuery } from '@apollo/client'
import Button, { ButtonSize, ButtonVariant } from '../../../../components/Button'
import { Color } from '../../../../color.enum'
import RichTextInput from '../../../../components/RichTextInput'
import { useEffect } from 'react'
import Loader from '../../../../components/Loader/Loader'

const form = [
  {
    label: 'Statement Code',
    name: 'code',
    placeholder: 'Statement Code',
    options: {
      required: 'Statement Code is required'
    },

    type: 'text'
  },
  {
    label: 'Statement Label',
    name: 'label',
    placeholder: 'Label',
    options: {
      required: 'Headline is required'
    },
    type: 'text'
  },

  {
    label: 'Position',
    name: 'position',
    placeholder: 'Label',
    options: {
      required: 'Headline is required'
    },
    type: 'number'
  },
  {
    label: 'Statement Text',
    name: 'statement',
    placeholder: 'Statement',
    options: {
      required: 'Statement is required'
    },
    type: 'textarea'
  }
]

interface Props {
  onSubmit?: (data: any, type: string) => void
  loading?: boolean
  statementId?: string
  formType: string
  createError?: any
}

export const CreateStatementForm: React.FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm()

  const [loadStatementData, { loading, data }] = useLazyQuery(GET_SINGLE_STAMENT, {
    variables: { id: props.statementId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { statement } = data
      Object.keys(statement).forEach((st) => {
        setValue(st, statement[st])
      })
    }
  })

  useEffect(() => {
    if (props?.statementId) {
      loadStatementData()
    }
  }, [])

  const onRegister = async (data: any) => {
    if (props.onSubmit) {
      await props.onSubmit(data, props.formType)
    }
  }
  if (loading) return <Loader color={Color.PURPLE} />

  return (
    <div className="section-container-sm">
      <form
        action="#"
        autoComplete="no"
        onSubmit={handleSubmit(onRegister)}
        className="mt-6 grid grid-cols-2 gap-y-4 sm:grid-cols-2 sm:gap-x-8"
      >
        {form.map((field) => {
          if (field.type === 'text' || field.type === 'number') {
            return (
              <Box className="col-span-2 sm:col-span-2" key={field.name}>
                <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
                  {field.label}
                </label>
                <Input
                  as="input"
                  variant="standard"
                  min={1}
                  width="full"
                  type={field.type}
                  {...register(field.name, { ...field.options })}
                ></Input>
                {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
              </Box>
            )
          }

          if (field.type === 'textarea') {
            return (
              <Box className="col-span-2 sm:col-span-2 mt-4">
                <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
                  {field.label}
                </label>
                <Input as="textarea" variant="standard" width="full" {...register(field.name)} />
              </Box>
            )
          }

          if (field.type === 'richText') {
            return (
              <Box className="col-span-2 sm:col-span-2" key={field.name}>
                <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
                  {field.label}
                </label>
                <RichTextInput
                  placeholder={field.placeholder}
                  name={field.name}
                  setValue={setValue}
                  defaultValue={data?.statement[field.name]}
                />
              </Box>
            )
          }
        })}
        <div className="col-span-2">
          <Button
            className="justify-center mb-2"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.LARGE}
            color={Color.BLUE}
            style={{ width: '100%' }}
            type="submit"
          >
            {props.loading ? 'Loading...' : 'Submit'}
          </Button>
        </div>
      </form>
    </div>
  )
}

const GET_SINGLE_STAMENT = gql`
  query statement($id: String!) {
    statement(id: $id) {
      statement
      code
      position
      label
    }
  }
`
