import React from 'react'
import { Color } from '../../color.enum'
import { classNames } from '../../utils/class-names'

export interface HeadingPageProps {
  /** Headline of the component */
  headline?: string
  /** Descriptive text to tshow below the headline */
  description?: string | React.ReactNode
  /** Add a children to the component */
  children?: (color: Color) => JSX.Element
  /** Aditional Styles for the container */
  className?: string
  /** What color is the componet? */
  color: Color
}

interface HeadingPageColorProps {
  text: string
}

export const HEADING_PAGE_COLOR_MAPS: Record<Color, HeadingPageColorProps> = {
  [Color.BLUE]: {
    text: 'text-brand-blue'
  },
  [Color.PURPLE]: {
    text: 'text-brand-purple'
  },
  [Color.GRAY]: {
    text: 'text-gray-500'
  }
}

/**
 *  Component to show a Heading for a Page with optional actions
 */

const HeadingPage: React.FC<HeadingPageProps> = ({
  className,
  color,
  headline,
  description,
  children
}: HeadingPageProps) => {
  return (
    <div className={classNames('HeadingPage md:flex md:items-start md:justify-between md:mb-10 mb-5', className)}>
      <div className="flex-1 min-w-0 max-w-3xl">
        {headline && (
          <h2 className={`font-normal leading-none text-3xl md:text-5xl ${HEADING_PAGE_COLOR_MAPS[color].text}`}>
            {headline}
          </h2>
        )}
        {description && <p className="prose mt-2 md: text-lg">{description}</p>}
      </div>
      {children && <div className="mt-4 flex md:mt-0 md:ml-4">{children(color)}</div>}
    </div>
  )
}

HeadingPage.defaultProps = {
  color: Color.BLUE
}

export default HeadingPage
