import React from 'react'
import { classNames } from '../../utils/class-names'

export interface FooterNavItemProps {
  /** Label of the menu item */
  name: string
  /** External link destination */
  slug: string
  /** Is this the current active item? */
  isCurrent?: boolean
  /** Aditional Styles for the container */
  className?: string
  /** Set an index to style the first child */
  index?: number
}

/**
 *  Component to show the Footer Navigation Items
 */
const FooterNavItem: React.FC<FooterNavItemProps> = ({
  className,
  name,
  isCurrent,
  slug,
  index
}: FooterNavItemProps) => {
  return (
    <div className={classNames(className, `FooterNavItem relative nav-item-${index}`)}>
      <a target="_blank" href={`/${slug}`} title={name}>
        <div
          key={name}
          className={classNames(
            'leading-0 tracking-widest hover:underline text-gray-500 py-1 xl:py-2 px-2 text-xs uppercase before:w-[1px] before:bg-gray-500 before:h-3 xl:before:absolute before:top-[3px] before:translate-y-1/2 before:left-0 text-center',
            isCurrent && 'underline'
          )}
        >
          {name}
        </div>
      </a>
    </div>
  )
}

export default FooterNavItem
