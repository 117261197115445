import { RouteComponentProps } from '@reach/router'
import { Color } from '../../../color.enum'
import HeadingPage from '../../../components/HeadingPage'
import HeadingSection from '../../../components/HeadingSection'
import { User } from '../../../common/types'
import Button, { ButtonVariant, ButtonSize } from '../../../components/Button'
import { useDownloadCsv } from '../../../hooks/useDownloadCsv'
import Alert, { AlertSize, AlertVariant } from '../../../components/Alert'

interface AdminProps extends RouteComponentProps {
  user?: User
}

export const Reports: React.FC<AdminProps> = () => {
  const [download, { loading, error }] = useDownloadCsv()
  return (
    <div className="page-container-lg">
      <header>
        <HeadingPage headline={`Reports`} color={Color.BLUE} />
      </header>
      <main>
        <HeadingSection
          headline="Assignment details per participant"
          color={Color.PURPLE}
          children={() => {
            return (
              <div className="flex items-center">
                <Button
                  variant={ButtonVariant.PRIMARY}
                  color={Color.PURPLE}
                  size={ButtonSize.SMALL}
                  onClick={download}
                  iconRight="arrowRight"
                >
                  {loading ? 'Downloading..' : 'Download'}
                </Button>
              </div>
            )
          }}
        />
        {error && (
          <Alert className="page-container mt-4" size={AlertSize.SMALL} variant={AlertVariant.ERROR}>
            {error}
          </Alert>
        )}
      </main>
    </div>
  )
}
