import React from 'react'
import { Color } from '../../color.enum'
import { classNames } from '../../utils/class-names'

export interface CtaProps {
  /** Content of the Cta */
  headline?: string | React.ReactNode
  /** Eybrow for the headline */
  subtitle?: string | React.ReactNode
  /** Descriptive text to support the cite */
  description?: string | React.ReactNode
  /** Who should this be attributed to? */
  cite?: string
  /** Aditional Styles for the container */
  className?: string
  /** What color is the componet? */
  color: Color
  /** Image to be displayed as backgroud element */
  image?: React.ReactElement
  /** Add a children to the component */
  children?: (color: Color) => JSX.Element | React.ReactElement | React.ReactNode
  /** Id to identify specific component as provided by the CMS */
  id?: string
}

interface CtaColorProps {
  text: string
  bg: string
}

export const CTA_COLOR_MAPS: Record<Color, CtaColorProps> = {
  [Color.BLUE]: {
    text: 'text-brand-blue',
    bg: 'bg-brand-blue-light'
  },
  [Color.PURPLE]: {
    text: 'text-brand-purple',
    bg: 'bg-brand-purple-light'
  },
  [Color.GRAY]: {
    text: 'text-gray-500',
    bg: 'bg-gray-100'
  }
}

/**
 *  Component to show a Cta - typically at the top of the page
 */

const Cta: React.FC<CtaProps> = ({
  className,
  color,
  headline,
  image,
  subtitle,
  description,
  children,
  ...rest
}: CtaProps) => {
  return (
    <div className={classNames(className, 'Cta relative')} {...rest}>
      <div className={`px-4 md:px-8 max-w-7xl mx-auto`}>
        <div
          className={`relative rounded-lg max-w-7xl overflow-hidden shadow-2xl mx-auto py-8 md:py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between ${CTA_COLOR_MAPS[color].bg}`}
        >
          {image && (
            <div className="absolute z-0 inset-y-0 opacity-10 filter saturate-0 mix-blend-multiply -right-64">
              {image}
            </div>
          )}

          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0 flex-col w-3/4 sm:w-1/2">
            {subtitle && <p className="text-lg ">{subtitle}</p>}
            {headline && (
              <p className="text-2xl md:text-4xl  tracking-tight font-medium">
                <span className="block">{headline}</span>
              </p>
            )}
            {description && <p className="mt-4 text-lg">{description}</p>}
          </div>
          {children && <div className="mt-8 z-10 flex lg:mt-0 lg:flex-shrink-0 ml-auto">{children(color)}</div>}
        </div>
      </div>
    </div>
  )
}

Cta.defaultProps = {
  color: Color.BLUE
}

export default Cta
