import React from 'react'
import { Fragment, useRef } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import Icon from '../Icon/Icon'

export interface ModalProps {
  /** Title of the Modal */
  title: string
  /** Is the Modal open? Used to allow the story to show the modal and to override the initial state */
  isModalOpen: boolean
  /** Callback to close the modal */
  onClose: () => void
}

/**
 *  Component to show an Modal
 */

const Modal: React.FC<ModalProps> = ({ isModalOpen, title, onClose, children }) => {
  const closeButtonRef = useRef(null)

  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog
        open={isModalOpen}
        onClose={onClose}
        initialFocus={closeButtonRef}
        className="fixed inset-0 z-50 overflow-y-auto"
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-70" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative inline-block w-full max-w-lg p-12 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded">
              <Dialog.Title as="h3" className="mb-6 text-3xl text-brand-purple">
                {title}
              </Dialog.Title>
              {children && children}

              <button
                type="button"
                className="absolute top-2 right-2 flex justify-center items-center p-1 rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-brand-blue-light"
                onClick={onClose}
              >
                <Icon
                  name="close"
                  className="w-8 h-8 text-brand-blue hover:text-brand-blue-light transition-all"
                  fontAwesomeIconProps={{ size: '2x' }}
                />
              </button>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

Modal.defaultProps = {
  isModalOpen: false,
  title: ''
}

export default Modal
