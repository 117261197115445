import { RouteComponentProps, useNavigate } from '@reach/router'
import { useQuery, gql } from '@apollo/client'

import OrganisationTable from './OrganisationTable'
import { Helmet } from 'react-helmet'
import { language } from '../../common/i18n'

const Organisations: React.FC<RouteComponentProps> = () => {
  const { loading, data, error, refetch } = useQuery(GET_ALL_ORGANISATIONS, { fetchPolicy: 'no-cache' })

  return (
    <div>
       <Helmet>
        <title>{language.en.admin.organisations.title}</title>
        <meta name="description" content={language.en.admin.organisations.description}/>
      </Helmet>
      <OrganisationTable items={data?.organizations?.docs} loading={loading}  
          refetch={refetch}
          pagination={{
            currentPage: data?.organizations.page,
            totalPages: data?.organizations.totalPages,
            hasNextPage: data?.organizations.hasNextPage,
            hasPrevPage: data?.organizations.hasPrevPage
          }}/>
    </div>
  )
}

export default Organisations

const GET_ALL_ORGANISATIONS = gql`
  query Organisations($sort: SortArgs, $pagination: PaginationArgs, $input: QueryOrgsInput) {
    organizations(sort: $sort, pagination: $pagination, input: $input) {
      docs {
      name
      createdAt
      assignment {
        id
      }
      owner {
        id
        firstName
        lastName
      }
      user {
        id
      }
    }
    limit
      totalPages
      page
      hasPrevPage
      hasNextPage
  }
  }
`
