import { StyleSheet } from '@react-pdf/renderer'
import theme from '../../theme'

export default StyleSheet.create({
  page: {
    padding: 30,
    paddingBottom: 50,
    fontFamily: 'Made'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  radarContainer: {
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'row'
  },
  radar: {
    width: '400px',
    height: '400px'
  },
  heading: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 16,
    color: theme.colors['brand-blue']
  },
  subheading: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 16,
    color: theme.colors['brand-blue']
  },
  text: {
    fontSize: 10,
    lineHeight: 1.5
  },
  footer: {
    fontSize: 8
  },
  layout: {
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.gray[100]
  },
  logo: { height: 150, marginBottom: 50 },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    borderTopWidth: 1,
    borderTopColor: theme.colors.gray[100],
    paddingVertical: 10
  },
  column: {
    flexBasis: '50%'
  },
  bold: {
    fontWeight: 'medium'
  },
  center: {
    textAlign: 'center'
  },
  blue: {
    color: theme.colors['brand-blue']
  },
  black: {
    color: 'black'
  },
  grey: {
    color: theme.colors.gray[500]
  },
  purple: {
    color: theme.colors['brand-purple']
  },
  factorContentHeading: {
    fontSize: 14,
    color: 'black',
    fontWeight: 'medium',
    marginBottom: 10
  }
})
