import { useState, useEffect, useRef } from 'react'
import { Router, useLocation } from '@reach/router'
import { Helmet } from 'react-helmet'
import TagManager from 'react-gtm-module'
import { gql, useQuery } from '@apollo/client'
import { MainNav } from './components/Nav/MainNav'
import './App.css'
import { Registration } from './pages/Register'
import { Login } from './pages/Login'
import { RespondentPrivateRoute } from './components/Auth/RespondentPrivate'
import AddUsers from './pages/Users/AddUsers'
import Organisations from './pages/Organisations'
import { ResultsPreview, ReportPreview, Page } from './pages'
import { Context } from './context/context'

import Loader from './components/Loader/Loader'
import { Color } from './color.enum'
import { OrgPrivateRoute } from './components/Auth/OrgPrivate'
import { AdminPrivateRoute } from './components/Auth/AdminPrivate'

import { AssignmentWizard } from './pages/Dashboard/wizard/AssignmentWizard'
import { OwnerDashboard } from './pages/Dashboard/Owner'
import { AdminDashboard } from './pages/Dashboard/Admin'
import Users from './pages/Users'
import Surveys from './pages/Dashboard/survey'
import SingleSurvey from './pages/Dashboard/survey/singleSurvey'
import CreateEditSurvey from './pages/Dashboard/survey/createEditSurvey'
import SingleFactor from './pages/Dashboard/survey/factors/singleFactor'
import CreateFactor from './pages/Dashboard/survey/factors/createFactor'
import SingleUserView from './pages/Users/SingleUserView'
import client from './contentful'
import { RespondentDashboard } from './pages/Survey/RespondentDashboard'
import { SurveyContainer } from './pages/Survey/SurveyContainer'
import SingleDemographicSurveyOrg from './pages/Dashboard/survey/demographicSurveys/singleDemographicOrg'
import SingleDemographicSurveyParticipant from './pages/Dashboard/survey/demographicSurveys/singleDemographicParticipant'
import CreateDemographicSurveyOrg from './pages/Dashboard/survey/demographicSurveys/createDemographicSurveyOrg'
import SingleFactorType from './pages/Dashboard/survey/factorType/singleFactorType'
import CreateFactorType from './pages/Dashboard/survey/factorType/createFactorType'
import CreateStatement from './pages/Dashboard/survey/statements/createStatements'
import SingleSatement from './pages/Dashboard/survey/statements/singleStatement'
import CreatePreSurveyQuestion from './pages/Dashboard/survey/questions/DemographicQuestionOrg/createQuestion'
import SinglePreSurveyQuestion from './pages/Dashboard/survey/questions/DemographicQuestionOrg/singlePreSurveyQuestion'
import SinglePostSurveyQuestion from './pages/Dashboard/survey/questions/DemographicQuestionParticipant/singlePostSurveyQuestion'
import BasicResults from './pages/Survey/Results/BasicResults'
import { AllAssignments } from './pages/Dashboard/assignments/Allassignments'
import SinglePreSurveyQuestionOption from './pages/Dashboard/survey/options/singlePreSurveyQuestionOption'
import CreatePreSurveyQuestionOption from './pages/Dashboard/survey/options/createPreSurveyQuestionOptions'
import CreatePostSurveyQuestionOption from './pages/Dashboard/survey/options/createPostSurveyQuestionOptions'
import SinglePostSurveyQuestionOption from './pages/Dashboard/survey/options/singlePostSurveyQuestionOption'
import CreatePostSurveyQuestion from './pages/Dashboard/survey/questions/DemographicQuestionParticipant/createQuestion'

import Footer from './components/Footer/Footer'
import AssignmentResults from './pages/Survey/Results/AssignmentResults'
import FooterNavItem from './components/FooterNavItem/FooterNavItem'
import { language } from './common/i18n'
import { Reports } from './pages/Dashboard/reports/Reports'

interface FooterNavigationProps {
  footerItems: any[]
}
const FooterNavigation: React.FC<FooterNavigationProps> = ({ footerItems }) => (
  <div className="flex flex-col xl:flex-row items-center mt-2 xl:mt-0">
    {footerItems?.map((item, index) => (
      <FooterNavItem index={index} {...item} />
    ))}
  </div>
)

const Layout: any = (props: any) => (
  <div>
    <Helmet>
      <title>{language.en.site.title}</title>
      <meta name="description" content={language.en.site.description} />
      <link rel="icon" type="image/png" href="../public/mci-logo-center.png" />
    </Helmet>
    <MainNav {...props} />
    {props.children}
    <Footer
      legalText={`© ${new Date().getFullYear()} Mission Capacity Inventory. All rights reserved`}
      renderNavigation={<FooterNavigation footerItems={props?.footerItems} />}
    />
  </div>
)

function useScrollToTop() {
  const location = useLocation()
  const prevPath = useRef(location.pathname)

  useEffect(() => {
    // different base path
    if (prevPath.current !== location.pathname) {
      window.scrollTo({ top: 0 })
      prevPath.current = location.pathname
    }
  }, [location])
}

const App: React.FC = () => {
  const [contextValue, setContextValue] = useState<any>({})
  const { loading, data } = useQuery(FOOTER_QUERY, {
    variables: { id: '5CaVu8MdF5nqGMSh74VL0M' },
    client: client
  })
  useScrollToTop()

  // Add React-GTM https://www.npmjs.com/package/react-gtm-module
  const tagManagerArgs = {
    gtmId: 'GTM-KN6XP5K'
  }

  TagManager.initialize(tagManagerArgs)

  if (loading) return <Loader color={Color.BLUE} />

  return (
    <div className="app-container">
      <Context.Provider value={{ contextValue, setContextValue }}>
        <Router>
          <Layout path="/" footerItems={data?.menu?.menuItemsCollection?.items}>
            <Page path="/" slug="home" />
            <Registration path="/sign-up" />
            <Login path="/login" />
            <ResultsPreview path="/results" />
            <ReportPreview path="/report" />
            <AdminPrivateRoute component={AdminDashboard} path="/dashboard/admin" redirect="/" />
            <AdminPrivateRoute component={CreateEditSurvey} path="/dashboard/admin/create-survey" redirect="/" />
            <AdminPrivateRoute component={Surveys} path="/dashboard/admin/surveys" redirect="/" />
            <AdminPrivateRoute component={SingleSurvey} path="/dashboard/admin/survey/:surveyId" redirect="/" />
            <AdminPrivateRoute
              component={CreateEditSurvey}
              path="/dashboard/admin/survey/:surveyId/edit-survey"
              redirect="/"
            />
            <AdminPrivateRoute component={AddUsers} path="/dashboard/admin/add-user" />
            <AdminPrivateRoute component={AddUsers} path="/dashboard/admin/:userId/edit-user" />
            <AdminPrivateRoute component={Users} path="/dashboard/admin/users" redirect="/" />
            <AdminPrivateRoute component={Reports} path="/dashboard/admin/reports" redirect="/" />
            <AdminPrivateRoute
              component={SingleFactor}
              path="/dashboard/admin/survey/:surveyId/factor/:factorId"
              redirect="/"
            />
            <AdminPrivateRoute
              component={CreateFactor}
              path="/dashboard/admin/survey/:surveyId/create-factor"
              redirect="/"
            />
            <AdminPrivateRoute
              component={CreateFactor}
              path="/dashboard/admin/survey/:surveyId/:factorId/edit-factor"
              redirect="/"
            />
            <AdminPrivateRoute
              component={SingleDemographicSurveyOrg}
              path="/dashboard/admin/survey/:surveyId/demographic-survey/:preSurveyId/org"
              redirect="/"
            />
            <AdminPrivateRoute
              component={SingleDemographicSurveyParticipant}
              path="/dashboard/admin/survey/:surveyId/demographic-survey/:postSurveyId/participant"
              redirect="/"
            />
            <AdminPrivateRoute
              component={CreateDemographicSurveyOrg}
              path="/dashboard/admin/:surveyId/create-presurvey"
              redirect="/"
            />
            <AdminPrivateRoute
              component={SingleDemographicSurveyOrg}
              path="/dashboard/admin/survey/:surveyId/demographic-survey/:preSurveyId/org"
              redirect="/"
            />
            <AdminPrivateRoute
              component={CreateDemographicSurveyOrg}
              path="/dashboard/admin/survey/:surveyId/demographic-survey/:preSurveyId/org/edit"
              redirect="/"
            />

            <AdminPrivateRoute
              component={SinglePostSurveyQuestion}
              path="/dashboard/admin/survey/:surveyId/postSurvey/:postSurveyId/question/:questionId/"
              redirect="/"
            />
            <AdminPrivateRoute
              component={SinglePreSurveyQuestion}
              path="/dashboard/admin/survey/:surveyId/preSurvey/:preSurveyId/question/:questionId/"
              redirect="/"
            />

            <AdminPrivateRoute
              component={SinglePreSurveyQuestionOption}
              path="/dashboard/admin/survey/:surveyId/preSurvey/:preSurveyId/question/:questionId/option/:optionId"
              redirect="/"
            />

            <AdminPrivateRoute
              component={CreatePreSurveyQuestionOption}
              path="/dashboard/admin/survey/:surveyId/preSurvey/:preSurveyId/question/:questionId/option/:optionId/edit"
              redirect="/"
            />
            <AdminPrivateRoute
              component={SinglePostSurveyQuestionOption}
              path="/dashboard/admin/survey/:surveyId/postSurvey/:postSurveyId/question/:questionId/option/:optionId"
              redirect="/"
            />

            <AdminPrivateRoute
              component={CreatePreSurveyQuestionOption}
              path="/dashboard/admin/survey/:surveyId/preSurvey/:preSurveyId/question/:questionId/option/create"
              redirect="/"
            />
            <AdminPrivateRoute
              component={CreatePostSurveyQuestionOption}
              path="/dashboard/admin/survey/:surveyId/postSurvey/:postSurveyId/question/:questionId/create"
              redirect="/"
            />
            <AdminPrivateRoute
              component={CreatePostSurveyQuestionOption}
              path="/dashboard/admin/survey/:surveyId/postSurvey/:postSurveyId/question/:questionId/option/:optionId/edit"
              redirect="/"
            />

            <AdminPrivateRoute
              component={CreatePostSurveyQuestion}
              path="/dashboard/admin/survey/:surveyId/postSurvey/:postSurveyId/question/create"
              redirect="/"
            />

            <AdminPrivateRoute
              component={CreatePostSurveyQuestion}
              path="/dashboard/admin/survey/:surveyId/postSurvey/:postSurveyId/question/:questionId/edit"
              redirect="/"
            />

            <AdminPrivateRoute
              component={CreatePreSurveyQuestion}
              path="/dashboard/admin/survey/:surveyId/preSurvey/:preSurveyId/question/create"
              redirect="/"
            />
            <AdminPrivateRoute
              component={CreatePreSurveyQuestion}
              path="/dashboard/admin/survey/:surveyId/preSurvey/:preSurveyId/question/:questionId/edit"
              redirect="/"
            />

            <AdminPrivateRoute
              component={CreateStatement}
              path="/dashboard/admin/survey/:surveyId/:factorId/create-statement"
              redirect="/"
            />
            <AdminPrivateRoute
              component={CreateStatement}
              path="/dashboard/admin/statement/:statementId/edit-statement"
              redirect="/"
            />
            <AdminPrivateRoute component={SingleSatement} path="/dashboard/admin/statement/:statementId" redirect="/" />
            <AdminPrivateRoute
              component={SingleFactorType}
              path="/dashboard/admin/survey/:surveyId/factorType/:factorTypeId"
              redirect="/"
            />
            <AdminPrivateRoute
              component={CreateFactorType}
              path="/dashboard/admin/survey/:surveyId/factorType/create-factor-type"
              redirect="/"
            />
            <AdminPrivateRoute
              component={CreateFactorType}
              path="/dashboard/admin/survey/:surveyId/factor-type/:factorTypeId/edit-factor-type"
              redirect="/"
            />

            <AdminPrivateRoute component={SingleUserView} path="/dashboard/admin/users/:id" redirect="/" />
            <AdminPrivateRoute component={AllAssignments} path="/dashboard/admin/assessments" redirect="/" />
            <AdminPrivateRoute component={Organisations} path="/dashboard/admin/organizations" redirect="/" />
            <OrgPrivateRoute component={OwnerDashboard} path="/dashboard/organization" redirect="/" />
            <OrgPrivateRoute
              component={AssignmentWizard}
              path="/dashboard/organization/create-assignment"
              redirect="/"
            />
            <OrgPrivateRoute component={AssignmentResults} path="/dashboard/organization/:assignmentId/result" />
            <RespondentPrivateRoute component={RespondentDashboard} path="/dashboard/respondent" />
            <RespondentPrivateRoute component={SurveyContainer} path="/survey/:respondentId/:assignmentId" />
            <RespondentPrivateRoute component={BasicResults} path="/survey/:assignmentId/result" />
            <Page path=":slug" slug=":slug" />
            <Page default slug="not-found" />
          </Layout>
        </Router>
      </Context.Provider>
    </div>
  )
}

export default App

const FOOTER_QUERY = gql`
  query menu($id: String!) {
    menu(id: $id) {
      name
      menuItemsCollection(limit: 10) {
        items {
          slug
          name
        }
      }
    }
  }
`
