import React from 'react'
import { Transition } from '@headlessui/react'
import { classNames } from '../../utils/class-names'
import Icon from '../Icon/Icon'
import { Color } from '../../color.enum'

interface BarProps {
  /** What is the score (in %) */
  scorePercentage?: number
  /** What is the color of the Bar background and text labels? */
  color?: Color
  /** What is the headline of the Bar */
  headline?: string
  /** Optional click handler */
  onClick?: () => void
  /** Text to appear on hover */
  hoverText?: string | null
  /** What is the size of the component */
  size?: BarSize
  /** Should this component animate? */
  hasAnimation?: boolean
  /** Aditional Styles for the container */
  className?: string
}

export interface ColorProps {
  headline: string
  score: string
  barContainer: string
  barScore: string
}

export const COLOR_MAPS: Record<Color, ColorProps> = {
  [Color.BLUE]: {
    headline: 'text-brand-blue',
    score: 'text-brand-blue',
    barContainer: 'bg-brand-blue-light border-brand-blue-light hover:bg-opacity-50 hover:bg-brand-blue',
    barScore: 'bg-brand-blue'
  },
  [Color.PURPLE]: {
    headline: 'text-brand-purple',
    score: 'text-brand-purple',
    barContainer: 'bg-brand-purple-light border-brand-purple-light hover:bg-opacity-50 hover:bg-brand-purple',
    barScore: 'bg-brand-purple'
  },
  [Color.GRAY]: {
    headline: 'text-gray-500',
    score: 'text-gray-500',
    barContainer: 'bg-gray-50 border-gray-50 hover:bg-opacity-50 hover:bg-gray-500',
    barScore: 'bg-gray-500'
  }
}

export enum BarSize {
  SMALL = 'small',
  BASE = 'base'
}

export interface SizeProps {
  headline: string
  score: string
  barContainer: string
  cta: string
}

export const SIZE_MAPS: Record<BarSize, SizeProps> = {
  [BarSize.SMALL]: {
    headline: 'text-sm font-medium',
    score: 'text-sm font-regular',
    barContainer: 'h-3',
    cta: 'text-xs'
  },
  [BarSize.BASE]: {
    headline: 'text-sm font-medium',
    score: 'text-sm font-regular',
    barContainer: 'h-10',
    cta: 'text-sm'
  }
}

/**
 *  Component to show a score and reveal CTA's on hover
 */

const Bar: React.FC<BarProps> = ({
  size = BarSize.BASE,
  color = Color.BLUE,
  scorePercentage = 0,
  headline,
  onClick,
  hoverText = 'Learn more',
  hasAnimation = true,
  className
}: BarProps) => {
  return (
    <div className={className}>
      <div className="flex justify-between mb-1 bg-op">
        <p className={classNames('headline m-0', COLOR_MAPS[color].headline, SIZE_MAPS[size].headline)}>{headline}</p>
        <p className={classNames('scoreLabel m-0', COLOR_MAPS[color].score, SIZE_MAPS[size].score)}>
          {scorePercentage}%
        </p>
      </div>
      <Transition
        as={'div'}
        appear={true}
        show={true}
        enter={classNames(`${hasAnimation ? 'transition-all delay-500 duration-1000' : null}`)}
        enterFrom={classNames(`${hasAnimation ? 'opacity-0 w-0 h-full' : 'opacity-100 w-full h-full'}`)}
        enterTo="opacity-100 w-full h-full"
        leave="transition-all duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        entered="h-full w-full"
      >
        <div
          className={classNames(
            `StyledBarContainer group relative w-full transition border-2 border-solid rounded `,
            SIZE_MAPS[size].barContainer,
            COLOR_MAPS[color].barContainer
          )}
        >
          <div
            className={classNames('StyledBar h-full rounded', COLOR_MAPS[color].barScore)}
            style={{ width: `${scorePercentage}%`, animationFillMode: 'forwards' }}
          />

          {hoverText && (
            <div
              className={`StyledCta flex absolute -top-0 -left-0 text-center border-2 border-solid border-white cursor-pointer justify-center items-center opacity-0 group-hover:opacity-100 w-full h-full bg-white bg-opacity-10 transition-all`}
              onClick={onClick}
            >
              <p
                className={classNames('text-white flex m-0 uppercase tracking-widest font-medium', SIZE_MAPS[size].cta)}
              >
                {hoverText} <Icon name="arrowRight" />
              </p>
            </div>
          )}
        </div>
      </Transition>
    </div>
  )
}

export default Bar
