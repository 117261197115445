/* eslint-disable @typescript-eslint/no-var-requires */
import { useQuery } from '@apollo/client'
import { Document, Font, Image, Page, PDFDownloadLink, Text, View } from '@react-pdf/renderer'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { Color } from '../../../color.enum'
import { CONTENT_TYPES } from '../../../common/constants'
import Button, { ButtonSize, ButtonVariant } from '../../../components/Button'
import Content from '../../../components/pdf/Content'
import Results from '../../../components/pdf/Results'
import styles from '../../../components/pdf/styles'
import { ArchetypeProps } from '../../../components/Radar/Radar.helpers'
import client from '../../../contentful'
import { ReportQuery, useReportQuery } from '../../../generated'
import theme from '../../../theme'
import { REPORT_TEMPLATE_QUERY } from './contentful-queries'

interface PDFProps {
  assignmentId: string
  radarImage?: ArrayBuffer
  archetypes: Array<ArchetypeProps>
  statementCount: number
  isIndividual: boolean
  reportName?: string
  totalRespondentsCompleted?: number
  totalRespondents?: number
}

interface ReportProps {
  report: Array<{ __typename: string; [x: string]: any }>
  data: ReportQuery['report']
}

Font.register({
  family: 'Made',
  fonts: [
    { src: require('../../../fonts/made-regular.woff').default, fontWeight: 500 },
    { src: require('../../../fonts/made-bold.woff').default, fontWeight: 700 },
    { src: require('../../../fonts/made-medium.woff').default, fontWeight: 600 },
    { src: require('../../../fonts/made-light.woff').default, fontWeight: 400 },
    { src: require('../../../fonts/made-thin.woff').default, fontWeight: 300 }
  ]
})
Font.registerHyphenationCallback((word) => [word])

const LAYOUT_MAP = {
  [CONTENT_TYPES.LAYOUT_CONTENT]: Content,
  [CONTENT_TYPES.RESULTS]: Results
}

const Layout: React.FC<{
  layout: Array<{ __typename: string; [x: string]: any }>
  radarImage: PDFProps['radarImage']
  archetypes: PDFProps['archetypes']
  statementCount: PDFProps['statementCount']
  data: ReportProps['data']
}> = ({ layout, radarImage, archetypes, data, statementCount }) => {
  const modules = useMemo(() => {
    return layout
      .filter((l) => l && LAYOUT_MAP[l.__typename])
      .map((l, idx) => {
        const { __typename } = l
        const Component = LAYOUT_MAP[__typename]

        return (
          <View style={styles.layout} break={idx !== 0}>
            <Component
              {...l}
              key={l.sys.id}
              id={l.sys.id}
              styles={styles}
              {...(__typename === CONTENT_TYPES.RESULTS ? { radarImage, archetypes, data, statementCount } : {})}
            />
          </View>
        )
      })
  }, [layout])

  return <>{modules}</>
}

const Report: React.FC<PDFProps & ReportProps> = ({
  radarImage,
  report: layout,
  archetypes,
  data,
  statementCount,
  isIndividual,
  totalRespondentsCompleted,
  totalRespondents
}) => {
  return (
    <Document>
      <Page size="A4" style={{ ...styles.page, justifyContent: 'center', alignItems: 'center' }}>
        <Image src={require('../../../../public/mci-logo-center.png').default} style={styles.logo} />
        <Text style={{ fontSize: 24, color: theme.colors['brand-blue'], fontWeight: 'medium', marginBottom: 20 }}>
          {data.title}
        </Text>
        <Text style={{ fontSize: 14, fontWeight: 'medium', marginBottom: 20 }}>
          {dayjs(data.date).format('DD MMMM YYYY')}
        </Text>
        <Text style={{ fontSize: 14, fontWeight: 'medium' }}>{isIndividual ? 'Individual' : 'Group'} Report</Text>
        {!isIndividual ? (
          <Text style={{ fontSize: 14, fontWeight: 'medium', marginTop: '10px' }}>
            {' '}
            The sample size for this organisational report is {totalRespondentsCompleted} out of {totalRespondents}{' '}
            respondents
          </Text>
        ) : null}
      </Page>
      <Page size="A4" style={styles.page}>
        <Layout {...{ radarImage, layout, archetypes, data, statementCount }} />
        <Text
          style={[
            styles.text,
            styles.footer,
            styles.grey,
            styles.center,
            { position: 'absolute', bottom: 30, left: 30, width: '100%' }
          ]}
          render={({ pageNumber }) => pageNumber - 1}
          fixed
        />
        <Text
          style={[
            styles.text,
            styles.footer,
            styles.grey,
            styles.center,
            { position: 'absolute', bottom: 15, left: 30, width: '100%' }
          ]}
          fixed
        >
          © 2021 MISSION CAPACITY INVENTORY. ALL RIGHTS RESERVED
        </Text>
      </Page>
    </Document>
  )
}

const PDF: React.FC<PDFProps> = (props) => {
  const { data, loading } = useQuery(REPORT_TEMPLATE_QUERY, { variables: { slug: 'mci-2021' }, client })
  const { data: reportData, loading: loadingReport } = useReportQuery({
    variables: { assignmentId: props.assignmentId, isIndividual: props.isIndividual },
    fetchPolicy: 'network-only'
  })

  if (loading || loadingReport || !reportData) return null

  const report = data.reportCollection.items[0].layoutCollection.items

  const reportType = props.isIndividual ? 'Your' : 'Organisation'

  const reportName = `mci-${props.reportName?.replace(/\s/g, '_').toLowerCase()}-report-${dayjs(new Date()).format(
    'YYYY-MM-DD'
  )}.pdf`

  return (
    <div className="mb-6">
      <PDFDownloadLink
        document={<Report {...props} report={report} data={reportData.report} archetypes={props.archetypes} />}
        fileName={reportName}
      >
        {({ loading }) => (
          <Button
            size={ButtonSize.LARGE}
            variant={ButtonVariant.PRIMARY}
            color={Color.BLUE}
            iconLeft="download"
            isDisabled={loading}
          >
            {loading ? 'Loading...' : `Download ${reportType} Report`}
          </Button>
        )}
      </PDFDownloadLink>
    </div>
  )
}

export default PDF
