import { Image, Text, View } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'
import { PropsWithChildren, useMemo } from 'react'
import { Color } from '../../../color.enum'
import { ReportQuery } from '../../../generated'
import theme from '../../../theme'
import draftToPdf from '../../../utils/draftjs-to-pdf'
import { ArchetypeProps } from '../../Radar/Radar.helpers'
import Bar from '../Bar'
import { BarSize } from '../Bar/Bar'
import PDFRichText, { RichTextProps } from '../RichText'
import styles from '../styles'

interface ResultsProps {
  headline?: string
  content?: RichTextProps['content']
  styles: Record<string, Style>
  radarImage?: ArrayBuffer
  archetypes?: Array<ArchetypeProps>
  data?: ReportQuery['report']
  statementCount?: number
}

const LETTERS = 'ABCDEFGHIJ'.split('')

const colorMap = {
  blue: styles.blue,
  purple: styles.purple,
  grey: styles.grey
}

const ResultsWrapper: React.FC<ResultsProps> = (props) => {
  const { headline, content, styles, radarImage, archetypes, data, statementCount } = props

  const participants = useMemo(() => {
    return data?.overview.averages.map((val) => val.participantId) ?? []
  }, [data?.overview])

  return (
    <View>
      <Text style={styles.heading}>{headline}</Text>
      {content && <PDFRichText content={content} />}
      <View style={styles.radarContainer}>
        {radarImage && <Image style={styles.radar} src={Buffer.from(radarImage)} />}
      </View>
      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }} wrap={false}>
        {archetypes?.map((arch, idx) => (
          <View style={{ flexBasis: '50%', paddingRight: idx % 2 === 0 ? 10 : 0, paddingLeft: idx % 2 !== 0 ? 10 : 0 }}>
            <Text style={{ fontSize: 12, fontWeight: 'medium', marginBottom: 20 }}>{arch.name} Factors</Text>
            {arch.dimensions.map((dim) => (
              <View style={{ marginBottom: 20 }}>
                <Bar color={arch.color} headline={dim.name} scorePercentage={dim.score} size={BarSize.BASE} />
              </View>
            ))}
          </View>
        ))}
      </View>
      <Legend statementCount={statementCount} />
      {data ? (
        <>
          <Overview data={data.overview} participants={participants} />
          <DetailedResults data={data.detailedResults} participants={participants} />
        </>
      ) : null}
    </View>
  )
}

const LEGEND_ITEMS = [
  { label: 'Excellent', value: '90-100%' },
  { label: 'Good', value: '75-89%' },
  { label: 'Satisfactory', value: '60-74%' },
  { label: 'Marginal', value: '51-59%' },
  { label: 'Not Sufficient', value: '35-50%' }
]

const Legend = (props: { statementCount?: number }) => {
  const { statementCount } = props
  return (
    <View style={{ marginBottom: 30 }}>
      {typeof statementCount === 'number' ? (
        <>
          <Text style={{ fontSize: 12, fontWeight: 'medium', marginBottom: 20 }}>For {statementCount} Questions</Text>
          <Text style={{ fontSize: 12, marginBottom: 10 }}>Highest Possible Score: {statementCount * 5}</Text>
          <Text style={{ fontSize: 12, marginBottom: 20 }}>Lowest Possible Score: {statementCount}</Text>
        </>
      ) : null}
      <View>
        <Row style={{ borderTopWidth: 0 }}>
          <View style={styles.column}>
            <Text style={[styles.text, styles.bold, { color: theme.colors['brand-blue'] }]}>Description</Text>
          </View>
          <View style={styles.column}>
            <Text style={[styles.text, styles.bold, { color: theme.colors['brand-blue'] }]}>Percentage</Text>
          </View>
        </Row>
        {LEGEND_ITEMS.map(({ label, value }) => (
          <Row>
            <View style={styles.column}>
              <Text style={styles.text}>{label}</Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.text}>{value}</Text>
            </View>
          </Row>
        ))}
      </View>
    </View>
  )
}

const Overview = (props: { data: ReportQuery['report']['overview']; participants: Array<string> }) => {
  const { data, participants } = props
  const isIndividual = participants.length === 1

  return (
    <View style={[styles.layout, { paddingTop: 0 }]} break>
      <Row style={{ borderTop: 0, paddingVertical: 20 }}>
        {!isIndividual && <View style={{ flexBasis: 50 }} />}
        <View style={{ flexGrow: 1, flexDirection: 'row' }}>
          {!isIndividual && <View style={{ flexBasis: '50%' }} />}
          <View style={{ flexBasis: isIndividual ? '100%' : '50%' }}>
            <Text
              style={{
                fontSize: 12,
                fontWeight: 'medium',
                color: theme.colors['brand-blue'],
                textAlign: 'center',
                textDecoration: 'underline'
              }}
            >
              {isIndividual ? 'Your Results' : 'Participant ID'}
            </Text>
          </View>
        </View>
        {!isIndividual && <View style={{ flexBasis: 50, flexShrink: 0 }} />}
      </Row>
      <Row style={{ paddingVertical: 20 }}>
        <View style={{ flexBasis: 50 }} />
        <View style={{ flexGrow: 1, flexDirection: 'row' }}>
          <View style={{ flexBasis: '50%' }}>
            <Text style={[styles.text, styles.bold, styles.blue, { fontSize: 12 }]}>Factors</Text>
          </View>
          <View style={{ flexBasis: '50%' }}>
            {!isIndividual && (
              <View style={{ flexDirection: 'row' }}>
                {participants.map((_, index) => (
                  <Text style={[styles.text, styles.bold, { flexBasis: 50 }]}>{LETTERS[index]}</Text>
                ))}
              </View>
            )}
          </View>
        </View>
        {!isIndividual && (
          <View style={{ flexBasis: 50, flexShrink: 0 }}>
            <Text style={[styles.text, styles.center, styles.bold]}>Factor Average</Text>
          </View>
        )}
      </Row>
      {data.results.map((res) => (
        <Row style={{ paddingVertical: 0, minHeight: 200 }}>
          <View
            style={{
              position: 'relative',
              flexBasis: 50,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Text
              style={{
                fontSize: 12,
                fontWeight: 'bold',
                color: theme.colors['brand-blue'],
                textAlign: 'center',
                transform: 'rotate(-90deg) translateX(-60%)',
                transformOrigin: 'center'
              }}
            >
              {res.factorType} Factors
            </Text>
          </View>
          <View style={{ flexGrow: 1 }}>
            {res.factors.map((factor, idx) => (
              <View>
                <Row style={idx === 0 ? { borderTopWidth: 0, marginTop: 1 } : {}}>
                  <View style={{ flexBasis: '50%' }}>
                    <Text style={[styles.text]}>{factor.headline}</Text>
                  </View>
                  <View style={{ flexDirection: 'row', flexBasis: '50%' }}>
                    {participants.map((id) => (
                      <Text style={[styles.text, { flexBasis: 50 }]}>
                        {factor.scores.find(({ participantId }) => participantId === id)?.score}
                      </Text>
                    ))}
                  </View>
                  {!isIndividual && (
                    <View style={{ flexBasis: 50, flexShrink: 0 }}>
                      <Text
                        style={[
                          styles.text,
                          styles.center,
                          styles.bold,
                          res.factorTypeColor ? colorMap[res.factorTypeColor as keyof typeof colorMap] : styles.black
                        ]}
                      >
                        {factor.averageScore}
                      </Text>
                    </View>
                  )}
                </Row>
                {!isIndividual && (
                  <Row>
                    <View style={{ flexBasis: '50%' }} />
                    <View style={{ flexBasis: '50%' }}>
                      <Bar
                        color={(res.factorTypeColor as Color) ?? Color.BLUE}
                        scorePercentage={factor.average}
                        size={BarSize.SMALL}
                        headline="Average Score"
                      />
                    </View>
                    <View style={{ flexBasis: 50, flexShrink: 0 }} />
                  </Row>
                )}
              </View>
            ))}
          </View>
        </Row>
      ))}
      <Row style={{ paddingVertical: 20 }}>
        <View style={{ flexBasis: 50 }} />
        <View style={{ flexGrow: 1, flexDirection: 'row' }}>
          <View style={{ flexBasis: '50%' }}>
            <Text style={[styles.text, styles.bold, styles.blue]}>
              {isIndividual ? 'Your' : 'Participant'} Average Score
            </Text>
          </View>
          <View style={{ flexBasis: '50%', flexDirection: 'row' }}>
            {participants.map((id) => (
              <Text style={[styles.text, styles.bold, styles.blue, { flexBasis: 50 }]}>
                {data.averages.find(({ participantId }) => participantId === id)?.score}
              </Text>
            ))}
          </View>
          {!isIndividual && (
            <View style={{ flexBasis: 50, flexShrink: 0 }}>
              <Text style={[styles.text, styles.bold, styles.center]}>{data.totalAverage}</Text>
            </View>
          )}
        </View>
      </Row>
    </View>
  )
}

const DetailedResults = (props: { data: ReportQuery['report']['detailedResults']; participants: Array<string> }) => {
  const { data, participants } = props
  const isIndividual = participants.length === 1
  return (
    <View style={{ paddingTop: 20 }}>
      {/** Map over factorTypes here */}
      {data.map((res, idx) => (
        <View break={idx > 0}>
          <Text style={styles.heading}>{res.factorType} Results</Text>
          {res.factors.map((factor, idx) => (
            <View break={idx > 0}>
              <Text style={styles.subheading}>
                {idx + 1}. {factor.headline}
              </Text>
              <View>
                <Row>
                  <View style={{ flexBasis: '40%' }}>
                    <Text style={[styles.text, styles.bold, styles.blue]}>Statement</Text>
                  </View>
                  <View style={{ flexBasis: '40%' }}>
                    <Text style={[styles.text, styles.bold, styles.center, styles.blue, { paddingHorizontal: 5 }]}>
                      {isIndividual ? 'Your Results' : 'Response by Participant'}
                    </Text>
                  </View>
                  {!isIndividual && (
                    <View style={{ flexBasis: 100 }}>
                      <Text style={[styles.text, styles.bold, styles.center, styles.blue, { width: '100%' }]}>
                        Average
                      </Text>
                    </View>
                  )}
                </Row>
                {!isIndividual && (
                  <Row>
                    <View style={{ flexBasis: '40%' }} />
                    <View
                      style={{
                        flexBasis: '40%',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        paddingHorizontal: 5
                      }}
                    >
                      {participants.map((_, index) => (
                        <Text style={[styles.text, styles.bold, styles.center, { flexBasis: 50 }]}>
                          {LETTERS[index]}
                        </Text>
                      ))}
                    </View>
                    <View style={{ flexBasis: 100 }} />
                  </Row>
                )}
                <View style={{ marginBottom: 20 }}>
                  {/** Map over the statements here and display results */}
                  {factor.statements.map((statement) => (
                    <Row style={{ alignItems: 'stretch' }}>
                      <View style={{ flexBasis: '40%' }}>
                        <Text style={[styles.text, { paddingRight: 5 }]}>{statement.statement}</Text>
                      </View>
                      <View
                        style={{
                          flexBasis: '40%',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'flex-start',
                          paddingHorizontal: 5
                        }}
                      >
                        {participants.map((id) => (
                          <Text style={[styles.text, styles.center, { flexBasis: 50 }]}>
                            {statement.scores.find(({ participantId }) => participantId === id)?.score}
                          </Text>
                        ))}
                      </View>
                      {!isIndividual && (
                        <View style={{ flexBasis: 100 }}>
                          <Text style={[styles.text, styles.bold, styles.center, styles.blue]}>
                            {statement.average}
                          </Text>
                        </View>
                      )}
                    </Row>
                  ))}
                  <Row>
                    <View style={{ flexBasis: '40%' }}>
                      <Text style={[styles.text, styles.bold, styles.blue]}>
                        {isIndividual ? 'Your' : 'Participant'} Average
                      </Text>
                    </View>
                    <View
                      style={{
                        flexBasis: '40%',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        paddingHorizontal: 5,
                        justifyContent: 'center'
                      }}
                    >
                      {participants.map((id) => (
                        <Text style={[styles.text, styles.bold, styles.center, styles.blue, { flexBasis: 50 }]}>
                          {factor.averages.find(({ participantId }) => participantId === id)?.score}
                        </Text>
                      ))}
                    </View>
                    {!isIndividual && (
                      <View style={{ flexBasis: 100 }}>
                        <Text style={[styles.text, styles.center, styles.bold, styles.blue]}>
                          {factor.factorAverage}
                        </Text>
                      </View>
                    )}
                  </Row>
                </View>
                {factor.content ? (
                  <View style={{ marginBottom: 10 }}>
                    <Text style={styles.factorContentHeading}>Factor Overview</Text>
                    <View>{draftToPdf(JSON.parse(factor.content))}</View>
                  </View>
                ) : null}
                {factor.discussionQuestions ? (
                  <View style={{ marginBottom: 10 }}>
                    <Text style={styles.factorContentHeading}>Discussion Questions</Text>
                    <View>{draftToPdf(JSON.parse(factor.discussionQuestions))}</View>
                  </View>
                ) : null}
              </View>
            </View>
          ))}
        </View>
      ))}
    </View>
  )
}

const Row = (props: PropsWithChildren<{ style?: Style }>) => {
  return (
    <View style={{ ...styles.row, ...(props.style || {}) }} wrap={false}>
      {props.children}
    </View>
  )
}

export default ResultsWrapper
