import React, { useState } from 'react'
import { RouteComponentProps, useLocation, useNavigate, useParams } from '@reach/router'
import { AddUserForm } from './AddUserForm'
import { useMutation } from '@apollo/client'
import { Alert } from '@plusplusminus/plusplusdash'
import gql from 'graphql-tag'
import {Helmet} from 'react-helmet';
import { language } from '../../common/i18n'

const AddUsers: React.FC<RouteComponentProps> = () => {
  const { pathname } = useLocation()
  const { userId } = useParams()
  const navigate = useNavigate()

  const [error, setError] = useState({ message: '', action: '' })
  const [success, setSuccess] = useState({ message: '', action: '' })
  const [register, { loading }] = useMutation(CREATE_ADMIN)
  
  const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_USER, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      navigate(`/dashboard/admin/users/${userId}`)
    }
  })

  const type = pathname.endsWith('edit-user') ? 'edit': 'create'
  const onCreateUser = (submission: any, reset: any, formType: string) => {
    setError({
      message: '',
      action: ''
    })
    setSuccess({
      message: '',
      action: ''
    })

    const input: any = {
      firstName: submission.firstName.trim(),
      lastName: submission.lastName.trim()
    }

    if (!!submission.email) {
      input.email = submission.email.toLowerCase().trim()
    }

    if(type==='create'){
      register({
        variables: {
          input
        }
      })
        .then(({ data }) => {
          if (data) {
            setSuccess({
              message: 'New user added successfully.',
              action: 'no action'
            })
            reset()
          }
        })
        .catch((err) => {
          setError({
            message: 'Something went wrong. Please try again later.',
            action: 'no action'
          })
        })
    } else {
      const {__typename, createdAt, id, ...rest} = submission
      updateUser({
        variables: {
           userId,
          input: {...rest }
        }
      })
    }

    
  }

  return (
    <div className="page-container-lg">
      <AddUserForm onSubmit={onCreateUser} loading={loading || updateLoading} success={success} userId={userId} formType={type}/>
      {error.message ? <Alert type="error" children={<div>{error.message}</div>} /> : null}
      {success.message ? <Alert type="success" children={<div>{success.message}</div>} /> : null}
    </div>
  )
}

export default AddUsers

export const CREATE_ADMIN = gql`
  mutation createAdmin($input: CreateAdminInput!) {
    createAdmin(input: $input) {
      id
      email
      firstName
      lastName
      role
    }
  }
`

export const UPDATE_USER = gql`
    mutation updateUser($userId:String!, $input: UpdateUserInput!) {
        updateUser(userId: $userId, input: $input) {
            id
            email
            firstName
            lastName
            role
          }
    }
`;
