import React, { useEffect } from 'react'
import { Table } from '@plusplusminus/plusplusdash'
import { useSort } from '../../hooks/useSort'
import { formatDate } from '../../services/formatDate'
import { HeadingPage } from '../../components'
import { Color } from '../../color.enum'
import Loader from '../../components/Loader/Loader'
import TablePagination from '../../components/TablePagination'
import { usePagination } from '../../hooks/usePagaination'
import { Search } from '../../components/Search/Search'

const searchParams = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
    options: []
  }
]
interface Items {
  id: string
  name: string
  createdAt: Date
  owner: any
  assignment: []
}

interface ListItems {
  items: Array<Items>
  loading: boolean
  refetch: (value: any) => void
  pagination: PaginationArgs
}

interface PaginationArgs {
  currentPage: number
  totalPages: number
  hasNextPage: boolean
  hasPrevPage: boolean
}

const TABLE_SHAPE = [
  { label: 'Organisation Name', key: 'organisationName', isSortable: true },
  { label: 'Date Created', key: 'dateCreated', isSortable: true },
  { label: 'Organisation Leader Name', key: 'OrganisationLeaderName', isSortable: true },
  { label: 'Number of Assessments', key: 'numberOfAsignments', isSortable: true }
]

const OrganisationList: React.FC<ListItems> = ({
  items,
  loading,
  refetch,
  pagination: { totalPages, currentPage, hasNextPage, hasPrevPage }
}) => {
  const [sortField, direction, sortCallback] = useSort()
  const [page, offset, limit, nextPage, prevPage] = usePagination(50)

  useEffect(() => {
    refetch({ pagination: { limit, sort: 'ACS', page } })
  }, [page])

  useEffect(() => {
    if (sortField && direction) {
      refetch({ sort: { direction: direction.toUpperCase(), sortField: sortField.replace('-', '') } })
    }
  }, [sortField, direction])

  const onSearch = (data: any) => {
    Object.keys(data).forEach((key) => {
      if (data[key] === '') {
        delete data[key]
      }
    })

    if (sortField && direction) {
      refetch({
        pagination: { limit, sort: 'ACS', page: 1 },
        sort: { direction: direction.toUpperCase(), sortField: sortField.replace('-', '') },
        input: data
      })
    } else {
      refetch({
        pagination: { limit, sort: 'ACS', page: 1 },
        input: data
      })
    }
  }

  const onClear = () => {
    refetch({ pagination: { limit, sort: 'ACS', page: 1 }, input: {} })
  }

  if (loading) return <Loader color={Color.PURPLE} />
  return (
    <div className="page-container-lg">
      <HeadingPage color={Color.BLUE} headline="All Organisations" />
      <Search onSearch={onSearch} searchParams={searchParams} onClear={onClear} />
      <Table
        shape={TABLE_SHAPE}
        activeSort={direction === 'desc' ? sortField?.slice(1) : sortField}
        sortDirection={direction}
        sortCallback={sortCallback}
      >
        {items?.map((item) => {
          return (
            <Table.Row key={item.id}>
              <Table.Cell className="font-medium text-gray-900">{item?.name} </Table.Cell>
              <Table.Cell className="text-gray-900">{formatDate(item?.createdAt)}</Table.Cell>
              <Table.Cell className="text-gray-900">
                {item?.owner.firstName} {item?.owner.lastName}
              </Table.Cell>
              <Table.Cell className="text-gray-900">{item?.assignment?.length}</Table.Cell>
            </Table.Row>
          )
        })}
      </Table>
      <div className="flex">
        <div className="flex-1">
          <TablePagination
            page={currentPage}
            limit={limit}
            nextPage={nextPage}
            prevPage={prevPage}
            totalPages={totalPages}
            hasPrevPage={hasPrevPage}
            hasNextPage={hasNextPage}
          />
        </div>
      </div>
    </div>
  )
}

export default OrganisationList
