import { Color } from '../../../../color.enum'
import Button, { ButtonVariant, ButtonSize } from '../../../../components/Button'
import { navigate } from '@reach/router'
import { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import RemoveItemModal from '../components/RemoveItemModal'

interface ItemCtaData {
  color: Color
  id: string
  surveyId: string
  type: string
  demoId: string
  surveyStatus?: string
  refetch?: any
}

interface ItemMetaData {
  optionsNumber: string
}

export const ListItemCta: React.FC<ItemCtaData> = ({ color, id, surveyId, type, demoId, surveyStatus, refetch }) => {

  const [isOpen, setIsOpen] = useState(false)

  const [deletePreSurveyQuestion, { loading }] = useMutation( DELETE_PRESURVEY_QUESTION, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      refetch()
      setIsOpen(false)
    }
  })

  const [deletePostSurveyQuestion, { loading: pLoading }] = useMutation(
    DELETE_POSTSURVEY_QUESTION, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      refetch()
      setIsOpen(false)
    }
  })



  const handleCloseRemoveQuestion = () => {
    setIsOpen(false)
  }

  const handleRemoveQuestion = (id: string) => {
    if(type === 'org'){
      deletePreSurveyQuestion({
        variables:{
          id: id
        }
      })
    } else{
      deletePostSurveyQuestion({
        variables:{
          id: id
        }
      })
    }
  
  }
  return (
    <div className="flex space-x-2">
      <RemoveItemModal
        isOpen={isOpen}
        handleClose={handleCloseRemoveQuestion}
        handleConfirm={() => handleRemoveQuestion(id)}
        loading={loading || pLoading}
      />
      {surveyStatus !== 'PUBLISHED' && (
        <Button variant={ButtonVariant.PLAIN} color={color} size={ButtonSize.SMALL} onClick={() => setIsOpen(true)}>
          Remove Question
        </Button>
      )}
      <Button
        variant={ButtonVariant.PRIMARY}
        color={color}
        size={ButtonSize.SMALL}
        iconRight="arrowRight"
        onClick={() =>
          navigate(
            `/dashboard/admin/survey/${surveyId}/${
              type === 'org' ? 'preSurvey' : 'postSurvey'
            }/${demoId}/question/${id}`
          )
        }
      >
        View details
      </Button>
    </div>
  )
}

export const ListItemMeta: React.FC<ItemMetaData> = ({ optionsNumber }: ItemMetaData) => {
  return (
    <div>
      <p className="text-sm text-gray-900">{optionsNumber}</p>
    </div>
  )
}

const  DELETE_PRESURVEY_QUESTION = gql`
  mutation deletePreSurveyQuestion($id: String!) {
    deletePreSurveyQuestion(id: $id) {
      status
      message
    }
  }
`
const  DELETE_POSTSURVEY_QUESTION = gql`
  mutation deletePostSurveyQuestion($id: String!) {
    deletePostSurveyQuestion(id: $id) {
      status
      message
    }
  }
`
