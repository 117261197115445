import { RouteComponentProps } from '@reach/router'
import { Helmet } from 'react-helmet'
import { Color } from '../../../color.enum'
import { User } from '../../../common/types'
import AssignmentList from './AllAssignmentsList'
import { useQuery, gql } from '@apollo/client'
import Loader from '../../../components/Loader/Loader'
import Alert, { AlertVariant, AlertSize } from '../../../components/Alert'
import { language } from '../../../common/i18n'


interface AllAssignmentsProps extends RouteComponentProps {
  user?: User
  test?: string
}

export const AllAssignments: React.FC<AllAssignmentsProps> = () => {
  
  const { loading, data, refetch } = useQuery(GET_ALL_ASSIGNMENTS, {
    fetchPolicy: 'no-cache'
  })
  if (loading) return <Loader color={Color.PURPLE} />

  return (
    <div className="page-container-lg">
       <Helmet>
        <title>{language.en.admin.assignments.title}</title>
        <meta name="description" content={language.en.admin.assignments.description}/>
      </Helmet>
      <AssignmentList
          items={data?.assignments?.docs} loading={loading} refetch={refetch}
          pagination={{
            currentPage: data.assignments.page,
            totalPages: data.assignments.totalPages,
            hasNextPage: data.assignments.hasNextPage,
            hasPrevPage: data.assignments.hasPrevPage
          }}
        />
    </div>
  )
}

const GET_ALL_ASSIGNMENTS = gql`
  query Assignments($sort: SortArgs, $pagination: PaginationArgs, $input: QueryAssignmentsInput) {
    assignments(sort: $sort, pagination: $pagination, input: $input) {
      docs {
        id
        dueDate
        name
        status
        respondentCount
      }
      limit
      totalPages
      page
      hasPrevPage
      hasNextPage
    }
  }
`

