import React from 'react'
import { classNames } from '../../utils/class-names'

export interface HeaderNavItemProps {
  /** Label for the nav item */
  label?: string
  /** Is this the current active item? */
  isActive?: boolean
  /** Aditional Styles for the container */
  className?: string
}

/**
 *  Component to show the Header Navigation Items
 */
const HeaderNavItem: React.FC<HeaderNavItemProps> = ({ label, isActive, className, ...rest }) => {
  return (
    <span
      className={classNames(
        className,
        'HeaderNavItem inline-block transition py-1 mb-1',
        isActive
          ? 'font-medium text-brand-blue border-b-2 border-brand-blue-light'
          : 'text-brand-purple hover:text-brand-blue border-b-2 border-transparent'
      )}
      {...rest}
    >
      {label && label}
    </span>
  )
}

export default HeaderNavItem
