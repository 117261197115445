import { Box, Input } from '@plusplusminus/plusplusdash'
import Button, { ButtonVariant, ButtonSize } from '../Button'
import { useForm } from 'react-hook-form'
import { Color } from '../../color.enum'
import { Select } from '../../pages/Dashboard/survey/factors/components/Select'

interface SearchProps {
  searchParams: Array<{ name: string; label: string; type: string; options: Array<{ id: string; name: string }> }>
  onSearch: (value: any) => void
  onClear: () => void
}

export const Search: React.FC<SearchProps> = ({ onSearch, searchParams, onClear }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange' })

  const onRegister = async (data: any) => {
    onSearch(data)
  }

  const onReset = () => {
    reset()
    onClear()
  }

  return (
    <div className="pb-4">
      <form action="#" autoComplete="no" onSubmit={handleSubmit(onRegister)}>
        <div className="py-4 grid grid-cols-3 gap-4">
          {searchParams.map((params) => {
            if (params.type === 'select') {
              return (
                <Box className=" pb-4" key={params.name}>
                  <label htmlFor={params.name} className="text-base font-medium text-brand-purple">
                    {params.label}
                  </label>
                  <Select options={params.options} name={params.name} register={register} value={getValues(params.name) || ""}/>
                </Box>
              )
            }

            return (
              <Box className="w-full pb-4" key={params.name}>
                <label htmlFor={params.name} className="block text-sm font-medium text-gray-900 mb-1">
                  {params.label}
                </label>
                <Input as="input" variant="standard" {...register(params.name)} className="w-full"></Input>
              </Box>
            )
          })}
        </div>
        <div className=" grid grid-cols-6 gap-4">
          <Button variant={ButtonVariant.PRIMARY} color={Color.BLUE} size={ButtonSize.SMALL} iconRight="arrowRight">
            Search
          </Button>
          <Button
            type="button"
            variant={ButtonVariant.SECONDARY}
            color={Color.BLUE}
            size={ButtonSize.SMALL}
            onClick={onReset}
            iconRight="arrowRight"
          >
            Clear/reset
          </Button>
        </div>
      </form>
    </div>
  )
}
