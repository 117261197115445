import { useState } from 'react'
import dayjs from 'dayjs'
import saveFile from 'save-as-file'

export const useDownloadCsv: any = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const download = async () => {
    setError('')
    setLoading(true)

    const token = localStorage.getItem('mci-token')
    const url = process.env.REACT_APP_API
      ? `${process.env.REACT_APP_API}/reports/results`
      : 'http://localhost:3000/reports/results'

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Bearer ${token}`)

    const requestOptions = {
      method: 'GET',
      headers: myHeaders
    }

    await fetch(url, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          setError(response.statusText)
        } else {
          return response.blob()
        }
      })
      .then(async (result: any) => {
        setLoading(false)

        if (result) {
          saveFile(result, `mci-survey_responses-${dayjs(new Date()).format('YYYY-MM-DD')}.csv`)
        }
      })
      .catch((error) => {
        setLoading(false)
        setError(error)
      })
  }

  return [download, { loading, error }]
}
