import { RouteComponentProps, useNavigate, useParams, useLocation } from '@reach/router'
import { Color } from '../../../../color.enum'
import { gql, useMutation } from '@apollo/client'
import { CreateStatementForm } from './createStatementForm'
import HeadingPageCenter from '../../../../components/HeadingPageCenter'
import { Helmet } from 'react-helmet'
import { language } from '../../../../common/i18n'

interface CreateStatementInterface extends RouteComponentProps<any> {
  location: any
}

const CreateStatement: React.FC<CreateStatementInterface> = () => {
  const navigate = useNavigate()
  const { surveyId, factorId, statementId } = useParams()
  const { pathname } = useLocation()

  const [createStatement, { loading: createStatementLoading, error: createError }] = useMutation(
    CREATE_STAMENT,
    {
      onError: (error) => {
        console.log(error)
      },
      onCompleted: (data) => {
        navigate(`/dashboard/admin/statement/${data?.createStatement?.id}`)
      }
    }
  )

  const [updateStatement, { loading: updateLoading}] = useMutation(UPDATE_STATEMENT, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      console.log(data)
      navigate(`/dashboard/admin/statement/${statementId}`)
    }
  })

  const type = pathname.endsWith('edit-statement') ? 'edit' : 'create'

  const onSubmit = async (data: any, type: string) => {
    if (type === 'create') {
      createStatement({
        variables: {
          input: { ...data, surveyId, factorId, position: Number(data.position) }
        }
      })
    } else {
      const { position, ...rest } = data

      const parsePosition = parseInt(position)
      updateStatement({
        variables: {
          id: statementId,
          input: {
            statement: rest.statement,
            code: rest.code,
            label: rest.label,
            position: parsePosition,
            surveyId,
            factorId
          }
        }
      })
    }
  }

  return (
    <div className="page-container-lg">
       <Helmet>
        <title>{language.en.admin.statement.create.title}</title>
        <meta name="description" content={language.en.admin.statement.create.description}/>
      </Helmet>
      <main>
        <HeadingPageCenter
          headline={pathname.endsWith('edit-statement') ? 'Edit Statement' : 'Create new statement'}
          color={Color.PURPLE}
        />
        <div className="section-container-sm">
          <CreateStatementForm
            loading={createStatementLoading || updateLoading}
            onSubmit={onSubmit}
            createError={createError}
            formType={type}
            statementId={statementId}
          />
        </div>
      </main>
    </div>
  )
}

export default CreateStatement

const CREATE_STAMENT = gql`
  mutation createStatement($input: CreateStatementInput!) {
    createStatement(input: $input) {
      statement
      id
    }
  }
`
const UPDATE_STATEMENT = gql`
  mutation updateStatement($id: String!, $input: UpdateStatementInput!) {
    updateStatement(input: $input, id: $id) {
      id
    }
  }
`
