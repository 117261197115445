export const api = [
  {
    name: 'Strongly Disagree',
    value: 0,
    code: 'A0255',
    title: "Strongly Disagree"
  },
  {
    name: 'Disagree',
    value: 1,
    code: 'A0256',
    title: "Disagree"
  },
  {
    name: 'Somewhat Agree',
    value: 2,
    code: 'A0257',
    title: "Somewhat Agree"
  },
  {
    name: 'Agree',
    value: 3,
    code: 'A0258',
    title: "Agree"
  },
  {
    name: 'Strongly Agree',
    value: 4,
    code: 'A0259',
    title: "Strongly Agree"
  }
]
