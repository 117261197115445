import React from 'react'
import { Color } from '../../color.enum'
import theme from '../../theme'
import { classNames } from '../../utils/class-names'

export interface CoordArgs {
  trigFunction: (val: number) => number
  radius: number
  startingValue: number
  angle: number
}

export const getCoord = ({ trigFunction, radius, startingValue, angle }: CoordArgs): number => {
  return startingValue + radius * trigFunction((angle * Math.PI) / 180)
}

export interface LinesArgs {
  dimensionCount: number
  startX: number
  startY: number
  radius: number
  color?: string
}

const FILL_MAP: Record<Color, string> = {
  [Color.BLUE]: theme.colors['brand-blue'],
  [Color.PURPLE]: theme.colors['brand-purple'],
  [Color.GRAY]: theme.colors.gray[500]
}

export const renderLines = ({
  dimensionCount,
  startX,
  startY,
  radius,
  color
}: LinesArgs): Array<React.ReactElement> => {
  const lines = []
  for (let i = 0; i < dimensionCount; i++) {
    const angle = (i * 360) / dimensionCount
    const x2 = getCoord({ trigFunction: Math.cos, radius, startingValue: startX, angle })
    const y2 = getCoord({ trigFunction: Math.sin, radius, startingValue: startY, angle })

    lines.push(<line x1={startX} y1={startY} x2={x2} y2={y2} stroke={color} strokeWidth="0.5" opacity="0.6" />)
  }

  return lines
}

export interface SlicesProps {
  /** Description */
  archetypes: Array<ArchetypeProps>
  idx: number
  dimensionCount: number
  startX: number
  startY: number
  radius: number
  setActiveValues: (label: string, percentage: number) => void
  onMouseEnter: React.ReactEventHandler
  onMouseLeave: React.ReactEventHandler
}

export interface ArchetypeProps {
  dimensions: Array<DimensionProps>
  color: Color
  name: string
  description?: string;
}

export interface DimensionProps {
  score: number
  name: string
  description: string | JSX.Element
  id?: number
  content?: string;
}

export const renderSlices = ({
  archetypes,
  idx,
  dimensionCount,
  startX,
  startY,
  radius,
  setActiveValues,
  onMouseEnter,
  onMouseLeave
}: SlicesProps): Array<React.ReactNode> => {
  const slices = archetypes.map(({ dimensions, color }) => {
    return dimensions.map(({ score, name }) => {
      const thisIndex = idx++

      // calculate angles
      const angle1 = (thisIndex * 360) / dimensionCount
      const angle2 = ((thisIndex + 1) * 360) / dimensionCount

      const calculateCoords = (angle: number) => {
        const iterator: [(val: number) => number, number][] = [
          [Math.cos, startX],
          [Math.sin, startY]
        ]
        return iterator.map(([trigFunction, val]) =>
          getCoord({
            trigFunction,
            radius: radius + 10,
            startingValue: val,
            angle
          })
        )
      }

      // calculate first point coords
      const [x1, y1] = calculateCoords(angle1)

      // calculate second point coords
      const [x2, y2] = calculateCoords(angle2)

      const path = `M ${startX} ${startY} L ${x1} ${y1} L ${x2} ${y2}`

      return (
        <>
          <defs>
            <clipPath id={`slice-${idx}`}>
              <circle cx={startX} cy={startY} r={(score / 100) * radius} />
            </clipPath>
          </defs>
          <path
            className={classNames('Path animated-slide cursor-pointer origin-center')}
            onMouseEnter={(event) => {
              setActiveValues(name, score)
              onMouseEnter(event)
            }}
            onMouseLeave={onMouseLeave}
            d={path}
            fill={FILL_MAP[color]}
            clipPath={`url(#slice-${idx})`}
          />
        </>
      )
    })
  })
  return slices
}

export interface ArcProps {
  /** Description */
  name?: string
  archetypes: Array<ArchetypeProps>
  idx?: number
  dimensionCount: number
  startX: number
  startY: number
  radius: number
  setActiveName?: string
  onMouseEnter?: React.ReactElement
  onMouseLeave?: React.ReactElement
}

export const renderArcs = ({
  archetypes,
  dimensionCount,
  startX,
  startY,
  radius
}: ArcProps): Array<React.ReactNode> => {
  let idx = 0
  const arcs = archetypes.map(({ dimensions }) => {
    return dimensions.map(({ name }) => {
      const thisIndex = idx++
      const angle1 = (thisIndex * 360) / dimensionCount
      const angle2 = ((thisIndex + 1) * 360) / dimensionCount

      const calculateCoords = (angle: number) => {
        const iterator: [(val: number) => number, number][] = [
          [Math.cos, startX],
          [Math.sin, startY]
        ]
        return iterator.map(([trigFunction, val]) =>
          getCoord({
            trigFunction,
            radius: radius,
            startingValue: val,
            angle
          })
        )
      }

      // calculate first point coords
      let [x1, y1] = calculateCoords(angle1)

      // calculate second point coords
      let [x2, y2] = calculateCoords(angle2)

      let sweepFlag = 1
      let dy = -1.5
      const midY = (y1 + y2) / 2
      // if the midpoint of the slice is in the bottom half of the canvas,
      // we want to invert the text of the label, so we swap the start and end
      // points, set sweep-flag to 0, and change the y value to move the text by.
      const isLower = midY > startY
      if (isLower) {
        sweepFlag = 0
        // short-hand notation for swapping the values of variables
        ;[[x1, y1], [x2, y2]] = [
          [x2, y2],
          [x1, y1]
        ]
        dy = 5.5
      }
      const pathDefinition = `M ${x1} ${y1} A 50 50 0 0 ${sweepFlag} ${x2} ${y2}`

      const names = name.length > 20 ? name.split(' ') : [name]

      const lines = [] as string[]
      let lastIndex = 0
      names.forEach((val) => {
        if (lastIndex > 0) {
          if (lines[lastIndex - 1].length + val.length < 25) {
            lines[lastIndex - 1] = [lines[lastIndex - 1], val].join(' ')
          } else {
            lines.push(val)
            lastIndex++
          }
        } else {
          lines.push(val)
          lastIndex++
        }
      })

      const textContent = lines.map(
        (n) => `
          <textPath
          xlink:href="#text-path-${idx}"
          text-anchor="middle"
          startOffset="50%"
          fill="${theme.colors?.gray[600]}"
        >
          <tspan dy="${isLower ? dy : dy - 2}">${n}</tspan>
        </textPath>
          `
      )

      return (
        <svg
          dangerouslySetInnerHTML={{
            __html: `<path
            id="text-path-${idx}"
            d="${pathDefinition}"
            stroke="transparent"
            fill="transparent"
          />
          <text style="font-size: 2.5px; text-transform: uppercase;" fill="white" stroke-width="0">
           ${isLower ? textContent : textContent.reverse()}
          </text>
          `
          }}
        />
      )
    })
  })
  return arcs
}
