import { RouteComponentProps, useParams,useNavigate } from '@reach/router'
import { Helmet } from 'react-helmet'
import { useQuery, gql } from '@apollo/client'
import { Loader } from '@plusplusminus/plusplusdash'
import HeadingPage from '../../components/HeadingPage'
import HeadingSection from '../../components/HeadingSection'
import { Color } from '../../color.enum'
import { formatDate } from '../../services/formatDate'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import { language } from '../../common/i18n'
import Button, { ButtonSize, ButtonVariant } from '../../components/Button'

const SingleUserView: React.FC<RouteComponentProps> = () => {
  const { id } = useParams()
  const { loading, data } = useQuery(GET_A_USER, { variables: { id } })
  const navigate = useNavigate()
  if (loading) return <Loader isActive={true} />

  const breadCrumbPath = [
    { name: 'Dashboard', path: '/dashboard/admin/', current: false },
    { name: 'Users', path: `/dashboard/admin/users/${data.user.id}`, current: true }
  ]
  return (
    <div className="page-container-lg">
       <Helmet>
        <title>{language.en.admin.user.singleUserView.title}</title>
        <meta name="description" content={language.en.admin.user.singleUserView.description}/>
      </Helmet>
      
      {!loading && data && (
        <>
          <header>
            <BreadCrumbs className=" pb-10" pages={breadCrumbPath} />
            <HeadingPage
              headline={`${data?.user?.firstName} ${data?.user?.lastName}`}
              color={Color.BLUE}
              description="You can use this view to view the details of the current user."
            />
          </header>

          <HeadingSection
            headline="User Profile"
            color={Color.BLUE}
            children={() => {
              return (
                <div className="flex items-center">
                  <Button
                    className="justify-self-center self-end"
                    variant={ButtonVariant.PRIMARY}
                    color={Color.BLUE}
                    size={ButtonSize.SMALL}
                    onClick={() => {
                      navigate(`/dashboard/admin/${data.user.id}/edit-user`);
                    }}
                  >
                    Edit Profile
                  </Button>
                </div>
              )
            }}
          />
          <div className="mt-5 space-y-1">
            <div>
              <span className="inline text-brand-blue font-medium">Joined on:</span>
              <span className="ml-1">{formatDate(data.user.createdAt)}</span>
            </div>
            <div className="mb-3">
              <p className="inline text-brand-blue font-medium">Email Address:</p>
              <span className="ml-1">{data.user.email}</span>
            </div>

            <div>
              <span className="inline text-brand-blue font-medium">Organisation:</span>
              <span className="ml-1">{data.user.organization ? data.user.organization.name : '-'}</span>
            </div>
            <div>
              <span className="inline text-brand-blue font-medium">Role:</span>
              <span className="ml-1">{data.user.role[0].toUpperCase() + data.user.role.slice(1)}</span>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default SingleUserView

const GET_A_USER = gql`
  query User($id: String!) {
    user(id: $id) {
      id
      createdAt
      updatedAt
      email
      firstName
      lastName
      role
      organization {
        name
      }
      isSuperAdmin
    }
  }
`
