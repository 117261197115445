import { RouteComponentProps, useNavigate, useParams } from '@reach/router'
import { Helmet } from 'react-helmet'
import { Color } from '../../../../color.enum'
import Button from '../../../../components/Button'
import { ButtonVariant, ButtonSize } from '../../../../components/Button'
import { gql, useQuery } from '@apollo/client'
import parse from 'html-react-parser'
import Loader from '../../../../components/Loader/Loader'
import queryString from 'query-string'
import draftToHtml from 'draftjs-to-html'
import { HeadingPage } from '../../../../components'
import { useBreadcrumbs } from '../../../../hooks/useBreadcrumbs'
import BreadCrumbs from '../../../../components/BreadCrumbs/BreadCrumbs'
import { language } from '../../../../common/i18n'
interface SingleFactorTypeInterface extends RouteComponentProps<any> {
  history: any
}
interface DetailsInterface {
  description: string
  color: string
  indexOrder: number
}

const Details: React.FC<DetailsInterface> = ({ description, color, indexOrder }) => {
  return (
    <div className="mt-5 space-y-1">
      {indexOrder && (
        <div>
          <span className="inline text-brand-blue font-medium">Index order:</span>
          <span className="ml-1">{indexOrder}</span>
        </div>
      )}
      {color && (
        <div>
          <span className="inline text-brand-blue font-medium">Color:</span>
          <span className="ml-1">{color}</span>
        </div>
      )}
      {description && (
        <div>
          <span className="inline text-brand-blue font-medium">Description:</span>
          <span className="ml-1">{parse(description)}</span>
        </div>
      )}      
    </div>
  )
}

const SingleFactorType: React.FC<SingleFactorTypeInterface> = () => {
  const navigate = useNavigate()
  const { factorTypeId, surveyId } = useParams()
  const [getBreadCrumbs] = useBreadcrumbs()
  const { loading, data } = useQuery(GET_SINGLE_FACTOR_TYPE, {
    fetchPolicy: 'no-cache',
    variables: { id: factorTypeId }
  })
  const editFactorUrl = queryString.stringifyUrl({
    url: `/dashboard/admin/survey/${surveyId}/factor-type/${factorTypeId}/edit-factor-type`
  })

  const convertRichText = () => {
    try {
      return draftToHtml(JSON.parse(data?.factorType?.description))
    } catch (e) {
      // Incase it's a string? to prevent crash
      return data?.factorType?.description
    }
  }

  const description = convertRichText()

  if (loading) return <Loader color={Color.PURPLE} />

  const { factorType } = data

  const bc = getBreadCrumbs('factorType', surveyId)

  return (
    <div className="py-10">
       <Helmet>
        <title>{language.en.admin.factorType.title}</title>
        <meta name="description" content={language.en.admin.factorType.description}/>
      </Helmet>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <BreadCrumbs pages={bc} className="py-0 pb-10" />
          <HeadingPage
            headline={`${factorType?.name}`}
            color={Color.BLUE}
            description={<Details description={description} color={factorType?.color} indexOrder={factorType?.indexOrder}/>}
            children={() => {
              return (
                <div className="flex items-center">
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    color={Color.BLUE}
                    size={ButtonSize.SMALL}
                    onClick={() => {
                      navigate(editFactorUrl)
                    }}
                  >
                    Edit Factor type
                  </Button>
                </div>
              )
            }}
          />
        </div>
      </main>
    </div>
  )
}

export default SingleFactorType

const GET_SINGLE_FACTOR_TYPE = gql`
  query FactorType($id: String!) {
    factorType(id: $id) {
      id
      name
      code
      description
      color
      indexOrder
    }
  }
`
