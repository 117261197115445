import React from 'react'
import { Color } from '../../color.enum'
import { classNames } from '../../utils/class-names'

export interface ListProps {
  /** Add a children to the component */
  children?: (color: Color) => JSX.Element | Element[]
  /** Aditional Styles for the container */
  className?: string
  /** What color is the componet? */
  color: Color
}

interface ListItemColorProps {
  text: string
}

export const LIST_ITEM_COLOR_MAPS: Record<Color, ListItemColorProps> = {
  [Color.BLUE]: {
    text: 'text-brand-blue'
  },
  [Color.PURPLE]: {
    text: 'text-brand-purple'
  },
  [Color.GRAY]: {
    text: 'text-gray-500'
  }
}

/**
 *  Component to show a List of ListItems for the Admin
 */

const List: React.FC<ListProps> = ({ className, children, color }: ListProps) => {
  return (
    <div className={classNames('List bg-white shadow overflow-hidden sm:rounded-md', className)}>
      {children && <ul className="divide-y divide-gray-200">{children(color)}</ul>}
    </div>
  )
}

List.defaultProps = {}

export default List
