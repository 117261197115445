import { RouteComponentProps } from '@reach/router'
import { Helmet } from 'react-helmet'
import Layout from '../modules/Layout'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import client from '../contentful'
import { Color } from '../color.enum'
import Loader from '../components/Loader/Loader'
import { HERO_FIELDS } from '../modules/HeroWrapper'
import { SECTION_CENTER_FIELDS } from '../modules/SectionCenterWrapper'
import { SECTION_LEFT_FIELDS } from '../modules/SectionLeftWrapper'
import { TESTIMONIAL_FIELDS } from '../modules/TestimonialWrapper'
import { CTA_FIELDS } from '../modules/CtaWrapper'
import { isPreview } from '../contentful'

console.log('isPreview:', isPreview)

  interface PageProps extends RouteComponentProps {
  slug: string
}

const Page: React.FC<PageProps> = ({ slug }) => {
  const { loading, data } = useQuery(pageQuery, { variables: { slug, isPreview }, client: client })
  if (loading) return <Loader color={Color.PURPLE} />
  if (!data) return null
  if (!data.pageCollection.items.length) return <Page default slug="not-found" />
  
  const page = data.pageCollection.items[0].layoutCollection
  const desc = page?.items.find((item: any) => item.__typename === 'LayoutHero')?.headline || data.pageCollection.items[0].name
  const logoUrl = '../../public/mci-logo-center.png'
  const pageUrl = page.url

  console.log(page)
  return (
    <>

   <Helmet
      htmlAttributes={{
        lang:'en',
      }}
      title={data.pageCollection.items[0].name}
      meta={[
        {
          name: `description`,
          content: desc,
        },
        {
          property: `og:title`,
          content: data.pageCollection.items[0].name,
        },
        {
          property: `og:description`,
          content: desc,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: logoUrl,
        },
        {
          name: `og:url`,
          content:  pageUrl,
        },
        {
          name: `og:site_name`,
          content: 'Mission Capacity Inventory',
        },
        {
          name: `og:image:alt`,
          content: 'Mission Capacity Inventory',
        },
      ]}
    />
      <Layout layout={page.items} />

    </>
  )
  
}

export default Page

export const pageQuery = gql`
  ${HERO_FIELDS}
  ${SECTION_CENTER_FIELDS}
  ${SECTION_LEFT_FIELDS}
  ${TESTIMONIAL_FIELDS}
  ${CTA_FIELDS}
  query pageQuery($slug: String!, $isPreview: Boolean = false) {
    pageCollection(limit: 1, where: { slug: $slug }, preview: $isPreview) {
      items {
        slug
        name
        layoutCollection {
          items {
            __typename
            ... on LayoutHero {
              ...HeroFields
            }
            ... on LayoutSectionCenter {
              ...SectionCenterFields
            }
            ... on LayoutSectionLeft {
              ...SectionLeftFields
            }
            ... on LayoutTestimonial {
              ...TestimonialFields
            }
            ... on LayoutCallToAction {
              ...CtaFields
            }
            ... on Content {
              sys {
                id
              }
            }
          }
        }
      }
    }
  }
`
