import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import React from 'react'
import { classNames } from '../../utils/class-names'
import Icon from '../Icon/Icon'
import { IconType } from '../Icon/Icon.options'

export interface AlertProps {
  /** What is the variant of the Alert? */
  variant: AlertVariant
  //** The Size of the alert */
  size: AlertSize
  /** Aditional Styles for the container */
  className?: string
}

interface AlertSizeProps {
  wrapper: string
  icon: Omit<FontAwesomeIconProps, 'icon'>
}

export enum AlertSize {
  LARGE = 'large',
  SMALL = 'small'
}

export const VARIANT_SIZE_MAPS: Record<AlertSize, AlertSizeProps> = {
  [AlertSize.SMALL]: {
    wrapper: 'text-sm py-3 px-4',
    icon: { size: '1x' }
  },

  [AlertSize.LARGE]: {
    wrapper: 'text-sm py-6 px-6',
    icon: { size: '3x' }
  }
}

export enum AlertVariant {
  WARNING = 'warning',
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success'
}

interface AlertVariantProps {
  iconName: IconType
  color: string
}

export const VARIANT_MAPS: Record<AlertVariant, AlertVariantProps> = {
  [AlertVariant.INFO]: {
    iconName: 'info',
    color: 'bg-brand-purple-light text-brand-purple border-brand-purple'
  },
  [AlertVariant.WARNING]: {
    iconName: 'warning',
    color: 'bg-orange-50 text-orange-500 border-orange-500'
  },
  [AlertVariant.ERROR]: {
    iconName: 'error',
    color: 'bg-red-50 text-red-500 border-red-500'
  },
  [AlertVariant.SUCCESS]: {
    iconName: 'success',
    color: 'bg-green-50 text-green-500 border-green-500'
  }
}

export const Alert: React.FC<AlertProps> = ({ variant, size, children, className }) => {
  return (
    <div
      className={classNames(
        `rounded border-solid border`,
        VARIANT_SIZE_MAPS[size].wrapper,
        VARIANT_MAPS[variant].color,
        className
      )}
    >
      <div className={'flex justify-start items-center flex-col sm:flex-row '}>
        {/* {icon && */}
        <div className="items-center mr-3 mb-2 sm:mb-0">
          <Icon name={VARIANT_MAPS[variant].iconName} fontAwesomeIconProps={VARIANT_SIZE_MAPS[size].icon} />
        </div>
        {/* } */}
        <div className="items-center">{children && <div>{children}</div>}</div>
      </div>
    </div>
  )
}

Alert.defaultProps = {
  variant: AlertVariant.ERROR,
  size: AlertSize.LARGE,
  className: 'w-max'
}

export default Alert
