import React from 'react'
import { Color } from '../../color.enum'
import { classNames } from '../../utils/class-names'
import Icon from '../Icon/Icon'
import { IconType } from '../Icon/Icon.options'

export interface FeatureCardProps {
  /** Icon to show with Feature */
  icon?: IconType
  /** Headline of the component */
  headline?: string
  /** Descriptive text to tshow below the headline */
  description?: string | React.ReactNode
  /** Add a children to the component */
  children?: React.ReactElement
  /** Aditional Styles for the container */
  className?: string
  /** What color is the componet? */
  color: Color
}

interface FeatureCardColorProps {
  text: string
  bg: string
}

export const FEATURE_COLOR_MAPS: Record<Color, FeatureCardColorProps> = {
  [Color.GRAY]: {
    text: 'text-gray-500',
    bg: 'bg-gray-100'
  },

  [Color.BLUE]: {
    text: 'text-brand-blue',
    bg: 'bg-brand-blue-light'
  },
  [Color.PURPLE]: {
    text: 'text-brand-purple',
    bg: 'bg-brand-purple-light'
  }
}

/**
 *  Component to show a Feature with optional icon and color
 */

const FeatureCard: React.FC<FeatureCardProps> = ({
  className,
  color,
  icon,
  headline,
  description,
  children
}: FeatureCardProps) => {
  return (
    <div className={classNames('FeatureCard relative bg-white py-4 md:py-12', className)}>
      <div className="pt-6">
        <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
          <div className="-mt-6">
            {icon && (
              <div className="text-center">
                <span
                  className={`inline-flex items-center justify-center p-3 rounded-full shadow-lg text-xl ${FEATURE_COLOR_MAPS[color].bg}`}
                >
                  <Icon
                    name={icon}
                    className={`h-[20px] w-[20px] ${FEATURE_COLOR_MAPS[color].text}`}
                    aria-hidden="true"
                  />
                </span>
              </div>
            )}
            {headline && <h3 className="mt-8 text-lg font-medium text-gray-900 text-center">{headline}</h3>}
            {description && <p className="mt-5 text-base text-gray-500 text-center">{description}</p>}
            {children && children}
          </div>
        </div>
      </div>
    </div>
  )
}

FeatureCard.defaultProps = {
  color: Color.BLUE
}

export default FeatureCard
