import { useMutation, gql } from '@apollo/client'
import { useState, useEffect } from 'react'
import FormLabel from '../../../components/FormLabel/FormLabel'
import { useForm } from 'react-hook-form'
import Button, { ButtonVariant, ButtonSize } from '../../../components/Button'
import { Color } from '../../../color.enum'
import Notice, { NoticeVariant } from '../../../components/Notice'
import Icon from '../../../components/Icon/Icon'

interface Question {
  id: string
  preSurveyOption: PreSurveyOption[]
  preSurveyResponse: any
  text: string
  code: string
}

interface PreSurveyOption {
  id: string
  code: string
  index: string
  text: string
}

interface PreSurvey {
  questions: Question[]
  preSurveyId: string
  respondentId: string | undefined
  setStep: (step: { step: string; props: any }) => void
  orgId: string | undefined
  assignmentId: string
}

interface responseInput {
  preSurveyQuestionId: string
  preSurveyOptionId: string
  assignmentId: string
}

const PresurveyForm: React.FC<PreSurvey> = ({ questions, setStep, ...props }) => {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors }
  } = useForm({ mode: 'onSubmit', reValidateMode: 'onChange' })

  const [isResponseLoading, setResponseLoading] = useState(false)

  const [submitPreSurveyResponse] = useMutation(SUBMIT_PRESURVEY_RESPONSE)
  const [completePreSurvey] = useMutation(COMPLETE_PRESURVEY)
  const [error, setError] = useState({ message: '', action: '' })
  const [success, setSuccess] = useState({ message: '', action: '' })

  const onSubmit = (data: any) => {
    setResponseLoading(true)
    setError({ message: '', action: '' })
    setSuccess({ message: '', action: '' })
    if (data) {
      completePreSurvey({
        variables: {
          assignmentId: props.assignmentId
        }
      })
        .then(({ data }) => {
          setResponseLoading(false)
          setError({ message: '', action: '' })
          if (data) {
            console.log(data)
            setSuccess({
              message: data?.completePreSurvey?.message,
              action: 'no action'
            })
            setStep({ step: 'confirmation', props: props })
          }
        })
        .catch((err) => {
          console.log(err)
          setResponseLoading(false)
          setError({
            message: 'Something went wrong. Please try again later.',
            action: 'no action'
          })
        })
    }
  }

  useEffect(() => {
    if (questions?.length > 0) {
      questions.forEach((question) => {
        if (question.preSurveyResponse) {
          setValue(question.code, question.preSurveyResponse.preSurveyOption?.id)
        }
      })
    }
  }, [])

  const handleOnResponse = (e: any, questionId: string) => {
    setResponseLoading(true)

    const input: responseInput = {
      preSurveyQuestionId: questionId,
      preSurveyOptionId: e.target.value,
      assignmentId: props.assignmentId
    }

    if (input.assignmentId) {
      submitPreSurveyResponse({
        variables: {
          input
        }
      })
        .then(() => {
          setResponseLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setResponseLoading(false)
        })
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {questions?.map((question: any, index: number) => {
          return (
            <div className="flex flex-col mt-8" key={question.id}>
              <FormLabel>{`${index + 1}. ${question.text}`}</FormLabel>

              {question.preSurveyOption.map((option: any) => {
                return (
                  <div className="flex flex-row items-start mb-2" key={option.code}>
                    <input
                      className="mt-[5px] mr-2 text-brand-purple"
                      id={option.code}
                      type="radio"
                      value={option.id}
                      {...register(question.code, {
                        required: 'Select an answer'
                      })}
                      onChange={(e: any) => {
                        clearErrors(question.code)
                        handleOnResponse(e, question.id)
                        setValue(question.code, e.target?.value)
                      }}                      
                    />                    
                    <label className="leading-tight text-base" htmlFor={option.code}>
                      {option.text}
                    </label>
                  </div>
                )
              })}
              {errors[question.code]?.message && (
                <p className="text-sm text-red-500">{errors[question.code]?.message}</p>
              )}
            </div>
          )
        })}
        <div className="">
          {Object.keys(errors)?.length > 0 && <p className="text-sm text-red-500">Answer all the questions</p>}
          <div className=" sm:flex  mt-5 space-x-1 justify-center align-middle">
            <Button
              className="flex-1 justify-center align-middle"
              variant={ButtonVariant.PLAIN}
              size={ButtonSize.LARGE}
              color={Color.BLUE}
              type="submit"
              onClick={() => {
                setStep({ step: 'add-participants', props: props })
              }}
            >
              <Icon className="pr-1" name="arrowLeft" /> Back
            </Button>

            <Button
              iconRight="arrowRight"
              className="flex-1 justify-center align-middle"
              variant={ButtonVariant.PRIMARY}
              color={Color.BLUE}
              size={ButtonSize.LARGE}
              type="submit"
            >
              {!isResponseLoading ? 'Next' : 'Loading'}
            </Button>
          </div>
        </div>
        {error?.message && (
          <Notice
            className="mt-2 mb-1"
            variant={NoticeVariant.SMALL}
            icon="warning"
            color={Color.GRAY}
            children={<div>{error.message}</div>}
          />
        )}
        {success?.message && (
          <Notice
            className="mt-2 mb-1"
            variant={NoticeVariant.SMALL}
            icon="success"
            color={Color.GRAY}
            children={<div>{success?.message}</div>}
          />
        )}
      </form>
    </div>
  )
}

export default PresurveyForm

const SUBMIT_PRESURVEY_RESPONSE = gql`
  mutation submitPreSurveyResponse($input: CreatePreSurveyResponseInput!) {
    submitPreSurveyResponse(input: $input) {
      id
    }
  }
`

const COMPLETE_PRESURVEY = gql`
  mutation completePreSurvey($assignmentId: String!) {
    completePreSurvey(assignmentId: $assignmentId) {
      message
    }
  }
`
