import { RouteComponentProps, useNavigate, useParams } from '@reach/router'
import HeadingSection from '../../../../components/HeadingSection'
import { Color } from '../../../../color.enum'
import Button from '../../../../components/Button'
import { ButtonVariant, ButtonSize } from '../../../../components/Button'
import { gql, useQuery } from '@apollo/client'
import { formatDate } from '../../../../services/formatDate'
import DemographicQuestionList from './demographicQuestionsList'
import Loader from '../../../../components/Loader/Loader'
import { HeadingPage } from '../../../../components'
import Alert, { AlertVariant, AlertSize } from '../../../../components/Alert'
import { useBreadcrumbs } from '../../../../hooks/useBreadcrumbs'
import BreadCrumbs from '../../../../components/BreadCrumbs/BreadCrumbs'
import { Helmet } from 'react-helmet'

interface SingleDemographicDetails {
  headline: string
  description: string
  createdAt: any
  locale: string
}

const Details: React.FC<SingleDemographicDetails> = ({ headline, description, createdAt, locale }) => {
  return (
    <div className="mt-5 space-y-1">
      {headline && (
        <div>
          <span className="inline text-brand-blue font-medium">Headline:</span>
          <span className="ml-1">{headline}</span>
        </div>
      )}
      {description && (
        <div>
          <span className="inline text-brand-blue font-medium">Description:</span>
          <span className="ml-1">{description}</span>
        </div>
      )}
      {createdAt && (
        <div>
          <span className="inline text-brand-blue font-medium">Date Created:</span>
          <span className="ml-1">{createdAt && formatDate(createdAt)}</span>
        </div>
      )}
      {locale && (
        <div>
          <span className="inline text-brand-blue font-medium">Locale:</span>
          <span className="ml-1">{locale}</span>
        </div>
      )}
    </div>
  )
}

const SingleDemographicSurvey: React.FC<RouteComponentProps> = () => {
  const { preSurveyId, surveyId } = useParams()
  const [getBreadCrumbs] = useBreadcrumbs()

  const { loading, data, refetch } = useQuery(GET_PRE_SURVEY, {
    fetchPolicy: 'no-cache',
    variables: { preSurveyId }
  })

  if (loading) return <Loader color={Color.PURPLE} />

  const preSurvey = data?.preSurveyById
  const navigate = useNavigate()
  const bc = getBreadCrumbs('demographic', surveyId, '', 'Organisation Questionaires')
  return (
    <div className="page-container-lg">
      <Helmet>
        <title>{preSurvey?.headline}</title>
        <meta name="description" content={preSurvey?.description} />
      </Helmet>
      <main>
        <BreadCrumbs pages={bc} className="py-0 pb-10" />
        <HeadingPage
          headline={preSurvey?.name ? preSurvey?.name : ''}
          color={Color.BLUE}
          description={
            <Details
              headline={preSurvey?.headline}
              description={preSurvey?.description}
              createdAt={preSurvey?.createdAt}
              locale={preSurvey?.locale?.name}
            />
          }
          children={() => {
            return (
              <div className="flex items-center">
                <Button
                  variant={ButtonVariant.PRIMARY}
                  color={Color.BLUE}
                  size={ButtonSize.SMALL}
                  onClick={() =>
                    navigate(`/dashboard/admin/survey/${surveyId}/demographic-survey/${preSurveyId}/org/edit`)
                  }
                >
                  Edit
                </Button>
              </div>
            )
          }}
        />
        <HeadingSection headline="Demographic Questions" color={Color.BLUE} className="mb-8" />
        {preSurvey?.questions.length > 0 ? (
          <DemographicQuestionList
            questions={preSurvey?.questions}
            surveyId={surveyId}
            type="org"
            demoId={preSurveyId}
            surveyStatus={preSurvey?.survey?.status}
            refetch={refetch}
          />
        ) : (
          <Alert size={AlertSize.LARGE} variant={AlertVariant.INFO} className="my-4">
            Questionaire has no questions
          </Alert>
        )}
        <Button
          className="mt-3"
          iconRight="plus"
          variant={ButtonVariant.PRIMARY}
          color={Color.BLUE}
          size={ButtonSize.MEDIUM}
          onClick={() => navigate(`/dashboard/admin/survey/${surveyId}/preSurvey/${preSurveyId}/question/create`)}
        >
          Add question
        </Button>
      </main>
    </div>
  )
}

export default SingleDemographicSurvey

const GET_PRE_SURVEY = gql`
  query preSurveyById($preSurveyId: String!) {
    preSurveyById(preSurveyId: $preSurveyId) {
      id
      name
      headline
      createdAt
      description
      survey {
        status
      }
      questions {
        id
        text
        description
        preSurveyOption {
          id
          text
          code
        }
        code
      }
      locale {
        id
        name
      }
    }
  }
`
