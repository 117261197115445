import { useState, useEffect } from 'react'
import { RouteComponentProps, useLocation } from '@reach/router'
import queryString from 'query-string'
import { User } from '../../../common/types'
import { PreSurvey } from './PreSurvey'
import { AppParticipant } from './AddParticipant'
import { CreateAssignment } from './CreateAssignment'
import { AssignmentConfirmation } from './AssignmentConfirmation'
import { Helmet } from 'react-helmet'
import { language } from '../../../common/i18n'

interface WizardProps extends RouteComponentProps {
  user?: User
}

interface Step extends RouteComponentProps {
  step: string
  props: any
}

export const AssignmentWizard: React.FC<WizardProps> = ({ user }) => {
  const { search } = useLocation()
  const [step, setStep] = useState<Step>({ step: 'create-assignment', props: {} })

  useEffect(() => {
    const parsed = queryString.parse(search)
    if (parsed?.step && parsed?.assignmentId) {
      const { step: currentStep, assignmentId } = parsed
      setStep({ step: String(currentStep), props: { assignmentId: assignmentId } })
    }
  }, [])

  const getWizardComponent = (currentStep: string) => {
    switch (currentStep) {
      case 'create-assignment':
        return <CreateAssignment user={user} setStep={setStep} {...step.props} />
      case 'add-participants':
        return <AppParticipant user={user} setStep={setStep} {...step.props} />
      case 'pre-survey':
        return <PreSurvey user={user} setStep={setStep} {...step.props} />
      case 'confirmation':
        return <AssignmentConfirmation user={user} setStep={setStep} {...step.props} />
    }
  }

  return (
    <div className="page-container-lg">
      <Helmet>
        <title>{language.en.orgOwner.dashboard.title}</title>
        <meta name="description" content={language.en.orgOwner.dashboard.description} />
      </Helmet>
      {getWizardComponent(step.step)}
    </div>
  )
}
