import { RouteComponentProps, useNavigate, useParams, useLocation } from '@reach/router'
import { Color } from '../../../../color.enum'
import { gql, useMutation } from '@apollo/client'
import { CreateFactorTypeForm } from './createFactorTypeForm'
import HeadingPageCenter from '../../../../components/HeadingPageCenter'
import { Helmet } from 'react-helmet'
import { language } from '../../../../common/i18n'

interface CreateFactorTypeInterface extends RouteComponentProps<any> {
  location: any
}

const CreateFactorType: React.FC<CreateFactorTypeInterface> = () => {
  const navigate = useNavigate()

  const { factorTypeId, surveyId } = useParams()

  const { pathname } = useLocation()

  const [createFactorType, { loading: createFactorLoading, error: createError }] = useMutation(
    CREATE_FACTOR_TYPE,
    {
      onError: (error) => {
        console.log(error)
      },
      onCompleted: (data) => {
        navigate(`/dashboard/admin/survey/${surveyId}/factorType/${data?.createFactorType?.id}`)
      }
    }
  )

  const [updateFactorType, { loading: updateLoading}] = useMutation(UPDATE_FACTOR_TYPE, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: () => {
      navigate(`/dashboard/admin/survey/${surveyId}/factorType/${factorTypeId}`)
    }
  })

  const type = pathname.endsWith('edit-factor-type') ? 'edit' : 'create'

  const onSubmit = (data: any, type: string) => {
    if (type === 'create') {
      const { indexOrder } = data
      createFactorType({ variables: { input: { ...data, indexOrder: Number(indexOrder) } } })
    } else {
      const { indexOrder, ...rest } = data

      updateFactorType({
        variables: {
          id: factorTypeId,
          input: {
            name: rest.name,
            code: rest.code,
            description: rest.description,
            color: rest.color,
            indexOrder: Number(indexOrder)
          }
        }
      })
    }
  }

  return (
    <div className="page-container-lg">
       <Helmet>
        <title>{language.en.admin.factorType.create.title}</title>
        <meta name="description" content={language.en.admin.factorType.create.description}/>
      </Helmet>
      <main>
        <HeadingPageCenter
          className="text-center"
          headline={pathname.endsWith('edit-factor-type') ? 'Edit factor type' : 'Create new factor type'}
          color={Color.PURPLE}
        />
        <div className="section-container-sm">
          <CreateFactorTypeForm
            onSubmit={onSubmit}
            factorTypeId={factorTypeId}
            formType={type}
            createError={createError}
            loading={createFactorLoading || updateLoading}
          />
        </div>
      </main>
    </div>
  )
}

export default CreateFactorType

const CREATE_FACTOR_TYPE = gql`
  mutation createFactorType($input: CreateFactorTypeInput!) {
    createFactorType(input: $input) {
      name
      id
    }
  }
`
const UPDATE_FACTOR_TYPE = gql`
  mutation updateFactorType($id: String!, $input: UpdateFactorTypeInput!) {
    updateFactorType(input: $input, id: $id) {
      name
      id
    }
  }
`
