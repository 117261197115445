import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const link = createHttpLink({
  uri: `${process.env.REACT_APP_API}/graphql`,
  credentials: 'include'
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('mci-token')

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link)
})

export default client
