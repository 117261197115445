import Card, { CardVariant } from '../../../components/Card'

interface FactorProps {
  headline: any
  image: string
  subtitle: string
  renderCta?: JSX.Element
}

const Factor: React.FC<FactorProps> = ({ headline, image, subtitle, renderCta }) => {
  return (
    <main>
      <div className="page-container-lg">
        <Card variant={CardVariant.STANDARD} headline={headline} subtitle={subtitle} media={<img src={image} alt="" />}>
          <>{renderCta}</>
        </Card>
      </div>
    </main>
  )
}

export default Factor
