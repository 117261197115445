import Likert from '../../../components/Likert'
import Steps from '../../../components/Steps'
import Progress from '../../../components/Progress'
import { Color } from '../../../color.enum'
import Alert, { AlertSize, AlertVariant } from '../../../components/Alert'

interface QuestionProps {
  error: string | null
  activeDimension: number
  questionNumber: number
  totalQuestions: number
  descriptor: string
  steps: Array<StepsProps>
  renderCta?: JSX.Element
  handleOnChange: (response: any) => void
  value: number | undefined
  scale: [
    {
      id: string
      code: string
      value: number
      title: string
      name: string
    }
  ]
}
export interface StepsProps {
  title: string
  isComplete: boolean
  color: Color
}
const Question: React.FC<QuestionProps> = ({
  activeDimension,
  questionNumber,
  totalQuestions,
  descriptor,
  steps,
  renderCta,
  value,
  handleOnChange,
  scale,
  error
}) => {
  return (
    <main>
      <div className="page-container-md">
        <div className="mb-6">
          <Steps headline={'Section:'} steps={steps} activeDimension={activeDimension} />
        </div>
        <div className="mb-2">
          <Progress headline="Question:" questionNumber={questionNumber} totalQuestions={totalQuestions} />
        </div>

        <h2 className="text-3xl text-brand-purple">{descriptor}</h2>
        <div className="py-10">
          <p className="mb-4 font-medium text-brand-blue">To what extent do you agree with this statement?</p>
          <Likert scale={scale} handleOnChange={handleOnChange} value={value} />
        </div>
        {renderCta}
        {error && (
          <Alert className="page-container mt-4" size={AlertSize.SMALL} variant={AlertVariant.ERROR}>
            {error}
          </Alert>
        )}
      </div>
    </main>
  )
}

export default Question
