export function svgToBuffer(svg: SVGSVGElement, bufferCallback: (buffer: string | ArrayBuffer) => void): void {
  const copy = svg.cloneNode(true)
  const canvas = document.createElement('canvas')
  canvas.width = 1000
  canvas.height = 1000
  const ctx = canvas.getContext('2d')
  ctx?.clearRect(0, 0, 1000, 1000)
  const data = new XMLSerializer().serializeToString(copy)
  const img = new Image()
  const svgBlob = new Blob([data], { type: 'image/svg+xml;charset=utf-8' })
  const url = URL.createObjectURL(svgBlob)
  img.onload = () => {
    ctx?.drawImage(img, 0, 0)
    URL.revokeObjectURL(url)
    canvas.toBlob(async (blob) => {
      if (blob) {
        const reader = new FileReader()
        reader.addEventListener('loadend', () => {
          const arrayBuffer = reader.result
          if (arrayBuffer) bufferCallback(arrayBuffer)
        })
        reader.readAsArrayBuffer(blob)
      }
    })
  }
  img.src = url
}
