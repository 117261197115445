import List from '../../../../components/List'
import { ListItemCta } from './factorTypeListItems'
import ListItem from '../../../../components/ListItem'
import { Color } from '../../../../color.enum'
import Alert, { AlertVariant, AlertSize } from '../../../../components/Alert'

interface FactorType {
  id: string
  name: string
  code: string
  description: string
  color: string
}

interface FactorTypeItem {
  headline: string
  color?: Color
  renderMeta?: JSX.Element
  renderCta?: (color: Color) => JSX.Element
  description: string
}

interface FactorTypeItemProps {
  factorTypes?: FactorType[] | null
  surveyId?: string
  surveyStatus?: string
  refetch?: any
  hideRemoveButton?:boolean
}

const FactorTypeList: React.FC<FactorTypeItemProps> = ({ factorTypes, surveyId, surveyStatus, refetch,hideRemoveButton }) => {
 

  const items = {
    color: Color.BLUE,
    children: (color: Color): JSX.Element => {
      return (
        <>
          {factorTypes?.map((factorType) => {
            const item: FactorTypeItem = {
              headline: factorType?.name,
              color: Color.BLUE,
              description: factorType?.description,
              renderCta: (color: Color): JSX.Element => {
                return (
                  <ListItemCta
                    color={color}
                    id={factorType?.id}
                    surveyId={surveyId}
                    surveyStatus={surveyStatus}
                    refetch={refetch}
                    hideRemoveButton={hideRemoveButton}
                  />
                )
              }
            }
            return <ListItem color={color} {...item} />
          })}
        </>
      )
    }
  }
  return factorTypes && factorTypes.length ? (
    <List {...items} className="mb-10" />
  ) : (
    <Alert size={AlertSize.LARGE} variant={AlertVariant.INFO} className="my-4">
      No Factor Type found for this Factor. Please add new Factor Type.
    </Alert>
  )
}

export default FactorTypeList
