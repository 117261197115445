import { RouteComponentProps, useNavigate, useParams, useLocation } from '@reach/router'
import { Color } from '../../../../../color.enum'
import { gql, useMutation } from '@apollo/client'
import { CreateQuestionForm } from './createQuestionForm'
import HeadingPageCenter from '../../../../../components/HeadingPageCenter'
import { Helmet } from 'react-helmet'
import { language } from '../../../../../common/i18n'


interface CreateStatementInterface extends RouteComponentProps<any> {
  location: any
}

const CreatePostSurveyQuestion: React.FC<CreateStatementInterface> = (props) => {
  const navigate = useNavigate()
  const { postSurveyId, questionId, surveyId } = useParams()
  const { pathname } = useLocation()

  const [createPostSurveyQuestion, { loading: createPostSurveyQuestionLoading }] = useMutation(
    CREATE_POST_SURVEY_QUESTION,
    {
      onError: (error) => {
        console.log(error)
      },
      onCompleted: (data) => {
        navigate(
          `/dashboard/admin/survey/${surveyId}/postSurvey/${postSurveyId}/question/${data?.createPostSurveyQuestion?.id}`
        )
      }
    }
  )

  const [updatePostSurveyQuestion, { loading: updateLoading, data: updateData }] = useMutation(
    UPDATE_POST_SURVEY_QUESTION,
    {
      onError: (error) => {
        console.log(error)
      },
      onCompleted: () => {
        navigate(`/dashboard/admin/survey/${surveyId}/postSurvey/${postSurveyId}/question/${questionId}`)
      }
    }
  )

  const onSubmit = async (data: any, type: string) => {
    if (type === 'create') {
      createPostSurveyQuestion({
        variables: {
          input: { ...data, postSurveyId, position: Number(data.position) }
        }
      })
    } else {
      const { position, postSurveyOption, ...rest } = data
      updatePostSurveyQuestion({
        variables: {
          id: questionId,
          input: { position: Number(position), code: rest.code,text: rest.text }
        }
      })
    }
  }

  const type = pathname.endsWith('edit') ? 'edit' : 'create'

  return (
    <div className="py-10">
      <Helmet>
        <title>{language.en.admin.question.create.title}</title>
        <meta name="description" content={language.en.admin.question.create.description}/>
      </Helmet>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <HeadingPageCenter
            className="text-center"
            headline={
              type === 'edit'
                ? 'Edit Participant Demographic Questionaire Question'
                : 'Create new Questionaire Question'
            }
            color={Color.PURPLE}
          />
          <CreateQuestionForm
            loading={createPostSurveyQuestionLoading || updateLoading}
            onSubmit={onSubmit}
            postSurveyQuestionId={questionId}
            formType={type}
          />
        </div>
      </main>
    </div>
  )
}

export default CreatePostSurveyQuestion

const CREATE_POST_SURVEY_QUESTION = gql`
  mutation createPostSurveyQuestion($input: CreatePostSurveyQuestionInput!) {
    createPostSurveyQuestion(input: $input) {
      text
      id
      position
    }
  }
`
const UPDATE_POST_SURVEY_QUESTION = gql`
  mutation updatePostSurveyQuestion($id: String!, $input: UpdatePostSurveyQuestionInput!) {
    updatePostSurveyQuestion(input: $input, id: $id) {
      text
      id
      position
    }
  }
`
