import { useEffect, useState } from 'react'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { Helmet } from 'react-helmet'
import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client'
import { Color } from '../../color.enum'
import HeadingPage from '../../components/HeadingPage'
import HeadingSection from '../../components/HeadingSection'
import { User } from '../../common/types'
import Button, { ButtonVariant, ButtonSize } from '../../components/Button'
import AssignmentList from './assignments/OrgAssignments'
import { useForm } from 'react-hook-form'
import Loader from '../../components/Loader/Loader'
import Alert, { AlertVariant, AlertSize } from '../../components/Alert'
import { language } from '../../common/i18n'
import Modal from '../../components/Modal/Modal'
import { Box, Input } from '@plusplusminus/plusplusdash'

interface OwnerProps extends RouteComponentProps {
  user?: User
  test?: string
}

export const OwnerDashboard: React.FC<OwnerProps> = (props) => {
  return <Assignments {...props} />
}

interface ModalProps extends RouteComponentProps {
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  setShowCreateButton: (showModal: boolean) => void
}

const CreateOrgModal: React.FC<ModalProps> = ({ showModal, setShowModal, setShowCreateButton }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()

  const [createOrg, { loading }] = useMutation(CREATE_ORG)

  const onRegister = async (data: any) => {
    createOrg({
      variables: {
        name: data.org
      }
    }).then((response) => {
      setShowCreateButton(false)
      setShowModal(false)
    })
  }

  return (
    <Modal isModalOpen={showModal} title="" onClose={() => setShowModal(false)}>
      <div className="flex-1  flex flex-col">
        <HeadingSection headline="Add new Organisation" color={Color.BLUE} />
        <form
          action="#"
          autoComplete="no"
          onSubmit={handleSubmit(onRegister)}
          className="mt-0 grid grid-cols-2 gap-y-4  sm:gap-x-8"
        >
          <Box className="col-span-2 sm:col-span-2" key="org">
            <label htmlFor="org" className="text-base font-medium text-brand-purple my-1 inline-block">
              Organisation name
            </label>
            <Input as="input" variant="standard" width="full" {...register('org')} />
          </Box>

          <div className="col-span-2">
            <Button
              className="justify-center mb-2 mt-3"
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              color={Color.BLUE}
              type="submit"
              iconRight="arrowRight"
            >
              {loading ? 'Loading' : 'Create'}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export const Assignments: React.FC<OwnerProps> = (props) => {
  const navigate = useNavigate()
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showCreateOrgButton, setShowCreateOrgButton] = useState(false)
  const { loading, data } = useQuery(GET_MY_ASSIGNMENTS, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (!props?.user?.organization?.id) {
      setShowCreateOrgButton(true)
    }
  }, [])

  if (loading) return <Loader color={Color.PURPLE} />

  return (
    <div className="page-container-lg">
      <Helmet>
        <title>{language.en.orgOwner.dashboard.title}</title>
        <meta name="description" content={language.en.orgOwner.dashboard.description} />
      </Helmet>
      <header>
        <HeadingPage
          headline={language.en.orgOwner.dashboard.title}
          color={Color.BLUE}
          description={`Welcome to your dashboard where you can view the status and results of all of your current and past assessments. ${
            showCreateOrgButton
              ? 'Creating an assessment requires an Organisation. To create an Organisation, click on the create Organisation button.'
              : ''
          }`}
        />
      </header>
      <main>
        <HeadingSection
          headline="Assessment"
          color={Color.BLUE}
          children={() => {
            if (!showCreateOrgButton) {
              return (
                <div className="flex items-center">
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    color={Color.BLUE}
                    size={ButtonSize.SMALL}
                    onClick={() => navigate('/dashboard/organization/create-assignment')}
                    iconRight="plus"
                  >
                    Create Assessment
                  </Button>
                </div>
              )
            } else {
              return (
                <div className="flex items-center">
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    color={Color.BLUE}
                    size={ButtonSize.SMALL}
                    onClick={() => setShowCreateModal(true)}
                    iconRight="plus"
                  >
                    Create Organisation
                  </Button>
                </div>
              )
            }
          }}
        />
        {data?.myAssignments.length ? (
          <AssignmentList
            assignments={data?.myAssignments}
            loading={loading}
            organizationId={props?.user?.organization?.id}
          />
        ) : (
          <div className="w-full">
            <Alert size={AlertSize.LARGE} variant={AlertVariant.INFO}>
              Please create your first assessment. To set up a new assessment and assign participants for your
              Organisation, click on "Create Assessment."
            </Alert>
          </div>
        )}
        <CreateOrgModal
          showModal={showCreateModal}
          setShowModal={setShowCreateModal}
          setShowCreateButton={setShowCreateOrgButton}
        />
      </main>
    </div>
  )
}

const CREATE_ORG = gql`
  mutation createOrg($name: String!) {
    createOrg(name: $name) {
      id
    }
  }
`
const GET_MY_ASSIGNMENTS = gql`
  query _getAllUserAssignments {
    myAssignments {
      id
      canViewAssignmentResults
      dueDate
      status
      name
      respondentCount
      preSurveyStatus
      isOwner
      respondentProfile {
        id
        surveyStatus
      }
      organization {
        id
      }
    }
  }
`
