import { RouteComponentProps } from '@reach/router'
import { useQuery, gql } from '@apollo/client'
import PreSurveyForm from './PresurveyForm'
import { User } from '../../../common/types'
import { Color } from '../../../color.enum'
import HeadingPageCenter from '../../../components/HeadingPageCenter'
import Loader from '../../../components/Loader/Loader'

interface PreSurveyProps extends RouteComponentProps {
  user?: User
  setStep: (step: { step: string; props: any }) => void
}

export const PreSurvey: React.FC<PreSurveyProps & any> = ({ user, setStep, ...props }) => {
  const { loading, data } = useQuery(GET_PRE_SURVEYS, {
    variables: { assignmentId: props.assignmentId },
    fetchPolicy: 'no-cache'
  })

  if (loading) return <Loader color={Color.PURPLE} />
  return (
    <>
      <HeadingPageCenter
        className="text-center"
        headline="Organisation's Demographic Questions"
        color={Color.PURPLE}
        description="Please tell us more about your ministry"
      />
      <div className="section-container-sm">
        <PreSurveyForm
          questions={data?.preSurveyByAssignmentId?.questions}
          preSurveyId={data?.preSurveyByAssignmentId?.id}
          respondentId={user?.id}
          orgId={user?.organization?.id}
          setStep={setStep}
          assignmentId={props.assignmentId}
        />
      </div>
    </>
  )
}

const GET_PRE_SURVEYS = gql`
  query PreSurvey($assignmentId: String!) {
    preSurveyByAssignmentId(assignmentId: $assignmentId) {
      id
      name
      description
      headline
      questions(assignmentId: $assignmentId) {
        code
        id
        text
        preSurveyResponse {
          id
          preSurveyOption {
            code
            id
          }
        }
        preSurveyOption {
          text
          code
          index
          id
        }
      }
    }
  }
`
