import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { useQuery, gql } from '@apollo/client'
import Loader from '../../../components/Loader/Loader'
import { Color } from '../../../color.enum'
import parse from 'html-react-parser'
import HeadingPageCenter from '../../../components/HeadingPageCenter'
import { DemographicForm } from './DemographicSurveyForm'
import { User } from '../../../common/types'

interface DemographicSurveyProps extends RouteComponentProps {
  respondentId: string
  assignmentId: string
  user?: User
  setStep: (step: string) => void
}

const DemographicSurvey: React.FC<DemographicSurveyProps> = ({ respondentId, assignmentId, setStep }) => {
  const { loading, data } = useQuery(GET_POST_SURVEYS, { variables: { assignmentId } })

  if (loading && !data) return <Loader color={Color.PURPLE} />

  const { postSurveyByAssignment } = data

  return (
    <div className="page-container-lg">
      <HeadingPageCenter
        className="text-center"
        headline={postSurveyByAssignment.headline}
        color={Color.PURPLE}
        description={parse(postSurveyByAssignment.description)}
      />
      <div className="section-container-sm">
        <DemographicForm
          questions={postSurveyByAssignment.questions}
          demographicSurveyId={postSurveyByAssignment.id}
          respondentId={respondentId}
          setStep={setStep}
        />
      </div>
    </div>
  )
}

export { DemographicSurvey }

const GET_POST_SURVEYS = gql`
  query postSurveyQuery($assignmentId: String!) {
    postSurveyByAssignment(assignmentId: $assignmentId) {
      id
      name
      description
      headline
      questions {
        id
        text
        code
        postSurveyOption {
          id
          text
          index
          code
        }
      }
    }
  }
`
