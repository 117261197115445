import { useState, useContext } from 'react'
import {Helmet} from 'react-helmet';
import Notice, { NoticeVariant } from '../../components/Notice'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import parse from 'html-react-parser'
import { Color } from '../../color.enum'
import HeadingPageCenter from '../../components/HeadingPageCenter'
import { LoginForm } from './ui/LoginForm'
import { OtpForm } from './ui/OtpVerification'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { Context } from '../../context/context'
import { useFormatApolloError } from '../../hooks/useFormatApolloError'
import TermsAndConditions from '../../components/TermsAndConditions'
import { language } from '../../common/i18n';

interface LoginProps extends RouteComponentProps {
  redirect?: string
}

export const Login: React.FC<LoginProps> = () => {
  const [, setValue] = useLocalStorage()
  const [formatError] = useFormatApolloError()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [delayOtp, setDelayOtp] = useState(false)
  const [error, setError] = useState({ message: '', action: '' })
  const [user, setUser] = useState({
    token: ''
  })
  const [step, setStep] = useState('validateEmail')
  const [resendOtpLoading, setResendOtpLoading] = useState<boolean>(false)
  const [resendOtpSuccess, setSuccess] = useState<boolean>(false)
  const [resendOtpResponse, setResponse] = useState<any>({})

  const { contextValue, setContextValue } = useContext(Context)

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  const regToken = urlParams.get('token')
  const afterRegStep = urlParams.get('step')

  const currentStep = afterRegStep ? afterRegStep : step
  const token = regToken ? regToken : user.token

  const [validateEmail] = useMutation(VALIDATE_EMAIL)
  const [login] = useMutation(LOGIN, { fetchPolicy: 'no-cache' })
  const [resendOtp] = useMutation(RESEND_OTP)

  const onLogin = (otp: string) => {
    if (regToken) {
      setUser({ token: regToken })
    }

    setSuccess(false)

    setLoading(true)
    setError({
      message: '',
      action: ''
    })
    if (!!otp) {
      otp = otp.toLowerCase().trim()
    }

    login({
      variables: {
        input: {
          token: token,
          otp: otp
        }
      }
    })
      .then(({ data }) => {
        setLoading(false)
        if (data?.login?.token) {
          setValue('mci-token', data.login.token)
          setContextValue({ ...contextValue, ...JSON.parse(data.login.data) })
          navigate(data.login.redirect)
        }
      })
      .catch((err) => {
        setLoading(false)
        setError({ message: formatError(err), action: 'no action' })
      })
  }

  const onValidateEmail = (email: string) => {
    setLoading(true)
    setError({
      message: '',
      action: ''
    })

    if (!!email) {
      email = email.toLowerCase().trim()
    }

    validateEmail({
      variables: {
        email
      }
    })
      .then(({ data }) => {
        setLoading(false)
        if (data?.validateEmail?.token) {
          const { token } = data.validateEmail
          setUser({ token: token })
          setStep('otp')
        }
      })
      .catch((err) => {
        setLoading(false)
        setError({ message: formatError(err), action: 'no action' })
      })
  }

  const onResendOtp = () => {
    setResendOtpLoading(true)

    const fingerprint = token ? token : user?.token
    setError({ message: '', action: '' })
    if (!fingerprint) {
      setError({ message: 'Something went wrong. Please try again later.', action: 'no action' })
      return
    }
    resendOtp({
      variables: {
        fingerPrint: fingerprint
      }
    })
      .then(({ data }) => {
        setResendOtpLoading(false)
        setSuccess(true)
        setResponse(data)
        setDelayOtp(true)
        setTimeout(
          () => {
            setDelayOtp(false)
          },
          process.env.REACT_APP_OTP_DELAY ? parseInt(process.env.REACT_APP_OTP_DELAY) : 20000
        )
      })
      .catch((err) => {
        setResendOtpLoading(false)

        setError({ message: 'Something went wrong. Please try again later.', action: 'no action' })
      })
  }

  const getWizardComponent = (step: string) => {
    switch (step) {
      case 'validateEmail':
        return <LoginForm onSubmit={onValidateEmail} loading={loading} />
      case 'otp':
        return (
          <OtpForm
            onSubmit={onLogin}
            loading={loading}
            setUser={setUser}
            setError={setError}
            onResendOtp={onResendOtp}
            resendOtpLoading={resendOtpLoading}
            resendOtpResponse={resendOtpResponse}
            resendOtpSuccess={resendOtpSuccess}
            delayOtp={delayOtp}
          />
        )
    }
  }

  return (
    <main>
      <Helmet>
        <title>{language.en.auth.title.login}</title>
        <meta name="description" content={language.en.auth.description.login}/>
      </Helmet>
      <div className="page-container-sm pb-40">
        <HeadingPageCenter
          className="text-center"
          headline="Let's get started"
          color={Color.PURPLE}
          description={parse(
            'Welcome to the <strong>Mission Capacity Inventory (MCI)</strong>.<br/>Please log in here to begin your assessment.'
          )}
        />
        {getWizardComponent(currentStep)}
        {error.message ? (
          <Notice
            className="mt-2 mb-1 w-full"
            variant={NoticeVariant.SMALL}
            icon="warning"
            color={Color.GRAY}
            children={<div>{error.message}</div>}
          />
        ) : null}
        <TermsAndConditions text="By signing in you agree with the" />
      </div>
    </main>
  )
}

const VALIDATE_EMAIL = gql`
  mutation validate($email: String!) {
    validateEmail(email: $email) {
      message
      token
    }
  }
`

const LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      token
      redirect
      data
    }
  }
`
const RESEND_OTP = gql`
  mutation resendOtp($fingerPrint: String!) {
    resendOtp(fingerPrint: $fingerPrint) {
      message
      status
    }
  }
`
