import { useMutation, gql } from '@apollo/client'
import FormLabel from '../../../components/FormLabel/FormLabel'
import { useForm } from 'react-hook-form'
import Button, { ButtonVariant, ButtonSize } from '../../../components/Button'
import { Color } from '../../../color.enum'

interface responseInput {
  postSurveyQuestionId: string
  postSurveyOptionId: string
  respondentId: string
}

interface Question {
  id: string
  option: Option[]
  Response: any
  text: string
  code: string
}

interface Option {
  id: string
  code: string
  index: string
  text: string
}

interface DemographicSurvey {
  questions: Question[]
  demographicSurveyId: string
  respondentId: string | undefined
  setStep: (step: string) => void
}

export const DemographicForm: React.FC<DemographicSurvey> = ({ questions, respondentId, setStep }) => {
  
  const [submitDemoSurveyResponse, { loading: submitLoader }] = useMutation(SUBMIT_POSTS_SURVEY_RESPONSE)
  const [completeDemoSurvey, { loading: completeLoader }] = useMutation(COMPLETE_POST_SURVEY)

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors }
  } = useForm({ mode: 'onSubmit', reValidateMode: 'onChange' })

  const onSubmit = (data: any) => {
    if (data) {
      completeDemoSurvey({
        variables: {
          respondentId: respondentId
        }
      })
        .then(({ data }) => {
          if (data?.completePostSurvey?.status === 'ok') {
            setStep('survey')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const handleOnResponse = (e: any, questionId: string) => {
    const input: responseInput = {
      postSurveyQuestionId: questionId,
      postSurveyOptionId: e.target.value,
      respondentId: String(respondentId)
    }
    submitDemoSurveyResponse({
      variables: {
        input
      }
    })
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {questions?.map((question: any, index: number) => {
          return (
            <div className="flex flex-col mt-8" key={question.id}>
              <FormLabel>{`${index + 1}. ${question.text}`}</FormLabel>

              {question.postSurveyOption.map((option: any) => {
                return (
                  <div className="flex flex-row items-start mb-2" key={option.code}>
                    <input
                      className="mt-1.5 mr-2 text-brand-purple"
                      id={option.id}
                      type="radio"
                      value={option.id}
                      {...register(question.code, {
                        required: 'Select an answer'
                      })}
                      onChange={(e: any) => {
                        clearErrors(question.code)
                        handleOnResponse(e, question.id)
                      }}
                    />
                    <label className="leading-tight mt-1" htmlFor={option.id}>
                      {option.text}
                    </label>
                  </div>
                )
              })}
              {errors[question.code]?.message && (
                <p className="text-sm mb-2 text-red-500">{errors[question.code]?.message}</p>
              )}
            </div>
          )
        })}
        <Button
          className="mt-5"
          variant={ButtonVariant.PRIMARY}
          color={Color.BLUE}
          size={ButtonSize.LARGE}
          iconRight="arrowRight"
          type="submit"
        >
          {completeLoader || submitLoader ? 'Loading' : 'Continue to survey'}
        </Button>
      </form>
    </div>
  )
}

const SUBMIT_POSTS_SURVEY_RESPONSE = gql`
  mutation submitPostSurveyResponse($input: CreatePostSurveyResponseInput!) {
    submitPostSurveyResponse(input: $input) {
      id
    }
  }
`
const COMPLETE_POST_SURVEY = gql`
  mutation completePostSurvey($respondentId: String!) {
    completePostSurvey(respondentId: $respondentId) {
      message
      status
    }
  }
`
