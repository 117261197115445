import React from 'react'
import { Color } from '../../color.enum'
import { classNames } from '../../utils/class-names'

export interface SectionLeftProps {
  /** Subtitle above the headline */
  subtitle?: string
  /** Headline of the component */
  headline?: string
  /** Descriptive text to tshow below the headline */
  description?: React.ReactNode | string
  /** Add a children to the component */
  children?: (color: Color) => JSX.Element
  /** Aditional Styles for the container */
  className?: string
  /** What color is the componet? */
  color: Color
  /** Id to identify specific component as provided by the CMS */
  id?: string
}

interface SectionProps {
  text: string
}

export const SECTION_COLOR_MAPS: Record<Color, SectionProps> = {
  [Color.GRAY]: {
    text: 'text-gray-500'
  },
  [Color.BLUE]: {
    text: 'text-brand-blue'
  },
  [Color.PURPLE]: {
    text: 'text-brand-purple'
  }
}

/**
 *  Component to show an Section that is left aligned
 */

const SectionLeft: React.FC<SectionLeftProps> = ({
  className,
  color,
  subtitle,
  headline,
  description,
  children,
  ...rest
}: SectionLeftProps) => {
  return (
    <div className={classNames('SectionLeft relative', className)} {...rest}>
      <div className="mx-auto max-w-md px-4 text-left sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          {subtitle && (
            <h2 className={`text-sm font-normal tracking-widest uppercase ${SECTION_COLOR_MAPS[color].text}`}>
              {subtitle}
            </h2>
          )}
          {headline && <p className="mt-2 text-4xl text-gray-900 tracking-tight md:text-5xl">{headline}</p>}
          {description && <p className="mt-5 max-w-prose mx-auto text-lg">{description}</p>}
        </div>
        {children && <div className="mt-12 lg:mt-0 lg:col-span-2">{children(color)}</div>}
      </div>
    </div>
  )
}

SectionLeft.defaultProps = {
  color: Color.BLUE
}

export default SectionLeft
