import { RouteComponentProps, useNavigate } from '@reach/router'
import UserList from './UsersTable'
import { useQuery, gql } from '@apollo/client'
import Loader from '../../components/Loader/Loader'
import HeadingPage from '../../components/HeadingPage'
import { Color } from '../../color.enum'
import Button from '../../components/Button'
import { ButtonVariant, ButtonSize } from '../../components/Button'
import HeadingSection from '../../components/HeadingSection'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import {Helmet} from 'react-helmet';
import { language } from '../../common/i18n'

const Users: React.FC<RouteComponentProps> = () => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_USERS, { fetchPolicy: 'no-cache' })

  const navigate = useNavigate()

  const breadCrumbPath = [{ name: 'Users', path: '#', current: true }]
  return (
    <div className="page-container-lg">
      <BreadCrumbs pages={breadCrumbPath} className="pl-0 pb-10" />
      <header>
      <Helmet>
          <title>{language.en.admin.user.list.title}</title>
          <meta name="description" content={language.en.admin.user.list.description}/>
      </Helmet>
        <HeadingPage
          headline="All Users"
          className=""
          color={Color.BLUE}
          description={language.en.admin.user.list.description}
        />
      </header>
      <HeadingSection
        headline="Current Users"
        color={Color.BLUE}
        children={() => {
          return (
            <div className="flex items-center">
              <Button
                variant={ButtonVariant.PRIMARY}
                color={Color.BLUE}
                size={ButtonSize.SMALL}
                onClick={() => navigate('/dashboard/admin/add-user')}
                iconRight="plus"
              >
                Add User
              </Button>
            </div>
          )
        }}
      />

      {loading && <Loader color={Color.PURPLE} className="h-20 flex flex-col items-center justify-center" />}
      {!loading && !error && (
        <UserList
          items={data?.users?.docs}
          refetch={refetch}
          pagination={{
            currentPage: data.users.page,
            totalPages: data.users.totalPages,
            hasNextPage: data.users.hasNextPage,
            hasPrevPage: data.users.hasPrevPage
          }}
          loading={loading}
        />
      )}
    </div>
  )
}
export default Users

const GET_ALL_USERS = gql`
  query Users($sort: SortArgs, $pagination: PaginationArgs, $input: QueryUsersInput) {
    users(sort: $sort, pagination: $pagination, input: $input) {
      docs {
        id
        firstName
        lastName
        email
        role
        organization {
          name
        }
      }
      limit
      totalPages
      page
      hasPrevPage
      hasNextPage
    }
  }
`
