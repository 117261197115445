import { Input } from '@plusplusminus/plusplusdash'
import { useForm } from 'react-hook-form'
import FormLabel from '../../../components/FormLabel/FormLabel'
import Button, { ButtonSize, ButtonVariant } from '../../../components/Button'
import { Color } from '../../../color.enum'
import { InputValidator } from '../../../services/validate'

interface Props {
  //TODO:add proper return type
  // eslint-disable-next-line
  onSubmit?: (data: any) => void
  loading?: boolean
}

type FormInputs = {
  email: string
}

const emailFieldOptions = {
  required: 'Email is required',
  validate: {
    checkEmail: (input: string) => new InputValidator(input).validateEmail() || 'Enter a valid  email'
  }
}

export const LoginForm: React.FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormInputs>({ mode: 'onChange', reValidateMode: 'onChange' })

  const onRegister = async (data: any) => {
    if (props.onSubmit) {
      await props.onSubmit(data.email)
    }
  }

  return (
    <div className="flex flex-col mt-8">
      <FormLabel>Please enter your email here to log in:</FormLabel>
      <form action="#" autoComplete="no" onSubmit={handleSubmit(onRegister)}>
        <Input as="input" variant="standard" width="full" {...register('email', { ...emailFieldOptions })}></Input>
        {errors.email && <p className="text-sm text-red-500">{errors.email.message}</p>}
        <div className="py-4">
          <Button
            className="justify-center"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.LARGE}
            color={Color.BLUE}
            style={{ width: '100%' }}
            type="submit"
          >
            {props.loading ? 'Loading...' : 'Log in'}
          </Button>
        </div>
      </form>
    </div>
  )
}
