import { Box, Input } from '@plusplusminus/plusplusdash'
import { useForm } from 'react-hook-form'
import { InputValidator } from '../../../../../services/validate'
import Button, { ButtonSize, ButtonVariant } from '../../../../../components/Button'
import { Color } from '../../../../../color.enum'
import { gql, useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'
import RichTextInput from '../../../../../components/RichTextInput'
import Loader from '../../../../../components/Loader/Loader'

const form = [
  {
    label: 'Question Code',
    name: 'code',
    placeholder: 'Question Code',
    options: {
      required: 'Question code is required',
    },
    type: 'text'
  },

  {
    label: 'Position',
    name: 'position',
    placeholder: 'Label',
    options: {
      required: 'Position is required',
    },
    type: 'number'
  },
  {
    label: 'Question',
    name: 'text',
    placeholder: 'Question',
    options: {
      required: 'Question is required',
    },

    type: 'textarea'
  },
  {
    label: 'Description',
    name: 'description',
    placeholder: 'Enter factor description here....',
    type: 'textarea'
  }
]

interface Props {
  onSubmit?: (data: any, type: string) => void
  loading?: boolean
  postSurveyQuestionId?: string
  formType: string
  createError?: any
  
}

export const CreateQuestionForm: React.FC<Props> = (props) => {
  
  const { register, handleSubmit, setValue, getValues, formState:{errors} } = useForm()

  const [loadPostSurveyQuestionData, { called, loading, data }] = useLazyQuery(GET_SINGLE_POST_SURVEY_QUESTION, {
    variables: { id: props.postSurveyQuestionId },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const { postSurveyQuestion } = data
      Object.keys(postSurveyQuestion).forEach((pq) => {
        setValue(pq, postSurveyQuestion[pq])
      })
    }
  })

  useEffect(() => {
    if (props?.postSurveyQuestionId) {
      loadPostSurveyQuestionData()
    }
  }, [])

  const onRegister = async (data: any) => {
    console.log(data)
    if (props.onSubmit) {
      await props.onSubmit(data, props.formType)
    }
  }

  if (loading) return <Loader color={Color.PURPLE} />


  return (
    <div className="flex flex-col mt-4 page-container-md">
      <form
        action="#"
        autoComplete="no"
        onSubmit={handleSubmit(onRegister)}
        className="mt-6 grid grid-cols-2 gap-y-4 sm:grid-cols-2 sm:gap-x-8"
      >
         {form.map((field) => {
          if (field.type === 'text' || field.type === 'number') {
            return (
              <Box className="col-span-2 sm:col-span-2" key={field.name}>
                <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
                  {field.label}
                </label>
                <Input as="input" variant="standard" min={1} width="full" type={field.type} {...register(field.name, {...field.options})} />
                {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
              </Box>
            )
          }

          if (field.type === 'textarea') {
            return (
              <Box className="col-span-2 sm:col-span-2 mt-4">
                <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
                  {field.label}
                </label>
                <Input as="textarea" variant="standard" width="full" {...register(field.name, {...field.options})} />
                {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
              </Box>
            )
          }

          if (field.type === 'richText') {
            return (
              <Box className="col-span-2 sm:col-span-2" key={field.name}>
                <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
                  {field.label}
                </label>
                <RichTextInput
                  placeholder={field.placeholder}
                  name={field.name}
                  setValue={setValue}
                  defaultValue={data?.postSurveyQuestion[field.name]}
                />
              </Box>
            )
          }
        })}
        <div className="col-span-2">
          <Button
            className="justify-center mb-2"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.LARGE}
            color={Color.BLUE}
            style={{ width: '100%' }}
            type="submit"
          >
            {(props.loading) ? 'Loading...' : 'Submit'}
          </Button>
        </div>
      </form>
    </div>
  )
}

const GET_SINGLE_POST_SURVEY_QUESTION = gql`
  query _postSurveyQuestion($id: String!) {
    postSurveyQuestion(id: $id) {
      text
      code
      position
      id
      description
      postSurveyOption{
        id
        text
        code
      }
      }
    }
`
