import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

const userQueryGQL = gql`
  query user {
    me {
      id
      firstName
      lastName
      email
      role
      channel{
        id
      }
      organization{
        id
        name
      }
    }
  }
`

//TODO:add proper return type
// eslint-disable-next-line
export const useUserQuery = () => useQuery(userQueryGQL)
