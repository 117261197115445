import { useState } from 'react'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { Color } from '../../color.enum'
import { User } from '../../common/types'
import { useQuery, gql } from '@apollo/client'
import Loader from '../../components/Loader/Loader'
import { Welcome } from './Welcome'
import { DemographicSurvey } from './DemographicSurvey/DemographicSurvey'
import { SurveyWrapper } from './MainSurvey/Survey'
import { Helmet } from 'react-helmet'
import { language } from '../../common/i18n'

interface WelcomeContainerProps extends RouteComponentProps {
  user?: User
  respondentId: string
  assignmentId: string
}

export const SurveyContainer: React.FC<WelcomeContainerProps> = ({ user, respondentId, assignmentId }) => {
  const [step, setStep] = useState<string>('')
  const navigate = useNavigate()
  const { loading, data } = useQuery(GET_RESPONDENT_ASSIGNMENTS, {
    variables: { respondentId, assignmentId },
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      if (res?.respondentAssignment) {
        const { surveyStatus } = res.respondentAssignment
        if (surveyStatus === 'COMPLETED') {
          navigate(`/survey/${assignmentId}/result`)
        } else {
          setStep('welcome')
        }
      }
    }
  })

  const getComponent = (step: string, respondent?: any) => {
    switch (step) {
      case 'welcome':
        return (
          <Welcome
            dueDate={respondent.assignment.dueDate}
            firstName={respondent.user.firstName}
            orgName={respondent.assignment.organization.name}
            respondentId={respondent.id}
            postSurveyStatus={respondent.postSurveyStatus}
            assignmentId={respondent.assignment.id}
            surveyId={respondent.assignment.survey.id}
            surveyStatus={respondent.surveyStatus}
            setStep={setStep}
          />
        )
      case 'demographic-survey':
        return (
          <DemographicSurvey respondentId={respondent.id} setStep={setStep} assignmentId={respondent.assignment.id} />
        )
      case 'survey':
        return (
          <SurveyWrapper
            respondentId={respondent.id}
            surveyId={respondent.assignment.survey.id}
            assignmentId={respondent.assignment.id}
            setStep={setStep}
          />
        )
    }
  }

  if (loading) return <Loader color={Color.PURPLE} />

  const respondent = data.respondentAssignment

  return <>
  <Helmet>
      <title>{respondent.assignment.name}</title>
      <meta
        name="description"
        content={ respondent.assignment.description|| respondent.assignment.name}
      />
      <link rel="icon" type="image/png" href="../public/mci-logo-center.png" />
  </Helmet>
  {getComponent(step, respondent)}
  </>
}

const GET_RESPONDENT_ASSIGNMENTS = gql`
  query RespondentAssignment($respondentId: String!, $assignmentId: String!) {
    respondentAssignment(respondentId: $respondentId, assignmentId: $assignmentId) {
      id
      postSurveyStatus
      surveyStatus
      user {
        email
        firstName
      }
      assignment {
        id
        name
        dueDate
        survey {
          id
        }
        organization{
          id
          name
        }
      }
    }
  }
`
