export const uploadFactorImageFile = async (file: any, factorId: string): Promise<boolean> => {
  const formdata: any = new FormData()
  formdata.append('file', file, file.filename)
  const token = localStorage.getItem('mci-token')

  const myHeaders = new Headers({})
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Access-Control-Allow-Origin', '*')
  myHeaders.append('Authorization', `Bearer ${token}`)

  const requestOptions = {
    method: 'POST',
    body: formdata,
    header: myHeaders
  }

  const response = await fetch(`${process.env.REACT_APP_API}/factors/image/${factorId}`, {
    mode: 'no-cors',
    ...requestOptions
  })
    .then((res) => {
      if (res.ok) {
        return true
      } else {
        return false
      }
    })
    .catch((err) => {
      console.log('err', err)
      return false
    })

  return response
}
