import { RouteComponentProps, useNavigate } from '@reach/router'
import HeadingPage from '../../../components/HeadingPage'
import HeadingSection from '../../../components/HeadingSection'
import { Color } from '../../../color.enum'
import Button from '../../../components/Button'
import { ButtonVariant, ButtonSize } from '../../../components/Button'
import SurveyList from './surveyList'
import { gql, useQuery } from '@apollo/client'
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs'
import Loader from '../../../components/Loader/Loader'
import {Helmet} from 'react-helmet';
import { language } from '../../../common/i18n'
import Alert, { AlertSize, AlertVariant } from '../../../components/Alert'

const Surveys: React.FC<RouteComponentProps> = () => {
  const navigate = useNavigate()
  const { data, loading, refetch } = useQuery(GET_ALL_SURVEYS)
  console.log(data, 'surveys')
  const breadCrumbPath = [
    { name: 'Dashboard', path: '/dashboard/admin', current: false },
    { name: 'Surveys', path: '/dashboard/admin/surveys', current: true }
  ]
  return (
    <div className="page-container-lg">
      <Helmet>
        <title>{language.en.admin.survey.list.title}</title>
        <meta name="description" content={language.en.admin.survey.list.description}/>
      </Helmet>
      <header>
        <BreadCrumbs pages={breadCrumbPath} className="pb-10 px-0" />
        <HeadingPage
          headline={`All Surveys`}
          color={Color.BLUE}
          description={language.en.admin.survey.list.description}
        />
      </header>
      <main>
        <HeadingSection
          headline="Surveys"
          color={Color.BLUE}
          children={() => {
            if (loading) return <></>
            return (
              <div className="flex items-center">
                {data?.surveys.length ? (
                  <></>
                ) : (
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    color={Color.BLUE}
                    size={ButtonSize.SMALL}
                    onClick={() => navigate('/dashboard/admin/create-survey')}
                    iconRight="plus"
                  >
                    Create Survey
                  </Button>
                )}
              </div>
            )
          }}
        />
        {loading ? (
          <Loader color={Color.PURPLE} />
        ) : (
          <>
            {data?.surveys.length > 0 ? (
              <SurveyList surveys={data.surveys} refetch={refetch} />
            ) : (
              <Alert size={AlertSize.LARGE} variant={AlertVariant.INFO} className="mb-4">
                {language.en.admin.survey.emptyList}
              </Alert>

            )}
          </>
        )}
      </main>
    </div>
  )
}

export default Surveys

const GET_ALL_SURVEYS = gql`
  query Surveys {
    surveys {
      id
      createdAt
      status
      name
      locale {
        name
        id
      }
      factors {
        id
        name
      }
    }
  }
`
