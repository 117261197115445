import { RouteComponentProps, useNavigate, useParams, useLocation } from '@reach/router'
import { Color } from '../../../../../color.enum'
import { gql, useMutation } from '@apollo/client'
import { CreateQuestionForm } from './createQuestionForm'
import HeadingPageCenter from '../../../../../components/HeadingPageCenter'
import { Helmet } from 'react-helmet'
import { language } from '../../../../../common/i18n'

interface CreateStatementInterface extends RouteComponentProps<any> {
  location: any
}

const CreatePreSurveyQuestion: React.FC<CreateStatementInterface> = () => {
  const navigate = useNavigate()
  const { preSurveyId, questionId, surveyId } = useParams()
  const { pathname } = useLocation()

  const [createPreSurveyQuestion, { loading: createPreSurveyQuestionLoading }] = useMutation(
    CREATE_PRE_SURVEY_QUESTION,
    {
      onCompleted: (data) => {
        navigate(
          `/dashboard/admin/survey/${surveyId}/preSurvey/${preSurveyId}/question/${data?.createPreSurveyQuestion?.id}`
        )
      }
    }
  )

  const [updatePreSurveyQuestion, { loading: updateLoading }] = useMutation(UPDATE_PRE_SURVEY_QUESTION, {
    onCompleted: (data) => {
      navigate(`/dashboard/admin/survey/${surveyId}/preSurvey/${preSurveyId}/question/${questionId}`)
    }
  })

  const onSubmit = async (data: any, type: string) => {
    if (type === 'create') {
      createPreSurveyQuestion({
        variables: {
          input: { ...data, preSurveyId, position: Number(data.position) }
        }
      })
    } else {
      const { position, ...rest } = data
      updatePreSurveyQuestion({
        variables: {
          id: questionId,
          input: { position: Number(position), code: rest.code, description: rest.description, text: rest.text }
        }
      })
    }
  }

  const type = pathname.endsWith('edit') ? 'edit' : 'create'

  return (
    <div className="page-container-lg">
       <Helmet>
        <title>{language.en.admin.question.create.title}</title>
        <meta name="description" content={language.en.admin.question.create.description}/>
      </Helmet>
      <main>
        <HeadingPageCenter
          className="text-center"
          headline={
            pathname.endsWith('edit')
              ? 'Edit Organisation Demographic Questionaire Question'
              : 'Create new Questionaire Question'
          }
          color={Color.PURPLE}
        />
        <div className="section-container-sm">
          <CreateQuestionForm
            loading={createPreSurveyQuestionLoading || updateLoading}
            onSubmit={onSubmit}
            preSurveyQuestionId={questionId}
            formType={type}
          />
        </div>
      </main>
    </div>
  )
}

export default CreatePreSurveyQuestion

const CREATE_PRE_SURVEY_QUESTION = gql`
  mutation createPreSurveyQuestion($input: CreatePreSurveyQuestionInput!) {
    createPreSurveyQuestion(input: $input) {
      text
      id
      description
    }
  }
`
const UPDATE_PRE_SURVEY_QUESTION = gql`
  mutation updatePreSurveyQuestion($id: String!, $input: UpdatePreSurveyQuestionInput!) {
    updatePreSurveyQuestion(input: $input, id: $id) {
      text
      id
      description
    }
  }
`
