import { RouteComponentProps, useNavigate, useLocation, useParams } from '@reach/router'
import { Box, Input } from '@plusplusminus/plusplusdash'
import { Color } from '../../../color.enum'
import Button from '../../../components/Button'
import { ButtonVariant, ButtonSize } from '../../../components/Button'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { User } from '../../../common/types'
import { useState } from 'react'
import HeadingPageCenter from '../../../components/HeadingPageCenter'
import {Helmet} from 'react-helmet';
import { language } from '../../../common/i18n'

const form = [
  {
    label: 'Survey Name',
    name: 'name',
    type: 'text',
    placeholder: 'Survey Name',
    options: {
      required: 'Survey Name is required'
    }
  }
]

interface CreateEditSurveyProps extends RouteComponentProps {
  user?: User
}

const CreateEditSurvey: React.FC<CreateEditSurveyProps> = ({ user }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { surveyId } = useParams()

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  const surveyName = urlParams.get('surveyName')
  const localeName = urlParams.get('localeName')
  const localeId = urlParams.get('localeId')

  const [editedlocaleId, setEditedLocaleId] = useState<any>(null)
  const [editedSurveyName, setEditedSurveyName] = useState<any>(null)
  const { data: localesData } = useQuery(GET_LOCALES)

  const [createSurvey, { loading }] = useMutation(CREATE_SURVEY, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      console.log(data)
      navigate(`/dashboard/admin/survey/${data.createSurvey?.id}`)
    }
  })

  const [updateSurvey, { loading: updateLoading }] = useMutation(EDIT_SURVEY, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      console.log(data)
      navigate(`/dashboard/admin/survey/${data.updateSurvey?.id}`)
    }
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange' })

  const onRegister = async (data: any) => {
    if (pathname === `/dashboard/admin/survey/${surveyId}/edit-survey`) {
      const updateData: any = {}
      if (editedlocaleId) {
        updateData.localeId = editedlocaleId
      }
      if (editedSurveyName) {
        updateData.name = editedSurveyName
      }
      updateSurvey({
        variables: {
          surveyId,
          input: { ...updateData, channelId: user?.channel.id }
        }
      }).then((response) => {
        console.log(response.data)
      })
    }
    if (pathname === `/dashboard/admin/create-survey`) {
      createSurvey({
        variables: {
          input: { ...data, channelId: user?.channel.id }
        }
      })
    }
  }

  return (
    <div className="page-container-lg">
      <Helmet>
        <title>{language.en.admin.survey.create.title}</title>
        <meta name="description" content={language.en.admin.survey.create.description}/>
      </Helmet>
      <header>
        <HeadingPageCenter
          headline={pathname === `/dashboard/admin/survey/${surveyId}/edit-survey` ? 'Edit Survey' : 'Create Survey'}
          color={Color.BLUE}
          description={
            pathname === `/dashboard/admin/survey/${surveyId}/edit-survey`
              ? 'Please edit the relevant details below'
              : 'Please enter details below to create new Survey'
          }
        />
      </header>
      <main>
        <div className="section-container-sm">
          <form
            action="#"
            autoComplete="no"
            onSubmit={handleSubmit(onRegister)}
            className="mt-6 grid grid-cols-2 gap-y-4 sm:grid-cols-2 sm:gap-x-8"
          >
            {form.map((field) => {
              return (
                <Box className="col-span-2 sm:col-span-2" key={field.name}>
                  <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
                    {field.label}
                  </label>
                  <Input
                    as="input"
                    variant="standard"
                    defaultValue={surveyName ? surveyName : ''}
                    width="full"
                    {...register(field.name, { ...field.options })}
                    onChange={(e) => {
                      setEditedSurveyName(e.target.value)
                    }}
                  ></Input>
                  {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
                </Box>
              )
            })}

            <Box className="col-span-2 sm:col-span-2">
              <label htmlFor="Locale" className="text-base font-medium text-brand-purple my-1 inline-block">
                Locale
              </label>
              <select
                {...register('localeId')}
                onChange={(e) => {
                  console.log(editedlocaleId, 'edited locale id')
                  setEditedLocaleId(e.target.value)
                }}
                className=" flex w-full rounded border border-gray-300 text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none"
              >
                {localeName && localeId ? (
                  <option>{localeName}</option>
                ) : (
                  <option value="" disabled selected>
                    Select locale
                  </option>
                )}
                {localesData?.locales.map((locale: any) => {
                  return (
                    <option value={locale.id} key={locale.id}>
                      {locale.name}
                    </option>
                  )
                })}
              </select>
              {errors?.localeId?.message && <p className="text-sm text-red-500">{errors.localeId.message}</p>}
            </Box>
            <div className="col-span-2">
              {localeName && localeId ? (
                <Button
                  className="justify-center mb-2"
                  variant={ButtonVariant.PRIMARY}
                  size={ButtonSize.LARGE}
                  color={Color.BLUE}
                  style={{ width: '100%' }}
                  type="submit"
                >
                  {updateLoading ? 'Loading...' : 'Save'}
                </Button>
              ) : (
                <Button
                  className="justify-center mb-2"
                  variant={ButtonVariant.PRIMARY}
                  size={ButtonSize.LARGE}
                  color={Color.BLUE}
                  style={{ width: '100%' }}
                  type="submit"
                >
                  {loading ? 'Loading...' : 'Create Survey'}
                </Button>
              )}
            </div>
          </form>
        </div>
      </main>
    </div>
  )
}

export default CreateEditSurvey

export const CREATE_SURVEY = gql`
  mutation createSurvey($input: CreateSurveyInput!) {
    createSurvey(input: $input) {
      id
      name
    }
  }
`

export const EDIT_SURVEY = gql`
  mutation updateSurvey($input: UpdateSurveyInput!, $surveyId: String!) {
    updateSurvey(input: $input, surveyId: $surveyId) {
      id
      name
    }
  }
`

export const GET_LOCALES = gql`
  query GetLocalesQuery {
    locales {
      id
      name
      code
    }
  }
`
