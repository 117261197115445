import { Input } from '@plusplusminus/plusplusdash'
import { useForm } from 'react-hook-form'
import FormLabel from '../../../components/FormLabel/FormLabel'
import Button, { ButtonSize, ButtonVariant } from '../../../components/Button'
import { Color } from '../../../color.enum'
import { InputValidator } from '../../../services/validate'
import Alert, { AlertSize, AlertVariant } from '../../../components/Alert'
interface Props {
  onSubmit?: (data: any) => void
  loading?: boolean
  resendOtp?: (data: any) => void
  setUser?: (data: any) => void
  setError?: (data: any) => void
  onResendOtp: () => void
  resendOtpLoading: boolean
  resendOtpResponse?: any
  resendOtpSuccess: boolean
  delayOtp: boolean
}
const otpFieldOptions = {
  required: 'OTP is required',
  validate: (input: string) => new InputValidator(input).validateOtp() || 'Enter a valid OTP'
}
export const OtpForm: React.FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const onRegister = async (data: any) => {
    if (props.onSubmit) {
      await props.onSubmit(data.otp)
    }
  }
  return (
    <div className="flex flex-col mt-8">
      <Alert className={'mb-2 w-full'} size={AlertSize.SMALL} variant={AlertVariant.INFO}>
        Check your email for your OTP
      </Alert>
      <FormLabel>Please enter your OTP (One time password) here to log in:</FormLabel>
      <form action="#" autoComplete="no" onSubmit={handleSubmit(onRegister)}>
        <Input as="input" variant="standard" width="full" {...register('otp', { ...otpFieldOptions })}></Input>
        {errors?.otp?.message && <p className="text-sm text-red-500">{errors.otp.message}</p>}
        {errors.otp && !errors.otp.message && <p className="text-sm text-red-500">This field is required</p>}
        <div className="py-4">
          <Button
            className="justify-center"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.LARGE}
            color={Color.BLUE}
            style={{ width: '100%' }}
            type="submit"
          >
            {props.loading ? 'Loading...' : 'Log in'}
          </Button>
          <Button
            className="justify-center mt-3"
            variant={ButtonVariant.PLAIN}
            size={ButtonSize.LARGE}
            color={Color.BLUE}
            style={{ width: '100%' }}
            onClick={(e) => {
              e.preventDefault()
              props.onResendOtp()
            }}
            disabled={props.delayOtp}
            iconRight="sync"
          >
            {props.resendOtpLoading ? 'Sending...' : 'Resend OTP'}
          </Button>
          {props.resendOtpSuccess ? (
            <Alert
              className="w-full"
              variant={AlertVariant.SUCCESS}
              size={AlertSize.SMALL}
              children={<div>{props?.resendOtpResponse?.resendOtp?.message}</div>}
            />
          ) : null}
        </div>
      </form>
    </div>
  )
}
