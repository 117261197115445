import React from 'react'
import { Cta } from '../components'
import gql from 'graphql-tag'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Color } from '../color.enum'
import bg from '../../public/mci-bg.png'
import { Content } from '../common/types'
import LinkWrapper, { LINK_FIELDS, LinkProps } from './LinkWrapper'

interface CtaProps {
  subtitle?: string
  ctaHeadline?: Content
  description?: Content
  color: Color
  className?: string
  callToAction?: callToAction
  sys: { id: string }
}

interface callToAction {
  className?: string
  linksCollection?: { items: [LinkProps] }
}

const CtaWrapper: React.FC<CtaProps> = ({
  ctaHeadline,
  subtitle,
  description,
  color,
  className,
  sys,
  callToAction
}) => {
  return (
    <Cta
      className={className}
      image={<img src={bg} alt="" className="h-full object-cover" />}
      color={color}
      subtitle={subtitle}
      headline={ctaHeadline && documentToReactComponents(ctaHeadline.json)}
      description={description && documentToReactComponents(description.json)}
      id={sys.id}
    >
      {(color: Color): JSX.Element => {
        return (
          <>
            {callToAction && callToAction.linksCollection && callToAction.linksCollection.items.length > 0 ? (
              <div className={callToAction.className}>
                {callToAction.linksCollection.items.map((i, index) => {
                  return <LinkWrapper key={index} {...i} color={color} />
                })}
              </div>
            ) : null}
          </>
        )
      }}
    </Cta>
  )
}

export default CtaWrapper

export const CTA_FIELDS = gql`
  ${LINK_FIELDS}
  fragment CtaFields on LayoutCallToAction {
    sys {
      id
    }
    name
    description {
      json
    }
    ctaHeadline: headline {
      json
    }
    subtitle
    color
    className
    callToAction {
      className
      linksCollection(limit: 4) {
        items {
          ...LinkFields
        }
      }
    }
  }
`
