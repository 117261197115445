import { RadioGroup } from '@headlessui/react'
import { classNames } from '../../utils/class-names'

export interface LikertProps {
  /** Likert Scale points */
  scale: Array<ScaleProps>
  /** Aditional Styles for the containers */
  className?: string
  handleOnChange: (response: any) => void
  value: number | undefined
}

interface ScaleProps {
  /** Aditional Styles for the containers */
  name: string
  value: number
  code: string
  title: string
}

export interface LikertInputProps {
  /** What is the label for this input? */
  name: string
  /** Has this input been selected? */
  isSelected: boolean
  /** Aditional Styles for the containers */
  className?: string
}

/**
 *  Component to show Likert scale for rating purposes
 */

const Likert: React.FC<LikertProps> = ({ className, scale, handleOnChange, value }) => {
  return (
    <div className={classNames(className, 'Likert h-full relative bg-gray-100 pt-4 pb-14 px-6')}>
      <div className={classNames('Container bg-white py-2 px-2 rounded-full')}>
        <RadioGroup value={value} onChange={handleOnChange}>
          <RadioGroup.Label className="sr-only">Likert Scale</RadioGroup.Label>
          <div
            className={classNames(
              'Line relative flex items-center justify-between before:absolute before:top-[6px] before:h-[1px] before:bg-brand-blue before:w-full'
            )}
          >
            {scale && scale.length > 0
              ? scale.map((i) => {

                return (
                  <RadioGroup.Option
                    key={i.name}
                    value={i.value}
                    className={({ active }) =>
                      classNames(active ? 'ring-1 ring-offset-2 ring-brand-blue-light rounded-full' : '')
                    }
                  >
                    {(item) => {
                      return <LikertInput name={i.title} isSelected={item.checked} />
                    }}
                  </RadioGroup.Option>
                )
              })
              : null}
          </div>
        </RadioGroup>
      </div>
    </div>
  )
}

Likert.defaultProps = {}

export default Likert

/**
 *  Component to show Inputs for the Likert scale component
 */

export const LikertInput: React.FC<LikertInputProps> = ({ name, isSelected, className }) => {
  return (
    <div
      className={classNames(
        className,
        'Point relative flex flex-col items-center justify-center hover:cursor-pointer rounded-full hover:font-medium transition-all',
        isSelected
          ? 'ring-1 ring-offset-2 ring-brand-blue-light '
          : 'hover:ring-1 hover:ring-offset-2 hover:ring-brand-blue-light'
      )}
    >
      <div
        className={classNames(
          'Circle pt-2 rounded-full w-3 h-3 border-brand-blue border border-1 transition-all',
          !isSelected ? ' bg-brand-blue-light' : ' bg-brand-blue'
        )}
      >
        <div className={classNames('LabelWrapper absolute top-2 translate-x-[-50%] left-[50%]')}>
          <RadioGroup.Label
            as="p"
            className={classNames(
              'Name m-0 mt-4 text-center leading-tight text-xs md:leading-tight md:text-sm text-brand-blue',
              isSelected && 'font-medium'
            )}
          >
            {name}
          </RadioGroup.Label>
        </div>
      </div>
    </div>
  )
}

LikertInput.defaultProps = {
  isSelected: false,
  name: 'Option Label'
}
