import { useState } from 'react'
import { RouteComponentProps, useNavigate, useParams } from '@reach/router'
import {Helmet} from 'react-helmet';
import { Color } from '../../../color.enum'
import Button, { ButtonVariant, ButtonSize } from '../../../components/Button'
import Alert, { AlertSize, AlertVariant } from '../../../components/Alert'
import { gql, useQuery, useMutation } from '@apollo/client'
import Tabs from '../../../components/Tabs'
import { formatDate } from '../../../services/formatDate'
import FactorList from './factors/factorList'
import DemoGraphicList from './demographicSurveys/demographicList'
import queryString from 'query-string'
import Loader from '../../../components/Loader/Loader'
import FactorTypeList from './factors/factorType'
import HeadingSection from '../../../components/HeadingSection'
import HeadingPage from '../../../components/HeadingPage'
import { useBreadcrumbs } from '../../../hooks/useBreadcrumbs'
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs'
import { language } from '../../../common/i18n';
import { EDIT_SURVEY } from './createEditSurvey'
import Modal from '../../../components/Modal/Modal'

const SingleSurvey: React.FC<RouteComponentProps> = () => {
  const navigate = useNavigate()
  const { surveyId } = useParams()

  const [getBreadCrumbs] = useBreadcrumbs()
  const { loading, data, refetch } = useQuery(GET_A_SINGLE_SURVEY, {
    variables: { id: surveyId },
    fetchPolicy: 'no-cache'
  })

const { data: factorTypeData, refetch: refetchFactorTypes } = useQuery(GET_ALL_FACTOR_TYPES, {fetchPolicy: 'no-cache'})

  const [deletePreSurvey] = useMutation(DELETE_PRE_SURVEY, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      refetch({ id: surveyId })
    }
  })

  const [deletePostSurvey] = useMutation(DELETE_POST_SURVEY, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      refetch({ id: surveyId })
    }
  })

  const tabsData = [
    {
      name: 'Factors',
      content: (
        <div>
          {data?.surveyById?.factors <= 0 ? (
            <Alert size={AlertSize.LARGE} variant={AlertVariant.INFO} className="mb-4">
              No Factors found for this Survey. Please add new Factor.
            </Alert>
          ) : (
            <FactorList
              factors={data?.surveyById?.factors}
              surveyId={surveyId}
              surveyStatus={data?.surveyById?.status}
              refetch={refetch}
            />
          )}

          <Button
            variant={ButtonVariant.PRIMARY}
            color={Color.BLUE}
            size={ButtonSize.MEDIUM}
            onClick={() => navigate(`/dashboard/admin/survey/${surveyId}/create-factor`)}
            iconRight="plus"
          >
            Add Factor
          </Button>
        </div>
      )
    },
    {
      name: 'Factor Types',
      content: (
        <div>
          {!factorTypeData?.allFactoryTypes.length ? (
            <Alert size={AlertSize.LARGE} variant={AlertVariant.INFO} className="mb-4">
              No Factor Type found for this Survey. Please add new Factor Type.
            </Alert>
          ) : (
            <FactorTypeList
              factorTypes={factorTypeData.allFactoryTypes}
              surveyId={surveyId}
              surveyStatus={data?.surveyById?.status}
              refetch={refetchFactorTypes}
            />
          )}

          <Button
            variant={ButtonVariant.PRIMARY}
            color={Color.BLUE}
            size={ButtonSize.MEDIUM}
            onClick={() => navigate(`/dashboard/admin/survey/${surveyId}/factorType/create-factor-type`)}
            iconRight="plus"
          >
            Add Factor type
          </Button>
        </div>
      )
    },
    {
      name: 'Demographic Questionaires',
      content: (
        <>
          <div id="organization_survey">
            <HeadingSection color={Color.BLUE} headline="Organisation Questionaires" />
            {data?.surveyById?.preSurvey ? (
              <DemoGraphicList
                survey={data?.surveyById?.preSurvey}
                type="org"
                deleteSurvey={deletePreSurvey}
                surveyId={surveyId}
                surveyStatus={data?.surveyById?.status}
                refetch={refetch}
              />
            ) : (
              <div className="my-5">
                <Alert size={AlertSize.LARGE} variant={AlertVariant.INFO} className="mb-4">
                  No Questionaire found for this Survey. Please add new Questionaire.
                </Alert>
                <Button
                  variant={ButtonVariant.PRIMARY}
                  color={Color.BLUE}
                  size={ButtonSize.MEDIUM}
                  onClick={() => navigate(`/dashboard/admin/${surveyId}/create-presurvey`)}
                  iconRight="plus"
                >
                  Add Questionaire
                </Button>
              </div>
            )}
          </div>
          <div id="respondent_survey">
            <HeadingSection color={Color.BLUE} headline="Participant Questionaires" className="mb-4" />
            {data?.surveyById?.postSurvey ? (
              <DemoGraphicList
                survey={data?.surveyById?.postSurvey}
                type="participant"
                deleteSurvey={deletePostSurvey}
                surveyId={surveyId}
                surveyStatus={data?.surveyById?.status}
                refetch={refetch}
              />
            ) : (
              <div className="my-5">
                <Alert size={AlertSize.LARGE} variant={AlertVariant.INFO} className="mb-4">
                  No Questionaire found for this Survey. Please add new Questionaire.
                </Alert>
                <Button
                  variant={ButtonVariant.PRIMARY}
                  color={Color.BLUE}
                  size={ButtonSize.MEDIUM}
                  onClick={() => navigate(`/dashboard/admin/${surveyId}/create-postsurvey`)}
                  iconRight="plus"
                >
                  Add Questionaire
                </Button>
              </div>
            )}
          </div>
        </>
      )
    }
  ]

  const editSurveyUrl = queryString.stringifyUrl({
    url: `/dashboard/admin/survey/${data?.surveyById.id}/edit-survey`,
    query: {
      surveyName: data?.surveyById.name,
      localeId: data?.surveyById?.locale?.id,
      localeName: data?.surveyById?.locale.name
    }
  })

  const bc = getBreadCrumbs('survey', data?.surveyById?.id)
  interface DetailsInterface {
    locale?: React.ReactNode
    date?: React.ReactNode
    status: string
  }

  const Details: React.FC<DetailsInterface> = ({ locale, date, status }) => {
    const [showNotification, setShowNotification] = useState(false)
    const [surveyStatus, setSurveyStatus] = useState<string>(status)
    const [updateSurvey, { loading }] = useMutation(EDIT_SURVEY)
    return (
      <div className="mt-5 space-y-1">
        <Modal isModalOpen={showNotification} title="" onClose={() => setShowNotification(false)}>
          <div className="flex-1  flex flex-col">
            <HeadingSection headline="Change survey status warning" color={Color.BLUE} />

            <div>
              <p className="text-md text-brand-purple">
                {['DRAFT', 'CLOSED'].includes(surveyStatus)
                  ? 'This will make the survey unavailable for participants, are you want sure?'
                  : 'Wont be able to remove any entities when published. Are you sure you want to publish this survey?'}
              </p>
              <div className="flex space-x-1  mt-3">
                <Button
                  className="flex-1 text-center justify-center outline-none"
                  variant={ButtonVariant.PLAIN}
                  color={Color.BLUE}
                  size={ButtonSize.LARGE}
                  onClick={() => {         
                    setSurveyStatus(status)           
                    setShowNotification(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="flex-1 text-center justify-center"
                  variant={ButtonVariant.PRIMARY}
                  color={Color.BLUE}
                  size={ButtonSize.LARGE}
                  onClick={() =>
                    updateSurvey({
                      variables: {
                        surveyId,
                        input: { status: surveyStatus }
                      }
                    }).then(() => {
                      refetch()
                      setShowNotification(false)
                    })
                  }
                >
                  {loading ? 'Updating..' : 'Update'}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <div>
          <span className="inline text-brand-blue font-medium">Survey Status:</span>
          <span className="ml-1">
            <select
              className="rounded border border-gray-300 text-gray-600  pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none"
              value={surveyStatus || ''}                          
              onChange={(e) => {
                e.preventDefault()
                setSurveyStatus(e.target.value)
                setShowNotification(true)
              }}
            >
              {[
                { name: 'Publish', value: 'PUBLISHED' },
                { name: 'Close', value: 'CLOSED' },
                { name: 'Draft', value: 'DRAFT' }
              ].map((option: { name: string; value: string }) => (
                <option value={option.value} key={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </span>
        </div>
        {date && (
          <div>
            <span className="inline text-brand-blue font-medium">Date Created:</span>
            <span className="ml-1">{date}</span>
          </div>
        )}
        {locale && (
          <div>
            <span className="inline text-brand-blue font-medium">Locale:</span>
            <span className="ml-1">{locale}</span>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="page-container-lg">
      <Helmet>
         <title>{data?.surveyById?.name}</title>
         <meta name="description" content={data?.surveyById?.description || language.en.admin.survey.singleSurvey.description}/>
      </Helmet>
      <main>
        {loading ? (
          <Loader color={Color.PURPLE} />
        ) : (
          <>
            <BreadCrumbs pages={bc} className="py-0 pb-10" />
            <HeadingPage
              headline={data?.surveyById?.name}
              color={Color.BLUE}
              description={
                <Details
                  locale={data?.surveyById?.locale.name}
                  date={data?.surveyById?.createdAt && formatDate(data?.surveyById.createdAt)}
                  status={data?.surveyById?.status}
                />
              }
              children={() => {
                return (
                  <div className="flex items-center">
                    <Button
                      variant={ButtonVariant.PRIMARY}
                      color={Color.BLUE}
                      size={ButtonSize.SMALL}
                      onClick={() => navigate(editSurveyUrl)}
                    >
                      Edit Survey
                    </Button>
                  </div>
                )
              }}
            />

            <Tabs tabs={tabsData} />
          </>
        )}
      </main>
    </div>
  )
}

export default SingleSurvey

const GET_A_SINGLE_SURVEY = gql`
  query SurveyById($id: String!) {
    surveyById(id: $id) {
      id
      name
      locale {
        id
        name
      }
      createdAt
      status
      factors {
        id
        name
        factorType {
          name
        }
        statements {
          id
        }
      }
      preSurvey {
        id
        name
        questions {
          id
        }
      }
      postSurvey {
        id
        name
        questions {
          id
        }
      }
    }
  }
`
const DELETE_PRE_SURVEY = gql`
  mutation deletePreSurvey($id: String!) {
    deletePreSurvey(id: $id){
      status
    }
  }
`

const DELETE_POST_SURVEY = gql`
  mutation deletePostSurvey($id: String!) {
    deletePostSurvey(id: $id){
      status
    }
  }
`

const GET_ALL_FACTOR_TYPES = gql`
  query factorTypes {
    allFactoryTypes {
      id
      name
      code
      description
      color
    }
  }
`
