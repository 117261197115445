import { useEffect } from 'react'
import { Box, Input } from '@plusplusminus/plusplusdash'
import { gql, useQuery, useLazyQuery } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { InputValidator } from '../../../../services/validate'
import Button, { ButtonSize, ButtonVariant } from '../../../../components/Button'
import { Color } from '../../../../color.enum'
import RichTextInput from '../../../../components/RichTextInput'
import { Select } from './components/Select'
import Loader from '../../../../components/Loader/Loader'

const form = [
  {
    label: 'Factor Name',
    name: 'name',
    type: 'text',
    placeholder: 'Factor Name',
    options: {
      required: 'Factor Name is required',
      validate: (input: string) =>
        new InputValidator(input).validateAlphaNumericWithSpace() || ' Enter a valid factor name'
    }
  },
  {
    label: 'Factor Code',
    name: 'code',
    placeholder: 'Factor Code',
    options: {
      required: 'Factor Code is required',
      validate: (input: string) =>
        new InputValidator(input).validateAlphaNumericWithSpace() || 'Enter a valid factor code'
    },

    type: 'text'
  },
  {
    label: 'Headline',
    name: 'headline',
    placeholder: 'Headline',
    options: {
      required: 'Headline is required',
      validate: (input: string) =>
        new InputValidator(input).validateAlphaNumericWithSpace() || 'Enter a valid headline name'
    },
    type: 'text'
  },
  {
    label: 'Weight',
    name: 'weight',
    placeholder: 'Weight',
    options: {
      required: 'Weight is required'
    },
    type: 'number'
  },
  {
    label: 'Position',
    name: 'position',
    placeholder: 'Position',
    options: {
      required: 'Position is required'
    },
    type: 'number',
    show: 'create'
  },
  {
    label: 'Description',
    name: 'description',
    placeholder: 'Description',
    type: 'textarea'
  },
  {
    label: 'Factor Type',
    name: 'factorTypeId',
    placeholder: 'Factor Type',
    options: {
      required: 'Factor type is required',
      validate: (input: string) =>
        new InputValidator(input).validateAlphaNumericWithSpace() || 'Enter a valid factor type'
    },
    type: 'factorType'
  },
  {
    label: 'Discussion Questions',
    name: 'discussionQuestions',
    placeholder: 'Add discussion questions here....',
    type: 'richText'
  },
  {
    label: 'Content',
    name: 'content',
    placeholder: 'Add discussion questions here....',
    type: 'richText'
  },
  {
    label: 'Core factor (If checked the factor will always be in survey)',
    name: 'coreFactor',
    placeholder: 'Add discussion questions here....',
    type: 'checkBox'
  }
]

interface FormProps {
  onSubmit: (data: any, type: string) => void
  factorId?: string
  formType: string
  loading: boolean
}

export const CreateFactorForm: React.FC<FormProps> = (props) => {
  const { data: factorTypes, loading: ftLoading } = useQuery(GET_ALL_FACTOR_TYPE)
  const { register, handleSubmit, setValue, formState:{errors} } = useForm()

  const [loadFactorData, { loading, data }] = useLazyQuery(GET_A_SINGLE_FACTOR, {
    variables: { id: props.factorId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { factor } = data
      Object.keys(factor).forEach((f) => {
        setValue(f, factor[f])
      })
    }
  })

  useEffect(() => {
    if (props?.factorId) {
      loadFactorData()
    }
  }, [])

  const onRegister = async (data: any) => {
    if (props.onSubmit) {
      await props.onSubmit(data, props.formType)
    }
  }

  if (ftLoading || loading) return <Loader color={Color.PURPLE} />

  return (
    <div className="flex flex-col">
      <form
        action="#"
        autoComplete="no"
        onSubmit={handleSubmit(onRegister)}
        className="mt-6 grid grid-cols-2 gap-y-4 sm:grid-cols-2 sm:gap-x-8"
      >
        {form.map((field) => {
          if (field.type === 'text' ) {
            return (
              <Box className="col-span-2 sm:col-span-2" key={field.name}>
                <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
                  {field.label}
                </label>
                <Input as="input" variant="standard" min={1} width="full" type={field.type} {...register(field.name)} />
              </Box>
            )
          }

          if (field.type === 'number' && field?.show === props.formType) {
            return (
              <Box className="col-span-2 sm:col-span-2" key={field.name}>
                <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
                  {field.label}
                </label>
                <Input as="input" variant="standard" min={1} width="full" type={field.type} {...register(field.name ,{ ...field.options })} />
                {errors[field.name]?.message && (
                      <p className="text-sm text-red-500">{errors[field.name].message}</p>
                    )}
              </Box>
            )
          }

          if (field.type === 'checkBox') {
            return (
              <Box className="flex items-center col-span-2">
                <input
                  id={field.name}
                  type="checkbox"
                  className="h-4 w-4 text-brand-purple focus:ring-blue-900 border-gray-300 rounded"
                  {...register(field.name , { ...field.options })}
                />
                <label htmlFor={field.name} className="ml-2 block text-sm text-gray-900">
                  {field.label}
                </label>
                {errors[field.name]?.message && (
                      <p className="text-sm text-red-500">{errors[field.name].message}</p>
                    )}
              </Box>
            )
          }

          if (field.type === 'textarea') {
            return (
              <Box className="col-span-2 sm:col-span-2 mt-4">
                <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
                  {field.label}
                </label>
                <Input as="textarea" variant="standard" width="full" {...register(field.name , { ...field.options })} />
                {errors[field.name]?.message && (
                  <p className="text-sm text-red-500">{errors[field.name].message}</p>
                )}
              </Box>
            )
          }

          if (field.type === 'factorType') {
            return (
              <Box className="col-span-2 sm:col-span-2" key={field.name}>
                <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
                  {field.label}
                </label>
                <Select
                  options={factorTypes?.allFactoryTypes}
                  name={field.name}
                  validations={field.options}
                  register={register}
                  value={data?.factor?.factorType.id}
                />
                {errors[field.name]?.message && (
                  <p className="text-sm text-red-500">{errors[field.name].message}</p>
                )}
              </Box>
            )
          }

          if (field.type === 'richText') {
            return (
              <Box className="col-span-2 sm:col-span-2" key={field.name}>
                <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
                  {field.label}
                </label>
                <RichTextInput
                  placeholder={field.placeholder}
                  name={field.name}
                  setValue={setValue}
                  defaultValue={data?.factor[field.name]}
                />
              </Box>
            )
          }
        })}
        <div className="col-span-2">
          <Button
            className="justify-center mb-2"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.LARGE}
            color={Color.BLUE}
            style={{ width: '100%' }}
            type="submit"
          >
            {props.loading ? 'Loading' : 'Submit'}
          </Button>
        </div>
      </form>
    </div>
  )
}

const GET_ALL_FACTOR_TYPE = gql`
  query allFactoryTypes {
    allFactoryTypes {
      id
      createdAt
      name
      code
      description
    }
  }
`

const GET_A_SINGLE_FACTOR = gql`
  query SingleFactor($id: String!) {
    factor(id: $id) {
      id
      name
      headline
      code
      description
      discussionQuestions
      content
      weight
      position
      image
      coreFactor
      factorType {
        id
      }
      statements {
        id
        statement
      }
      preSurveyOption {
        id
      }
    }
  }
`
