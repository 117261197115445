import { Box, Input } from '@plusplusminus/plusplusdash'
import { useForm } from 'react-hook-form'
import { InputValidator } from '../../../services/validate'
import Button, { ButtonSize, ButtonVariant } from '../../../components/Button'
import { Color } from '../../../color.enum'
import { Link } from '@reach/router'
import TermsAndConditions from '../../../components/TermsAndConditions'

const form = [
  {
    label: 'First Name',
    name: 'firstName',
    type: 'text',
    placeholder: 'First Name',
    options: {
      required: 'Last name is required',
      validate: (input: string) =>
        new InputValidator(input).validateAlphaNumericWithSpace() || 'Enter a valid first name'
    }
  },
  {
    label: 'Last Name',
    name: 'lastName',
    placeholder: 'Last Name',
    options: {
      required: 'Last name is required',
      validate: (input: string) =>
        new InputValidator(input).validateAlphaNumericWithSpace() || 'Enter a valid last name'
    },

    type: 'text'
  },
  {
    label: 'Email Address',
    name: 'email',
    placeholder: 'Email',
    options: {
      required: 'Email is required',
      validate: (input: string) => new InputValidator(input).validateEmail() || 'Enter a valid email'
    },
    type: 'email'
  },
  {
    label: 'Organisation Name',
    name: 'organisation',
    description: 'Add the full (unabbreviated) name of the organisation that will be evaluated during the Assessment',
    placeholder: 'Organisation',
    options: {
      required: 'Organisation is required',
      validate: (input: string) =>
        new InputValidator(input).validateAlphaNumericWithSpace() || 'Enter a valid organisation name'
    },
    type: 'text'
  }
]

interface Props {
  onSubmit?: (data: any) => void
  loading: boolean
}

export const RegistrationForm: React.FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange' })

  const onRegister = async (data: any) => {
    if (props.onSubmit) {
      await props.onSubmit(data)
    }
  }

  return (
    <form
      action="#"
      autoComplete="no"
      onSubmit={handleSubmit(onRegister)}
      className="mt-6 grid grid-cols-2 gap-y-4 sm:grid-cols-2 sm:gap-x-8"
    >
      {form.map((field) => {
        return (
          <Box className="col-span-2 sm:col-span-2" key={field.name}>
            <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
              {field.label}
            </label>
            {field.description && <p className="text-sm text-gray-500 prose mb-2">{field.description}</p>}
            <Input as="input" variant="standard" width="full" {...register(field.name, { ...field.options })}></Input>
            {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
          </Box>
        )
      })}
      <div className="col-span-2">
        <Button
          className="justify-center mb-2"
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.LARGE}
          color={Color.BLUE}
          style={{ width: '100%' }}
          type="submit"
        >
          {props.loading ? 'Loading...' : 'Submit'}
        </Button>

        <p className="text-sm text-gray-500 prose">
          Already have an account? Please sign in{' '}
          <Link to="/login" className="text-brand-blue underline font-medium ">
            here.
          </Link>
        </p>
        <TermsAndConditions text="By signing up you agree with the" />
      </div>
    </form>
  )
}
