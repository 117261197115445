import { Box, Input } from '@plusplusminus/plusplusdash'
import { useForm } from 'react-hook-form'
import { gql, useLazyQuery } from '@apollo/client'
import { InputValidator } from '../../../../services/validate'
import Button, { ButtonSize, ButtonVariant } from '../../../../components/Button'
import RichTextInput from '../../../../components/RichTextInput'
import { Color } from '../../../../color.enum'
import { Select, OptionInterface } from '../demographicSurveys/components/Select'

import Alert, { AlertSize, AlertVariant } from '../../../../components/Alert'
import { useEffect } from 'react'
import Loader from '../../../../components/Loader/Loader'
import { useFormatApolloError } from '../../../../hooks/useFormatApolloError'

interface FieldTypes {
  label: string
  name: string
  type: string
  placeholder: string
  options?: {
    required?: string
    validate?: any
  }
  items?: Array<OptionInterface>
}

const form: FieldTypes[] = [
  {
    label: 'Factor Type Name',
    name: 'name',
    type: 'text',
    placeholder: 'Factor Type Name',
    options: {
      required: 'Factor Type Name is required',
      validate: (input: string) =>
        new InputValidator(input).validateAlphaNumericWithSpace() || 'Enter a valid factor name'
    }
  },
  {
    label: 'Factor Type Code',
    name: 'code',
    placeholder: 'Factor Type Code',
    options: {
      required: 'Factor Type Code is required'
    },
    type: 'text'
  },
  {
    label: 'Index Order',
    name: 'indexOrder',
    placeholder: 'Index Order',
    options: {
      required: 'Index order is required'
    },
    type: 'number'
  },
  {
    label: 'Factor Type Color',
    name: 'color',
    placeholder: 'Color',
    options: {
      required: 'color is required'
    },
    items: [
      { id: 'gray', name: 'Gray' },
      { id: 'purple', name: 'Purple' },
      { id: 'blue', name: 'Blue' }
    ],
    type: 'select'
  },
  {
    label: 'Description',
    name: 'description',
    placeholder: 'Enter factor description here....',
    type: 'textarea'
  }
]

interface Props {
  onSubmit?: (data: any, type: string) => void
  loading?: boolean
  factorTypeId?: string
  formType: string
  createError?: any
}

export const CreateFactorTypeForm: React.FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm()

  const [formatError] = useFormatApolloError()

  const [loadFactorTypeData, { loading, data }] = useLazyQuery(GET_SINGLE_FACTOR_TYPE, {
    variables: { id: props.factorTypeId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { factorType } = data
      Object.keys(factorType).forEach((ft) => {
        setValue(ft, factorType[ft])
      })
    }
  })

  useEffect(() => {
    if (props?.factorTypeId) {
      loadFactorTypeData()
    }
  }, [])

  const onRegister = async (data: any) => {
    if (props.onSubmit) {
      await props.onSubmit(data, props.formType)
    }
  }

  if (loading) return <Loader color={Color.PURPLE} />

  return (
    <form
      action="#"
      autoComplete="no"
      onSubmit={handleSubmit(onRegister)}
      className="mt-6 grid grid-cols-2 gap-y-4 sm:grid-cols-2 sm:gap-x-8"
    >
      {form.map<any>((field) => {
        if (field.type === 'select') {
          return (
            <Box className="col-span-2 sm:col-span-2" key={field.name}>
              <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
                {field.label}
              </label>
              <Select name={field.name} options={field.items} register={register} validations={field.options}/>
              {errors[field.name]?.message && (
                      <p className="text-sm text-red-500">{errors[field.name].message}</p>
                    )}
            </Box>
          )
        }

        if (field.type === 'richText') {
          return (
            <Box className="col-span-2 sm:col-span-2" key={field.name}>
              <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
                {field.label}
              </label>
              <RichTextInput
                placeholder={field.placeholder}
                name={field.name}
                setValue={setValue}
                defaultValue={data?.factorType[field.name]}
              />
            </Box>
          )
        }

        if (field.type === 'textarea') {
          return (
            <Box className="col-span-2 sm:col-span-2 mt-4">
              <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
                {field.label}
              </label>
              <Input as="textarea" variant="standard" width="full" {...register(field.name, {...field.options})} />
              {errors[field.name]?.message && (
                      <p className="text-sm text-red-500">{errors[field.name].message}</p>
                    )}
            </Box>
          )
        }
        return (
          <Box className="col-span-2 sm:col-span-2" key={field.name}>
            <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
              {field.label}
            </label>
            <Input
              as="input"
              variant="standard"
              min={1}
              width="full"
              type={field.type}
              {...register(field.name, { ...field.options })}
            ></Input>
            {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
          </Box>
        )
      })}

      <div className="col-span-2">
        {props.createError && (
          <Alert className="my-3" variant={AlertVariant.ERROR} size={AlertSize.SMALL}>
            {formatError(props.createError)}
          </Alert>
        )}

        <Button
          className="justify-center mb-2"
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.LARGE}
          color={Color.BLUE}
          style={{ width: '100%' }}
          type="submit"
        >
          {props.loading ? 'Loading...' : 'Submit'}
        </Button>
      </div>
    </form>
  )
}

const GET_SINGLE_FACTOR_TYPE = gql`
  query FactorType($id: String!) {
    factorType(id: $id) {
      id
      name
      code
      description
      color
      indexOrder
    }
  }
`
