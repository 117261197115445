import { Color } from '../../color.enum'
import ListItem, { ListItemCta, ListItemMeta } from '../ListItem'

interface ItemData {
  headline: string
  description: string
  color?: Color
  renderMeta?: JSX.Element
  renderCta?: (color: Color) => JSX.Element
}

export const items: ItemData[] = [
  {
    headline: 'Survey Name One',
    description: 'Organisation Name One',
    renderMeta: <ListItemMeta />,
    renderCta: (color: Color): JSX.Element => {
      return <ListItemCta color={color} />
    }
  },
  {
    headline: 'Survey Name Two',
    description: 'Organisation Name Two',
    renderMeta: <ListItemMeta />,
    renderCta: (color: Color): JSX.Element => {
      return <ListItemCta color={color} />
    }
  },
  {
    headline: 'Another Survey',
    description: 'Organisation Name Three',
    renderMeta: <ListItemMeta />,
    renderCta: (color: Color): JSX.Element => {
      return <ListItemCta color={color} />
    }
  }
]

export const api = {
  color: Color.BLUE,
  children: (color: Color): JSX.Element => {
    return (
      <>
        {items.map((item) => (
          <ListItem color={color} {...item} />
        ))}
      </>
    )
  }
}
