import React from 'react'
import { Color } from '../../color.enum'
import { classNames } from '../../utils/class-names'
import Icon from '../Icon/Icon'
import { IconType } from '../Icon/Icon.options'

export interface FeatureRowProps {
  /** Icon to show with Feature */
  icon?: IconType
  /** Headline of the component */
  headline?: string
  /** Descriptive text to tshow below the headline */
  description?: string | React.ReactNode
  /** Add a children to the component */
  children?: React.ReactElement
  /** Aditional Styles for the container */
  className?: string
  /** What color is the componet? */
  color: Color
}

interface FeatureRowColorProps {
  text: string
  bg: string
}

export const FEATURE_COLOR_MAPS: Record<Color, FeatureRowColorProps> = {
  [Color.GRAY]: {
    text: 'text-gray-500',
    bg: 'bg-gray-100'
  },

  [Color.BLUE]: {
    text: 'text-brand-blue',
    bg: 'bg-brand-blue-light'
  },
  [Color.PURPLE]: {
    text: 'text-brand-purple',
    bg: 'bg-brand-purple-light'
  }
}

/**
 *  Component to show a Feature with optional icon and color
 */

const FeatureRow: React.FC<FeatureRowProps> = ({
  className,
  color,
  icon,
  headline,
  description,
  children
}: FeatureRowProps) => {
  return (
    <div className={classNames('FeatureRow relative pb-4 md:pb-12', className)}>
      <dt>
        {icon && (
          <span
            className={`text-center inline-flex items-center justify-center p-3 rounded-full shadow-lg text-xl mr-4 ${FEATURE_COLOR_MAPS[color].bg}`}
          >
            <Icon name={icon} className={`h-[20px] w-[20px] ${FEATURE_COLOR_MAPS[color].text}`} aria-hidden="true" />
          </span>
        )}
        {headline && <h3 className="mt-8 text-lg font-medium text-gray-900 inline">{headline}</h3>}
      </dt>
      {description && <dd className="mt-2 text-base text-gray-500 ml-16">{description}</dd>}
      {children && children}
    </div>
  )
}

FeatureRow.defaultProps = {
  color: Color.BLUE
}

export default FeatureRow
