import { RouteComponentProps, useNavigate } from '@reach/router'
import { Color } from '../../color.enum'
import HeadingPage from '../../components/HeadingPage'
import HeadingSection from '../../components/HeadingSection'
import { User } from '../../common/types'
import Button, { ButtonVariant, ButtonSize } from '../../components/Button'
import {Helmet} from 'react-helmet';
import { language } from '../../common/i18n'

interface AdminProps extends RouteComponentProps {
  user?: User
}

export const AdminDashboard: React.FC<AdminProps> = () => {
  const navigate = useNavigate()
  return (
    <div className="page-container-lg">
      <Helmet>
        <title> {language.en.admin.dashBoard.title} </title>
        <meta name="description" content={language.en.admin.dashBoard.description}/>
      </Helmet>
      <header>
        <HeadingPage headline={`Dashboard`} color={Color.BLUE} />
      </header>
      <main>
        <HeadingSection
          headline="View a list of all Users"
          color={Color.PURPLE}
          children={() => {
            return (
              <div className="flex items-center">
                <Button
                  variant={ButtonVariant.PRIMARY}
                  color={Color.PURPLE}
                  size={ButtonSize.SMALL}
                  onClick={() => navigate('/dashboard/admin/users')}
                  iconRight="arrowRight"
                >
                  View
                </Button>
              </div>
            )
          }}
        />
        <HeadingSection
          headline="View a list of all Assessments"
          color={Color.PURPLE}
          children={() => {
            return (
              <div className="flex items-center">
                <Button
                  variant={ButtonVariant.PRIMARY}
                  color={Color.PURPLE}
                  size={ButtonSize.SMALL}
                  onClick={() => navigate('/dashboard/admin/assessments')}
                  iconRight="arrowRight"
                >
                  View
                </Button>
              </div>
            )
          }}
        />
        <HeadingSection
          headline="View a list of all Organisations"
          color={Color.PURPLE}
          children={() => {
            return (
              <div className="flex items-center">
                <Button
                  variant={ButtonVariant.PRIMARY}
                  color={Color.PURPLE}
                  size={ButtonSize.SMALL}
                  onClick={() => navigate('/dashboard/admin/organizations')}
                  iconRight="arrowRight"
                >
                  View
                </Button>
              </div>
            )
          }}
        />
        <HeadingSection
          headline="View a list of all Surveys"
          color={Color.PURPLE}
          children={() => {
            return (
              <div className="flex items-center">
                <Button
                  variant={ButtonVariant.PRIMARY}
                  color={Color.PURPLE}
                  size={ButtonSize.SMALL}
                  onClick={() => navigate('/dashboard/admin/surveys')}
                  iconRight="arrowRight"
                >
                  View
                </Button>
              </div>
            )
          }}
        />
      </main>
    </div>
  )
}
