import React from 'react'
import { Input as BaseInput } from '@plusplusminus/plusplusdash'
import { classNames } from '../../utils/class-names'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  /** Aditional Styles for the container */
  className?: string
  /** Children are displayed as the text of the label */
  children?: React.ReactElement | string
  /** Type of input */
  as: 'input' | 'textarea'
  /** Width of input */
  width?: keyof Width
}

interface Width {
  sm: string
  md: string
  lg: string
  full: string
}

/**
 * Form Labels
 */

export const Input: React.FC<InputProps> = ({ className, as, ...props }: InputProps) => {
  return (
    <BaseInput
      variant={'standard'}
      as={as}
      className={classNames(
        'py-3 px-3 text-base border-gray-700 border rounded-sm focus:border-opacity-20 placeholder-brand-blue placeholder-opacity-75 transition-all text-gray-500 focus:bg-gray-50',
        className
      )}
      {...props}
    />
  )
}

Input.defaultProps = {
  as: 'input'
}

export default Input
