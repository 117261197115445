import React from 'react'
import { Color } from '../../color.enum'
import { classNames } from '../../utils/class-names'

export interface HeadingSectionProps {
  /** Headline of the component */
  headline?: any
  /** Descriptive text to tshow below the headline */
  description?: any
  /** Add a children to the component */
  children?: (color: Color) => JSX.Element
  /** Aditional Styles for the container */
  className?: string
  /** What color is the componet? */
  color: Color
}

interface HeadingSectionColorProps {
  text: string
}

export const HEADING_PAGE_COLOR_MAPS: Record<Color, HeadingSectionColorProps> = {
  [Color.BLUE]: {
    text: 'text-brand-blue'
  },
  [Color.PURPLE]: {
    text: 'text-brand-purple'
  },
  [Color.GRAY]: {
    text: 'text-gray-500'
  }
}

/**
 *  Component to show a Heading for a Page with optional actions
 */

const HeadingSection: React.FC<HeadingSectionProps> = ({
  className,
  color,
  headline,
  description,
  children
}: HeadingSectionProps) => {
  return (
    <div
      className={classNames(
        'HeadingSection pb-2 border-b border-gray-200 sm:flex sm:items-start sm:justify-between mb-2',
        className
      )}
    >
      <div className="flex-1 min-w-0 max-w-3xl">
        {headline && (
          <h3 className={`font-medium leading-normal text-1xl md:text-2xl ${HEADING_PAGE_COLOR_MAPS[color].text}`}>
            {headline}
          </h3>
        )}
        {description && <p className="prose mt-2 text-medium">{description}</p>}
      </div>
      {children && <div className="mt-4 flex md:mt-0 md:ml-4">{children(color)}</div>}
    </div>
  )
}

HeadingSection.defaultProps = {
  color: Color.BLUE
}

export default HeadingSection
