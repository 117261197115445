import { RouteComponentProps, useNavigate, useParams, useLocation } from '@reach/router'
import { Color } from '../../../../color.enum'
import { gql, useMutation } from '@apollo/client'
import { CreateFactorForm } from './createFactorForm'
import HeadingPageCenter from '../../../../components/HeadingPageCenter'
import {Helmet} from 'react-helmet';
import { language } from '../../../../common/i18n'

interface CreateFactorInterface extends RouteComponentProps<any> {
  location: any
}

const CreateFactor: React.FC<CreateFactorInterface> = () => {
  const navigate = useNavigate()
  const { surveyId, factorId } = useParams()
  const { pathname } = useLocation()

  const [createFactor, { loading: createFactorLoading }] = useMutation(CREATE_FACTOR, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      navigate(`/dashboard/admin/survey/${surveyId}/factor/${data?.createFactor?.id}`)
    }
  })

  const [updateFactor, { loading: updateLoading }] = useMutation(UPDATE_FACTOR, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      console.log(data)
      navigate(`/dashboard/admin/survey/${surveyId}/factor/${factorId}`)
    }
  })

  const onSubmit = (data: any, type: string) => {
    if (type === 'create') {
      const {position, ...rest} = data
      createFactor({ variables: { input: { ...rest, surveyId: surveyId, factorId: factorId, position: position } } })
    } else {
      const {
        position,
        weight,
        factorType: { id: factorTypeId },
        ...rest
      } = data

      updateFactor({
        variables: {
          factorId,
          input: {
            position: Number(position),
            weight: Number(weight),
            name: rest.name,
            code: rest.code,
            description: rest.description,
            headline: rest.headline,
            content: rest.content,
            discussionQuestions: rest.discussionQuestions,           
            factorTypeId,
            image: rest.image,
            coreFactor: rest.coreFactor
          }
        }
      })
    }
  }

  const type = pathname.endsWith('edit-factor') ? 'edit' : 'create'

  return (
    <main>
      <div className="page-container-lg">
        <Helmet>
          <title>{language.en.admin.factor.add.title}</title>
          <meta name="description" content={language.en.admin.factor.add.description} />
        </Helmet>
        <HeadingPageCenter headline={type === 'edit' ? 'Edit factor' : 'Create new factor'} color={Color.PURPLE} />

        <div className="section-container-sm">
          <CreateFactorForm
            onSubmit={onSubmit}
            factorId={factorId}
            formType={type}
            loading={createFactorLoading || updateLoading}
          />
        </div>
      </div>
    </main>
  )
}

export default CreateFactor

const CREATE_FACTOR = gql`
  mutation createFactor($input: CreateFactorInput!) {
    createFactor(input: $input) {
      name
      id
    }
  }
`
const UPDATE_FACTOR = gql`
  mutation updateFactor($factorId: String!, $input: UpdateFactorInput!) {
    updateFactor(input: $input, factorId: $factorId) {
      name
      id
    }
  }
`
