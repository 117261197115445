import { RouteComponentProps, useNavigate, useParams, useLocation } from '@reach/router'
import { Color } from '../../../../color.enum'
import { gql, useMutation } from '@apollo/client'
import { CreateQuestionOptionForm } from './createPreSurveyQuestionForm'
import HeadingPageCenter from '../../../../components/HeadingPageCenter'
import {Helmet} from 'react-helmet';
import { language } from '../../../../common/i18n'
interface CreatePreSurveyQuestionOptionInterface extends RouteComponentProps<any> {
  location: any
}

const CreatePreSurveyQuestionOption: React.FC<CreatePreSurveyQuestionOptionInterface> = () => {
  const navigate = useNavigate()
  const { questionId, optionId, surveyId, preSurveyId } = useParams()

  const { pathname } = useLocation()

  const [createPreSurveyQuestionOption, { loading: createPreSurveyQuestionLoading }] = useMutation(
    CREATE_PRE_SURVEY_QUESTION_OPTION,
    {
      onCompleted: (data) => {
        navigate(
          `/dashboard/admin/survey/${surveyId}/preSurvey/${preSurveyId}/question/${questionId}/option/${data?.createPreSurveyQuestionOption?.id}`
        )
      }
    }
  )

  const [updatePreSurveyQuestionOption, { loading: updateLoading }] = useMutation(UPDATE_PRE_SURVEY_QUESTION_OPTION, {
    onCompleted: () => {
      navigate(`/dashboard/admin/survey/${surveyId}/preSurvey/${preSurveyId}/question/${questionId}/option/${optionId}`)
    }
  })

  const onSubmit = (data: any, type: string) => {
    if (type === 'create') {
      createPreSurveyQuestionOption({
        variables: {
          input: { ...data, preSurveyQuestionId:questionId }
        }
      })
    } else {
      updatePreSurveyQuestionOption({
        variables: {
          id: optionId,
          input: {
            index: data.index,
            text: data.text,
            code: data.code,
            factorId: data.factorId,
            preSurveyQuestionId: questionId
          }
        }
      })
    }
  }

  const type = pathname.endsWith('edit') ? 'edit' : 'create'

  return (
    <div className="page-container-lg">
      <Helmet>
        <title>{language.en.admin.option.title}</title>
        <meta name="description" content={language.en.admin.option.description}/>
      </Helmet>
      <main>
        <HeadingPageCenter
          className="text-center"
          headline={
            pathname.endsWith('edit')
              ? 'Edit Organisation Demographic Question Option'
              : 'Create Organisation Demographic Question Option'
          }
          color={Color.PURPLE}
        />
        <div className="section-container-sm">
          <CreateQuestionOptionForm
            loading={createPreSurveyQuestionLoading || updateLoading}
            optionId={optionId}
            onSubmit={onSubmit}
            formType={type}
          />
        </div>
      </main>
    </div>
  )
}

export default CreatePreSurveyQuestionOption

const CREATE_PRE_SURVEY_QUESTION_OPTION = gql`
  mutation createPreSurveyQuestionOption($input: CreatePreSurveyQuestionOptionInput!) {
    createPreSurveyQuestionOption(input: $input) {
      text
      id
    }
  }
`
const UPDATE_PRE_SURVEY_QUESTION_OPTION = gql`
  mutation updatePreSurveyQuestionOption($id: String!, $input: UpdatePreSurveyQuestionOptionInput!) {
    updatePreSurveyQuestionOption(input: $input, id: $id) {
      text
      id
    }
  }
`
