// eslint-disable-next-line @typescript-eslint/no-var-requires
const { fontWeight } = require('tailwindcss/defaultTheme')
const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
  purge: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
  darkMode: false, // or 'media' or 'class'
  mode: 'jit',
  theme: {
    extend: {
      fontFamily: {
        sans: ['Made', ...defaultTheme.fontFamily.sans]
      },
      letterSpacing: {
        widest: '.18em'
      },
      colors: {
        transparent: 'transparent',
        white: '#fff',
        'brand-blue': '#00506E',
        'brand-blue-light': '#E6EEF1',
        'brand-purple': '#0A043D',
        'brand-purple-light': '#D8D5F1',
        'orange-50': '#fff3e0',
        'orange-500': '#ff9800'
      },
      keyframes: {
        slice: {
          '0%': {
            transform: `scale(0)`
          },
          '100%': {
            transform: `scale(1)`
          }
        },
        barFill: {
          from: { width: '0%' },
          to: { width: '100%' }
        },
        spinPulse: {
          '0%': {
            transform: 'rotate(0deg) scale(2)',
            opacity: '1'
          },
          '50%': {
            transform: 'rotate(180deg) scale(1.7)',
            opacity: '0.4'
          },
          '100%': {
            transform: 'rotate(360deg) scale(2)',
            opacity: '1'
          }
        }
      },
      animation: {
        barFill: 'barFill 1s ease-out 1',
        spinPulse: 'spinPulse 1s linear infinite'
      },
      typography: (theme) => ({
        DEFAULT: {
          css: {
            a: {
              fontWeight: fontWeight.medium,
              color: theme('colors.brand-blue')
            },
            strong: {
              fontWeight: theme('fontWeight.medium')
            },
            h1: {
              fontWeight: theme('fontWeight.medium')
            },
            'h1 strong': {
              fontWeight: theme('fontWeight.bold')
            },
            h2: {
              fontWeight: theme('fontWeight.medium')
            },
            'h2 strong': {
              fontWeight: theme('fontWeight.bold')
            },
            h3: {
              fontWeight: theme('fontWeight.medium')
            },
            'h3 strong': {
              fontWeight: theme('fontWeight.bold')
            },
            h4: {
              fontWeight: theme('fontWeight.medium')
            },
            'h4 strong': {
              fontWeight: theme('fontWeight.bold')
            },
            thead: {
              fontWeight: theme('fontWeight.medium'),
              color: theme('colors.brand-blue', defaultTheme.colors.gray[900])
            },
            th: {
              fontWeight: theme('fontWeight.medium')
            },
            blockquote: {
              color: theme('colors.brand-blue', defaultTheme.colors.gray[900]),
              borderLeftColor: theme('colors.brand-blue-light', defaultTheme.colors.gray[200]),
              quotes: '"\\201C""\\201D""\\2018""\\2019"'
            }
          }
        }
      })
    },
    rotate: {
      '-180': '-180deg',
      '-90': '-90deg',
      '-45': '-45deg',
      0: '0',
      45: '45deg',
      90: '90deg',
      135: '135deg',
      180: '180deg',
      270: '270deg'
    }
  },
  variants: {
    extend: {}
  },
  plugins: [require('@tailwindcss/typography'), require('@tailwindcss/aspect-ratio')],
  important: true
}
