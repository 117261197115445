import { Color } from '../../../../color.enum'
import Button, { ButtonVariant, ButtonSize } from '../../../../components/Button'
import { navigate } from '@reach/router'
import { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import RemoveItemModal from '../components/RemoveItemModal'

interface ItemCtaData {
  color: Color
  id: string
  type: string
  deleteSurvey: (obj: { variables: { id: string } }) => void
  surveyId: string
  surveyStatus?: string
  refetch?: any
}
interface ItemMetaData {
  questionCount: string
}

export const ListItemCta: React.FC<ItemCtaData> = ({
  color,
  id,
  type,
  surveyId,
  surveyStatus,
  refetch
}: ItemCtaData) => {
  const url = `/dashboard/admin/survey/${surveyId}/demographic-survey/${id}/${type}`

  const [isOpen, setIsOpen] = useState(false)

  const [deletePreSurvey, { loading }] = useMutation( DELETE_PRESURVEY, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      refetch()
      setIsOpen(false)
    }
  })

  const [deletePostSurvey, { loading: pLoading }] = useMutation(
    DELETE_POSTSURVEY, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      refetch()
      setIsOpen(false)
    }
  })



  const handleCloseRemoveDemoSurvey = () => {
    setIsOpen(false)
  }

  const handleRemoveDemoSurvey = (id: string) => {
    if(type === 'org'){
      deletePreSurvey({
        variables:{
          id: id
        }
      })
    } else{
      deletePostSurvey({
        variables:{
          id: id
        }
      })
    }
  
  }



  return (
    <div className="flex space-x-2">
      <RemoveItemModal
        isOpen={isOpen}
        handleClose={handleCloseRemoveDemoSurvey}
        handleConfirm={() => handleRemoveDemoSurvey(id)}
        loading={loading || pLoading}
      />
      {surveyStatus !== 'PUBLISHED' && (
        <Button variant={ButtonVariant.PLAIN} color={color} size={ButtonSize.SMALL} onClick={() => setIsOpen(true)}>
          Remove Questionaire
        </Button>
      )}

      <Button
        variant={ButtonVariant.PRIMARY}
        color={color}
        size={ButtonSize.SMALL}
        onClick={() => navigate(url)}
        iconRight="arrowRight"
      >
        View details
      </Button>
    </div>
  )
}

export const ListItemMeta: React.FC<ItemMetaData> = ({ questionCount }: ItemMetaData) => {
  return <p className="text-sm text-gray-900">{questionCount}</p>
}

const  DELETE_PRESURVEY = gql`
  mutation _deletePreSurvey($id: String!) {
    deletePreSurvey(id: $id) {
      status
      message
    }
  }
`
const  DELETE_POSTSURVEY = gql`
  mutation deletePost($id: String!) {
    deletePostSurvey(id: $id) {
      status
      message
    }
  }
`
