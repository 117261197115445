import { useMutation } from '@apollo/client'
import {Helmet} from 'react-helmet';
import gql from 'graphql-tag'
import { useState } from 'react'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { RegistrationForm } from './ui/RegistrationForm'
import Notice, { NoticeVariant } from '../../components/Notice'
import queryString from 'query-string'
import HeadingPageCenter from '../../components/HeadingPageCenter'
import { Color } from '../../color.enum'
import parse from 'html-react-parser'
import {useFormatApolloError} from '../../hooks/useFormatApolloError'
import { language } from '../../common/i18n';


interface RegisterFormData {
  firstName: string
  lastName: string
  organisation: string
  email: string
}

export const Registration: React.FC<RouteComponentProps> = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [formatError]= useFormatApolloError()

  //TODO:redirect to otp screen and not login. Requires fingerprint token
  // const [user, setUser] = useState({ id: '', token: '' })
  const [error, setError] = useState({ message: '', action: '' })

  const [register] = useMutation(REGISTER_USER)

  const onRegister = (submission: RegisterFormData) => {
    setLoading(true)
    setError({
      message: '',
      action: ''
    })

    const input: any = {
      firstName: submission.firstName.trim(),
      lastName: submission.lastName.trim(),
      organizationName: submission.organisation.trim()
    }

    if (!!submission.email) {
      input.email = submission.email.toLowerCase().trim()
    }

    register({
      variables: {
        input
      }
    })
      .then(({ data }) => {
        setLoading(false)
        if (data) {
          console.log(data)
          const loginUrl = queryString.stringifyUrl({
            url: '/login',
            query: { step: 'otp', token: data.register.token }
          })
          navigate(loginUrl)
        }
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        setError({ message: formatError(err), action: 'no action' })
      })
  }

  return (
    <div className="page-container-lg">
      <Helmet>
        <title>{language.en.auth.title.register}</title>
        <meta name="description" content={language.en.auth.description.register}/>
      </Helmet>
      <main>
        <HeadingPageCenter
          className="text-center"
          headline="Create a new account"
          color={Color.PURPLE}
          description={parse(
            'Welcome to the <strong>Mission Capacity Inventory (MCI)</strong>.<br/>Please enter your details below to sign up.'
          )}
        />
        <div className="section-container-sm">
          <RegistrationForm onSubmit={onRegister} loading={loading} />
          {error.message ? (
            <Notice
              className="mt-2 mb-1"
              variant={NoticeVariant.SMALL}
              icon="warning"
              color={Color.GRAY}
              children={<div>{error.message}</div>}
            />
          ) : null}
        </div>
      </main>
    </div>
  )
}

export const REGISTER_USER = gql`
  mutation registerUser($input: RegisterOrgInput!) {
    register(input: $input) {
      message
      token
    }
  }
`
