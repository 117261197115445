import { useQuery } from '@apollo/client'
import { RouteComponentProps, useNavigate } from '@reach/router'
import dayjs from 'dayjs'
import { useContext, useEffect } from 'react'
import { Color } from '../../color.enum'
import Button, { ButtonSize, ButtonVariant } from '../../components/Button'
import Card, { CardVariant } from '../../components/Card'
import Loader from '../../components/Loader/Loader'
import Notice, { NoticeVariant } from '../../components/Notice'
import client from '../../contentful'
import { Context } from '../../context/context'
import { WELCOME_CONTENT_QUERY } from '../../graphql/contentful-queries'
import RichTextRenderer from '../../modules/RichTextRenderer'

interface WelcomeProps extends RouteComponentProps {
  dueDate: Date
  firstName: string
  orgName?: string
  respondentId: string
  postSurveyStatus: string
  assignmentId: string
  surveyId: string
  surveyStatus: string
  setStep: (step: string) => void
}

export const Welcome: React.FC<WelcomeProps> = ({
  dueDate,
  firstName,
  orgName,
  respondentId,
  postSurveyStatus,
  assignmentId,
  surveyStatus,
  setStep
}) => {
  const { contextValue, setContextValue } = useContext(Context)
  const navigate = useNavigate()

  const { data, loading } = useQuery(WELCOME_CONTENT_QUERY, { client })

  useEffect(() => {
    if (dueDate && surveyStatus !== 'EXPIRED') {
      setContextValue({ ...contextValue, dueDate })
    }
  }, [])

  if (loading) return <Loader color={Color.PURPLE} />

  const { headline, content, image } = data?.contentCollection?.items?.[0] ?? {}

  return (
    <main>
      <div className="page-container-lg">
        <Card
          variant={CardVariant.PRIMARY}
          headline={`${headline ?? 'Welcome'}, ${firstName}`}
          subtitle={`${orgName}`}
          media={image && <img src={image.url} alt="" />}
        >
          <div className="prose">{content && <RichTextRenderer content={content} />}</div>
          <div className="mt-10 mb-6">
            <Notice icon="alarm" variant={NoticeVariant.LARGE}>
              {surveyStatus === 'EXPIRED' ? (
                <p className="prose text-sm">Survey has expired.</p>
              ) : (
                <p className="prose text-sm">
                  You have until <strong>{dayjs(dueDate).format('D MMMM, YYYY')}</strong> to complete this survey.
                </p>
              )}
            </Notice>
          </div>

          {surveyStatus !== 'EXPIRED' && (
            <Button
              size={ButtonSize.LARGE}
              variant={ButtonVariant.PRIMARY}
              color={Color.BLUE}
              iconRight="arrowRight"
              onClick={() => {
                const goToPostSurvey = postSurveyStatus === 'PENDING'
                const goToSurvey = surveyStatus === 'PENDING' || surveyStatus === 'STARTED'
                const goToResults = postSurveyStatus === 'COMPLETED' && surveyStatus === 'COMPLETED'

                if (goToPostSurvey) {
                  setStep('demographic-survey')
                } else if (goToSurvey) {
                  setStep('survey')
                } else if (goToResults) {
                  navigate(`/survey/${respondentId}/${assignmentId}/result`)
                }
              }}
            >
              {postSurveyStatus === 'PENDING' && surveyStatus === 'PENDING' && 'Start Survey'}
              {postSurveyStatus === 'COMPLETED' && surveyStatus === 'PENDING' && 'Start Survey'}
              {surveyStatus === 'STARTED' && 'Continue Survey'}
              {surveyStatus === 'COMPLETED' && 'Results'}
            </Button>
          )}
        </Card>
      </div>
    </main>
  )
}
