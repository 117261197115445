import React from 'react'
import { Color } from '../../color.enum'
import { classNames } from '../../utils/class-names'

export interface TestimonialProps {
  /** Content of the Testimonial */
  headline?: React.ReactElement | React.ReactNode
  /** Descriptive text to support the cite */
  description?: string
  /** Who should this be attributed to? */
  cite?: string
  /** Aditional Styles for the container */
  className?: string
  /** Image to be displayed as backgroud element */
  image?: React.ReactElement
  /** What color is the componet? */
  color: Color
  /** Id to identify specific component as provided by the CMS */
  id?: string
}

interface TestimonialColorProps {
  text: string
  bg: string
}

export const TESTIMONIAL_COLOR_MAPS: Record<Color, TestimonialColorProps> = {
  [Color.GRAY]: {
    text: 'text-gray-800',
    bg: 'bg-gray-50'
  },

  [Color.BLUE]: {
    text: 'text-brand-blue',
    bg: 'bg-brand-blue-light'
  },
  [Color.PURPLE]: {
    text: 'text-brand-purple',
    bg: 'bg-brand-purple-light'
  }
}

/**
 *  Component to show a Testimonial - typically at the top of the page
 */

const Testimonial: React.FC<TestimonialProps> = ({
  className,
  color,
  headline,
  description,
  image,
  cite,
  ...rest
}: TestimonialProps) => {
  return (
    <div className={classNames(className, 'Testimonial')} {...rest}>
      <div className={`relative px-8 max-w-7xl mx-auto`}>
        <div
          className={`relative rounded-xl py-12 px-8 md:py-24 shadow-2xl overflow-hidden md:px-16 md:grid md:grid-cols-2 md:gap-x-8 ${TESTIMONIAL_COLOR_MAPS[color].bg}`}
        >
          {image && <div className="absolute inset-0 opacity-50 filter saturate-0 mix-blend-multiply">{image}</div>}
          <div className="relative lg:col-span-1">
            <blockquote className={`mt-6 ${TESTIMONIAL_COLOR_MAPS[color].text}`}>
              {headline && (
                <p className="text-xl tracking-tight font-medium md:text-3xl lg:text-4xl">
                  <span className="block">{headline}</span>
                </p>
              )}
              {description || cite ? (
                <footer className="mt-6">
                  <p className="flex flex-col font-medium">
                    {cite && <span>{cite}</span>}
                    {description && <span>{description}</span>}
                  </p>
                </footer>
              ) : null}
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  )
}

Testimonial.defaultProps = {
  color: Color.BLUE
}

export default Testimonial
