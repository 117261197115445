import React from 'react'
import { Color } from '../../../color.enum'
import theme from '../../../theme'
import { Text, View } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'

interface BarProps {
  /** What is the score (in %) */
  scorePercentage?: number
  /** What is the color of the Bar background and text labels? */
  color?: Color
  /** What is the headline of the Bar */
  headline?: string
  /** What is the size of the component */
  size?: BarSize
}

export interface ColorProps {
  headline: Style
  score: Style
  barContainer: Style
  barScore: Style
}

export const COLOR_MAPS: Record<Color, ColorProps> = {
  [Color.BLUE]: {
    headline: { color: theme.colors['brand-blue'] },
    score: { color: theme.colors['brand-blue'] },
    barContainer: {
      backgroundColor: theme.colors['brand-blue-light'],
      borderColor: theme.colors['brand-blue-light']
    },
    barScore: { backgroundColor: theme.colors['brand-blue'] }
  },
  [Color.PURPLE]: {
    headline: { color: theme.colors['brand-purple'] },
    score: { color: theme.colors['brand-purple'] },
    barContainer: {
      backgroundColor: theme.colors['brand-purple-light'],
      borderColor: theme.colors['brand-purple-light']
    },
    barScore: { backgroundColor: theme.colors['brand-purple'] }
  },
  [Color.GRAY]: {
    headline: { color: theme.colors.gray[500] },
    score: { color: theme.colors.gray[500] },
    barContainer: {
      backgroundColor: theme.colors.gray[50],
      borderColor: theme.colors.gray[50]
    },
    barScore: { backgroundColor: theme.colors.gray[500] }
  }
}

export enum BarSize {
  SMALL = 'small',
  BASE = 'base'
}

export interface SizeProps {
  headline: Style
  score: Style
  barContainer: Style
}

export const SIZE_MAPS: Record<BarSize, SizeProps> = {
  [BarSize.SMALL]: {
    headline: {
      fontSize: 10,
      fontWeight: 'medium'
    },
    score: {
      fontSize: 10,
      fontWeight: 'medium'
    },
    barContainer: {
      height: 10
    }
  },
  [BarSize.BASE]: {
    headline: {
      fontSize: 10,
      fontWeight: 'medium'
    },
    score: {
      fontSize: 10,
      fontWeight: 'medium'
    },
    barContainer: {
      height: 32
    }
  }
}

/**
 *  Component to show a score and reveal CTA's on hover
 */

const Bar: React.FC<BarProps> = ({
  size = BarSize.BASE,
  color = Color.BLUE,
  scorePercentage = 0,
  headline
}: BarProps) => {
  const colors = COLOR_MAPS[color]
  const sizes = SIZE_MAPS[size]
  return (
    <View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 2 }}>
        <Text style={{ ...colors.headline, ...sizes.headline}}>{headline}</Text>
        <Text style={{ ...colors.score, ...sizes.score }}>{scorePercentage}%</Text>
      </View>
      <View
        style={{
          position: 'relative',
          width: '100%',
          borderWidth: 2,
          borderStyle: 'solid',
          borderRadius: 3,
          ...sizes.barContainer,
          ...colors.barContainer
        }}
      >
        <View
          style={{
            ...colors.barScore,
            // position: 'absolute',
            height: '100%',
            borderRadius: 3,
            width: `${scorePercentage}%`
          }}
        />
      </View>
    </View>
  )
}

export default Bar
