import React from 'react'
import Button, { ButtonSize, ButtonVariant } from '../Button'
import { Color } from '../../color.enum'
import './styles.css'

interface TablePaginationProps {
  page: number
  limit: number
  nextPage: () => void
  prevPage: () => void
  totalPages: number
  hasPrevPage: boolean
  hasNextPage: boolean
}

const TablePagination: React.FC<TablePaginationProps> = ({
  page,
  nextPage,
  prevPage,
  totalPages,
  hasNextPage,
  hasPrevPage
}) => {
  return (
    <div className="min-w-0 flex-1 md:grid md:grid-cols-4 md:gap-6 mt-5 justify-center table-navigation-wrapper">
      {hasPrevPage && (
        <Button
          iconLeft="arrowLeft"
          className="justify-center"
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.SMALL}
          color={Color.BLUE}
          style={{ width: '50%' }}
          onClick={prevPage}
        >
          Previous
        </Button>
      )}

      <p>
        Page <span className="font-medium">{` ${page} `}</span>of {totalPages}{' '}
      </p>

      {hasNextPage && (
        <Button
          iconRight="arrowRight"
          className="justify-center"
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.SMALL}
          color={Color.BLUE}
          style={{ width: '40%' }}
          onClick={nextPage}
        >
          Next
        </Button>
      )}
    </div>
  )
}

export default TablePagination
