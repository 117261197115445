import { Color } from '../../../../../color.enum'
import Button, { ButtonVariant, ButtonSize } from '../../../../../components/Button'
import { navigate } from '@reach/router'
import { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import RemoveItemModal from '../../components/RemoveItemModal'

interface ItemCtaData {
  color: Color
  id: string
  surveyId: string
  postSurveyId: string
  questionId: string
  surveyStatus?: string
  refetch?: any
  type? : string
}

export const ListItemCta: React.FC<ItemCtaData> = ({
  color,
  id,
  surveyId,
  postSurveyId,
  questionId,
  surveyStatus,
  type,
  refetch
}) => {

  const [isOpen, setIsOpen] = useState(false)

  const [deletePostSurveyOption, { loading: pLoading }] = useMutation(
    DELETE_POSTSURVEY_OPTION, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      refetch()
      setIsOpen(false)
    }
  })



  const handleCloseRemoveOption = () => {
    setIsOpen(false)
  }

  const handleRemoveOption = (id: string) => {

      deletePostSurveyOption({
        variables:{
          id: id
        }
      })
  }

    


  return (
    <div className="flex space-x-2">
      <RemoveItemModal
        isOpen={isOpen}
        handleClose={handleCloseRemoveOption}
        handleConfirm={() => handleRemoveOption(id)}
        loading={pLoading}
      />
      {surveyStatus !== 'PUBLISHED' && (
        <Button variant={ButtonVariant.PLAIN} color={color} size={ButtonSize.SMALL} onClick={() => setIsOpen(true)}>
          Remove Option
        </Button>
      )}
      <Button
        variant={ButtonVariant.PRIMARY}
        color={color}
        size={ButtonSize.SMALL}
        onClick={() =>
          navigate(`/dashboard/admin/survey/${surveyId}/postSurvey/${postSurveyId}/question/${questionId}/option/${id}`)
        }
        iconRight="arrowRight"
      >
        View details
      </Button>
    </div>
  )
}

const  DELETE_POSTSURVEY_OPTION = gql`
  mutation deletePostSurveyOption($id: String!) {
    deletePostSurveyOption(id: $id) {
      status
      message
    }
  }
`

