import { useEffect, useState } from 'react'
import { Input } from '@plusplusminus/plusplusdash'
import queryString from 'query-string'
import { RouteComponentProps, useNavigate, useLocation } from '@reach/router'
import { useMutation, useLazyQuery, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useForm } from 'react-hook-form'
import { User } from '../../../common/types'
import { Color } from '../../../color.enum'
import Button, { ButtonVariant, ButtonSize } from '../../../components/Button'
import Loader from '../../../components/Loader/Loader'
import HeadingPageCenter from '../../../components/HeadingPageCenter'
import DatePicker from '../../../components/DatePicker/DatePicker'
import dayjs from 'dayjs'
import Icon from '../../../components/Icon/Icon'
import { Select } from '../survey/factors/components/Select'
import Alert, { AlertSize, AlertVariant } from '../../../components/Alert'

const form = [
  {
    label: 'Assessment Name',
    name: 'name',
    type: 'text',
    placeholder: 'Assessment Name',
    description:
      'The name will be used to identify the report. We advise naming the assessment after the organisation or group who will be taking this assessment.',
    options: {
      required: 'Assessment name is required'
    }
  },
  {
    label: 'Survey Close Date',
    name: 'dueDate',
    placeholder: 'Survey Close Date',
    type: 'datePicker',
    description:
      'This assessment will close at midnight UTC on the  Survey Close Date. We recommend giving the participants a minimum of 14 days to complete the survey.',
    options: {
      required: 'Survey Close Date is required'
    }
  },
  {
    label: 'Survey',
    name: 'surveyId',
    placeholder: 'Factor Type',
    type: 'survey'
  }
]

interface CreateAssignmentProps extends RouteComponentProps {
  user?: User
  setStep: (step: { step: string; props: any }) => void
}

export const CreateAssignment: React.FC<CreateAssignmentProps | any> = ({ setStep, ...props }) => {
  const { search } = useLocation()
  const [date, setDate] = useState<any>(null)
  const [dateError, setDateError] = useState<string | null>(null)

  const { loading: surveyLoading, data: surveyList } = useQuery(GET_SURVEY_LIST, {
    onCompleted: (data) => {
      const { surveys } = data

      if (surveys.length) {
        setValue('surveyId', surveys[0].id)
      }
    }
  })

  const navigate = useNavigate()
  const [getAssignment, { data, loading: assignmentLoading }] = useLazyQuery(GET_ASSIGNMENT_DETAILS, {
    fetchPolicy: 'no-cache'
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({ mode: 'onSubmit', reValidateMode: 'onChange' })

  const [createAssignment, { loading }] = useMutation(CREATE_ASSIGNMENT)

  useEffect(() => {
    const parsed = queryString.parse(search)
    const assignment = props?.assignmentId || parsed?.assignmentId
    if (assignment) {
      getAssignment({ variables: { id: assignment } })
    }
  }, [])

  useEffect(() => {
    if (data?.assignment) {
      const { assignment } = data
      setDate(dayjs(assignment.dueDate))
      Object.keys(assignment).forEach((a) => {
        setValue(a, assignment[a])
      })
    }
  }, [data])

  const onRegister = async (data: any) => {
    const { id, __typename, ...rest } = data
    //add validation here. If there are errors set the state and dont let them continue to the next step. Dont validate onChange because this can cause issues.

    if (!date) {
      setDateError('Survey Close Date is required')
      return
    }

    rest.dueDate = dayjs(date).format('YYYY-MM-DD')
    const vars: any = { input: rest }

    if (props?.assignmentId) {
      vars.id = props.assignmentId
    }

    createAssignment({
      variables: { ...vars }
    }).then((response) => {
      if (response?.data?.createAssignment?.id) {
        const { id, minRespondents, maxRespondents } = response.data.createAssignment
        setStep({ step: 'add-participants', props: { assignmentId: id, minRespondents, maxRespondents } })
      }
    })
  }

  if (assignmentLoading || surveyLoading) return <Loader className="h-100 my-10" color={Color.PURPLE} />

  return (
    <>
      <HeadingPageCenter
        headline="Create New Assessment"
        description="Please provide the details of the inventory"
        color={Color.BLUE}
      />
      {!surveyList?.surveys.length ? (
        <div className="section-container-sm">
          <Alert className="page-container mt-4" size={AlertSize.SMALL} variant={AlertVariant.ERROR}>
            No surveys are available. Please contact support for assistance.
          </Alert>
        </div>
      ) : (
        <div className="section-container-sm">
          <form
            action="#"
            autoComplete="no"
            onSubmit={handleSubmit(onRegister)}
            className="grid grid-cols-2 gap-y-4 sm:grid-cols-2 sm:gap-x-8"
          >
            {form.map((field) => {
              if (field.type === 'checkBox') {
                return (
                  <div className="flex items-center col-span-2">
                    <input
                      id={field.name}
                      type="checkbox"
                      className="h-4 w-4 text-brand-purple focus:ring-blue-900 border-gray-300 rounded"
                      {...register(field.name)}
                    />
                    <label htmlFor={field.name} className="ml-2 block text-sm text-gray-900">
                      {field.label}
                    </label>
                  </div>
                )
              }
              if (field.type === 'survey' && surveyList?.surveys.length > 1) {
                return (
                  <div className="col-span-2 sm:col-span-2" key={field.name}>
                    <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
                      {field.label}
                    </label>
                    <Select options={surveyList?.surveys} name={field.name} register={register} />
                  </div>
                )
              }
              if (field.type === 'datePicker') {
                return (
                  <div className="col-span-2 sm:col-span-2" key={field.name}>
                    <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
                      {field.label}
                      {field.description && <div className="text-xs py-1">{field.description}</div>}
                    </label>
                    <DatePicker selectedDate={date} selectDate={setDate} setDateError={setDateError} />

                    {dateError && <p className="text-sm text-red-500">{dateError}</p>}
                  </div>
                )
              }

              if (field.type === 'text') {
                return (
                  <div className="col-span-2 sm:col-span-2" key={field.name}>
                    <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
                      {field.label}
                      {field.description && <div className="text-xs py-1">{field.description}</div>}
                    </label>

                    {field.type === 'text' && (
                      <Input
                        as="input"
                        variant="standard"
                        width="full"
                        {...register(field.name, { ...field.options })}
                      />
                    )}
                    {errors[field.name]?.message && (
                      <p className="text-sm text-red-500">{errors[field.name].message}</p>
                    )}
                  </div>
                )
              }
            })}
            <div className="flex space-x-1 col-span-2">
              <Button
                className="justify-center align-middle mb-2"
                variant={ButtonVariant.PLAIN}
                size={ButtonSize.LARGE}
                color={Color.BLUE}
                style={{ width: '100%' }}
                type="submit"
                onClick={() => navigate('/dashboard/organization')}
              >
                <Icon className="pr-1" name="arrowLeft" />
                Back
              </Button>
              <Button
                iconRight="arrowRight"
                className="justify-center mb-2"
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.LARGE}
                color={Color.BLUE}
                style={{ width: '100%' }}
                type="submit"
              >
                {loading ? 'Loading...' : 'Next'}
              </Button>
            </div>
          </form>
        </div>
      )}
    </>
  )
}

export const CREATE_ASSIGNMENT = gql`
  mutation createAssignment($input: CreateAssignmentInput!, $id: String) {
    createAssignment(input: $input, assignmentId: $id) {
      id
      minRespondents
      maxRespondents
    }
  }
`

const GET_ASSIGNMENT_DETAILS = gql`
  query GetAssignmentDetails($id: String!) {
    assignment(id: $id) {
      id
      name
      dueDate
      canViewResults
      canShareResults
    }
  }
`

const GET_SURVEY_LIST = gql`
  query getSurveys {
    surveys(status: "published") {
      id
      name
    }
  }
`
