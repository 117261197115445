import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Color } from '../../color.enum'
import { classNames } from '../../utils/class-names'
import Icon from '../Icon/Icon'
import { IconType } from '../Icon/Icon.options'

export interface NoticeProps {
  /** What is the variant of the Notice? */
  variant: NoticeVariant
  /** What is the headline of the component */
  headline?: string
  /** What is the descriptive content of the component */
  description?: string
  /** What color to use - this will set the border, icon and bg shades */
  color?: Color
  /** What is the name of the icon to be displayed? */
  icon?: IconType
  /** Aditional Styles for the container */
  className?: string
}

interface NoticeVariantProps {
  wrapper: string
  icon: Omit<FontAwesomeIconProps, 'icon'>
}

export enum NoticeVariant {
  LARGE = 'large',
  SMALL = 'small'
}

export const VARIANT_MAPS: Record<NoticeVariant, NoticeVariantProps> = {
  [NoticeVariant.SMALL]: {
    wrapper: 'text-sm py-3 px-4',
    icon: { size: '1x' }
  },

  [NoticeVariant.LARGE]: {
    wrapper: 'text-sm py-6 px-6',
    icon: { size: '3x' }
  }
}

export const COLOR_MAPS: Record<Color, string> = {
  [Color.GRAY]: 'bg-gray-50 text-gray-500 border-gray-500',
  [Color.BLUE]: 'bg-brand-blue-light text-brand-blue border-brand-blue',
  [Color.PURPLE]: 'bg-purple-light text-purple border-purple'
}

export const Notice: React.FC<NoticeProps> = ({ variant, headline, description, color, icon, children, className }) => {
  return (
    <div className={classNames(className)}>
      <div
        className={classNames(
          className,
          `rounded border-solid border`,
          VARIANT_MAPS[variant].wrapper,
          color && COLOR_MAPS[color]
        )}
      >
        <div className={'flex justify-start items-center flex-col sm:flex-row '}>
          {/* {icon && */}
          <div className={classNames(`items-center mr-3 mb-2 sm:mb-0`)}>
            {icon && <Icon name={icon} fontAwesomeIconProps={VARIANT_MAPS[variant].icon} />}
          </div>
          {/* } */}
          <div className={classNames('items-center')}>
            {headline && <p className={`font-medium`}>{headline}</p>}
            {description && <p className={''}>{description}</p>}
            {children && <div className={''}>{children}</div>}
          </div>
        </div>
      </div>
    </div>
  )
}

Notice.defaultProps = {
  color: Color.GRAY,
  variant: NoticeVariant.LARGE
}

export default Notice
