import { api } from './BreadCrumbs.api'
import Icon from '../Icon/Icon'
import { Link } from '@reach/router'
import { classNames } from '../../utils/class-names'

interface Page {
  name: string
  path: string
  current: boolean
}
interface BreadCrumbsProps {
  pages: Array<Page>
  // Aditional styles for the container
  className?: string
}

const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ pages, className }) => {
  return (
    <nav className={classNames(className, `flex`)} aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <Link to="/" className="text-gray-400 hover:text-gray-500">
              <Icon className="flex-shrink-0 h-5 w-5" name="homeLgAlt" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name}>
            <div className="flex items-center">
              <Icon className="flex-shrink-0 h-5 w-5 text-gray-400" name="chevronRight" aria-hidden="true" />
              <Link
                to={page.path}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}

BreadCrumbs.defaultProps = {
  pages: api,
  className: 'pl-20'
}
export default BreadCrumbs
