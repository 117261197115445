import { useState } from 'react'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { useQuery, gql, useMutation } from '@apollo/client'
import { User } from '../../../common/types'
import { Color } from '../../../color.enum'
import Button, { ButtonVariant, ButtonSize } from '../../../components/Button'
import HeadingSection from '../../../components/HeadingSection'
import { Input } from '@plusplusminus/plusplusdash'
import { formatDate } from '../../../services/formatDate'
import HeadingPageCenter from '../../../components/HeadingPageCenter'
import Loader from '../../../components/Loader/Loader'
import Icon from '../../../components/Icon/Icon'
import Modal from '../../../components/Modal/Modal'

interface AssignmentConfirmationProps extends RouteComponentProps {
  user?: User
  setStep: (step: { step: string; props: any }) => void
}

export const AssignmentConfirmation: React.FC<AssignmentConfirmationProps & any> = ({ setStep, ...props }) => {
  const [message, setMessage] = useState('')
  const [confirm, setConfirm] = useState(false)
  const navigate = useNavigate()
  const { loading, data } = useQuery(GET_ASSIGNMENT, {
    variables: {
      assignmentId: props.assignmentId
    },
    fetchPolicy: 'no-cache'
  })

  const [publishAssignment ] = useMutation(PUBLISH_ASSIGNMENT, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      navigate('/dashboard/organization')
    }
  })

  const [isOpen, setIsOpen] = useState(true)

  return (
    <main>
      <HeadingPageCenter description={null} headline="Assessment Confirmation" color={Color.BLUE} />
      <div className="section-container-sm">
        <AssignmentDetails loading={loading} data={data?.assignment} />
        <div className="mt-5">
          <HeadingSection headline="Customize Your Invitations" color={Color.BLUE} />
          <div key="custom-message-box">
            <label htmlFor="customMessage" className="text-base font-medium text-brand-blue my-1 inline-block">
              An email will be sent to each participant inviting them to start the Inventory. Your custom message will
              be added to their invitation.
            </label>

            <Input
              as="textarea"
              variant="standard"
              width="full"
              name="customMessage"
              onChange={(event) => setMessage(event.target.value)}
            />
          </div>
        </div>

        <div className="flex space-x-2 mt-4 ">
          {confirm && (
            <Modal isModalOpen={isOpen} title="Please Confirm" onClose={() => setConfirm(false)}>
              <div>
                <p className="text-md text-brand-purple">
                  Please ensure that all your intended participants have been added and all assessment details are
                  correct before you submit. You will not be able to make any changes after clicking "Confirm". The
                  assessment will be live and an invitation email will be sent to each participant.
                </p>
                <div className="flex space-x-2  mt-3">
                  <Button
                    className="flex-1 text-center justify-center outline-none"
                    variant={ButtonVariant.PLAIN}
                    color={Color.BLUE}
                    size={ButtonSize.LARGE}
                    onClick={() => setConfirm(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="flex-1 text-center justify-center"
                    variant={ButtonVariant.PRIMARY}
                    color={Color.BLUE}
                    size={ButtonSize.LARGE}
                    onClick={() => {
                      publishAssignment({
                        variables: {
                          invitationMessage: message,
                          assignmentId: props.assignmentId
                        }
                      })
                      setConfirm(false)
                    }}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </Modal>
          )}
          <Button
            className="flex-1 text-center justify-center"
            variant={ButtonVariant.PLAIN}
            color={Color.BLUE}
            size={ButtonSize.LARGE}
            onClick={() => setStep({ step: 'pre-survey', props: props })}
          >
            <Icon className="pr-1" name="arrowLeft" />
            Back
          </Button>
          <Button
            className="flex-1 text-center justify-center"
            variant={ButtonVariant.PRIMARY}
            color={Color.BLUE}
            size={ButtonSize.LARGE}
            onClick={() => setConfirm(true)}
          >
            Publish
          </Button>
        </div>
      </div>
    </main>
  )
}

const AssignmentDetails: React.FC<any> = ({ loading, data }) => {
  return (
    <>
      <HeadingSection headline="Assessment Details" color={Color.BLUE} />
      {loading ? (
        <Loader className={'h-100 my-10'} color={Color.PURPLE} />
      ) : (
        <div className="text-md text-brand-purple mt-1">
          <div>
            <span className="text-base font-medium text-brand-purple">Assessment Name:</span> {data.name}
          </div>
          <div>
            <span className="text-base font-medium text-brand-purple">Due date:</span> {formatDate(data.dueDate)}
          </div>
          <div className="mt-5">
            <HeadingSection headline="Participants" color={Color.BLUE} />

            {data.respondent.map(({ user }: any) => {
              return (
                <>
                  <div className="flex text-md text-brand-purple pb-1">
                    <div className="flex-1 mt-1 justify-start">{user.firstName + ' ' + user.lastName}</div>
                    <div className="flex-1 mt-1 justify-end">{user.email}</div>
                  </div>
                </>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

const GET_ASSIGNMENT = gql`
  query GetAssignment($assignmentId: String!) {
    assignment(id: $assignmentId) {
      name
      dueDate
      canViewResults
      canShareResults
      respondent {
        id
        user {
          firstName
          lastName
          email
        }
      }
    }
  }
`
const PUBLISH_ASSIGNMENT = gql`
  mutation publishAssignment($invitationMessage: String!, $assignmentId: String!) {
    publishAssignment(invitationMessage: $invitationMessage, assignmentId: $assignmentId)
  }
`
