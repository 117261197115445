import { gql } from '@apollo/client'

export const WELCOME_CONTENT_QUERY = gql`
  query WelcomeContent {
    contentCollection(where: { identifier: "app-welcome-message" }) {
      items {
        headline
        content {
          json
        }
        image {
          url
        }
      }
    }
  }
`
