import { useContext } from 'react'
import { Link, navigate, RouteComponentProps, useMatch } from '@reach/router'
import { Context } from '../../context/context'
import logo from '../../../public/mci-logo.png'
import { useUserQuery } from '../../hooks/useUserQuery'
import Button, { ButtonVariant, ButtonSize } from '../Button'
import { Color } from '../../color.enum'
import { timeBeforeExpiry } from '../../services/formatDate'
import Header from '../Header/Header'
import ButtonNotice from '../ButtonNotice'
import { useCheckLogin } from '../../hooks/useCheckLogin'
import HeaderNavItemWrapper from '../HeaderNavItem/HeaderNavItemWrapper'

const roleMenuItems = (user?: any, assignmentExpire?: string, timeOfExpiration?: string) => {
  const items: any = {
    admin: {
      buttonProps: {
        buttonType: 'standard',
        size: ButtonSize.SMALL,
        color: Color.BLUE,
        variant: ButtonVariant.PRIMARY,
        buttonLabel: 'Sign out',
        iconLeft: 'signIn',
        onClick: () => {
          localStorage.removeItem('mci-token')
          navigate('/login')
        }
      },
      items: [
        { label: 'Dashboard', to: '/dashboard/admin' },
        { label: 'Users', to: '/dashboard/admin/users' },
        { label: 'Organisations', to: '/dashboard/admin/organizations' },
        { label: 'Surveys', to: '/dashboard/admin/surveys' },
        { label: 'Assessments', to: '/dashboard/admin/assessments' },
        { label: 'My Assessments', to: '/dashboard/organization' },
        { label: 'Reports', to: '/dashboard/admin/reports' }        
      ]
    },
    organization_owner: {
      buttonProps: {
        buttonType: 'notice',
        icon: 'clock',
        color: Color.BLUE,
        variant: ButtonVariant.PRIMARY,
        buttonLabel: 'Sign out',
        buttonIcon: 'signOut',
        headline: timeOfExpiration ? timeOfExpiration : '',
        description: assignmentExpire ? 'left to complete' : '',
        onClick: () => {
          localStorage.removeItem('mci-token')
          navigate('/login')
        }
      },
      items: [{ label: 'Dashboard', to: '/dashboard/organization' }]
    },
    respondent: {
      buttonProps: {
        buttonType: 'notice',
        icon: 'clock',
        color: Color.BLUE,
        variant: ButtonVariant.PRIMARY,
        buttonLabel: 'Sign out',
        buttonIcon: 'signOut',
        headline: timeOfExpiration ? timeOfExpiration : '',
        description: assignmentExpire ? 'left to complete' : '',
        onClick: () => {
          localStorage.removeItem('mci-token')
          navigate('/login')
        }
      },
      items: []
    }
  }

  return user?.role ? items[user.role] : []
}

export const MainNav: React.FC<RouteComponentProps> = () => {
  const { contextValue } = useContext(Context)
  const { loading, data } = useUserQuery()
  const isLoggedIn = useCheckLogin()
  const matchHome = useMatch('/')

  if (loading) return null

  if (loading) return null

  const assignmentExpire = timeBeforeExpiry(contextValue?.dueDate)
  const timeOfExpiration = assignmentExpire
    ? `${assignmentExpire.day}d ${assignmentExpire.hour}h ${assignmentExpire.minute}m`
    : ''

  const { items = [], buttonProps = null } = data?.me ? roleMenuItems(data?.me, assignmentExpire, timeOfExpiration) : []

  return (
    <Header
      renderLogo={
        <Link to="/">
          <img className="block h-6 md:h-9 w-auto" src={logo} alt="MCI home page" />
        </Link>
      }
      renderQuickLinks={
        <>
          {buttonProps && isLoggedIn ? (
            <>
              {buttonProps.buttonType === 'standard' && <Button {...buttonProps}>{buttonProps.buttonLabel}</Button>}
              {buttonProps.buttonType === 'notice' && (
                <ButtonNotice {...buttonProps}>{buttonProps.buttonLabel}</ButtonNotice>
              )}
            </>
          ) : null}

          {matchHome && !isLoggedIn && (
            <Button
              size={ButtonSize.SMALL}
              color={Color.BLUE}
              variant={ButtonVariant.PRIMARY}
              iconLeft="signIn"
              onClick={() => {
                navigate('/login')
              }}
            >
              Sign in
            </Button>
          )}
        </>
      }
      renderNavigation={
        items.length && isLoggedIn ? (
          <>
            {items.map((item: any) => (
              <HeaderNavItemWrapper to={item.path} {...item}>
                {item.label}
              </HeaderNavItemWrapper>
            ))}
          </>
        ) : null
      }
    />
  )
}
