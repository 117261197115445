import { Testimonial } from '../components'
import gql from 'graphql-tag'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import React from 'react'
import { Color } from '../color.enum'
import { Content } from '../common/types'

interface TestimonialProps {
  attribution?: string
  role?: string
  content?: Content
  color: Color
  image: TestimonialImage
  sys: { id: string }
}

interface TestimonialImage {
  url: string
  title: string
}

const TestimonialWrapper: React.FC<TestimonialProps> = ({ attribution, role, content, image, color, sys }) => {
  return (
    <Testimonial
      color={color}
      cite={attribution}
      description={role}
      headline={content && documentToReactComponents(content.json)}
      image={image && <img className="w-full h-full object-cover" src={image.url} alt={image.title} />}
      id={sys.id}
    />
  )
}

export default TestimonialWrapper

export const TESTIMONIAL_FIELDS = gql`
  fragment TestimonialFields on LayoutTestimonial {
    sys {
      id
    }
    name
    content {
      json
    }
    attribution
    role
    color
    image {
      title
      url
    }
  }
`
