import { gql, useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import cn from 'classnames'
import React, { useMemo, useState } from 'react'
import { Color } from '../../../color.enum'
import { User } from '../../../common/types'
import Bar from '../../../components/Bar'
import Button, { ButtonSize, ButtonVariant } from '../../../components/Button'
import Card, { CardVariant } from '../../../components/Card'
import Loader from '../../../components/Loader/Loader'
import Modal from '../../../components/Modal/Modal'
import Radar, { api as radarApi, DimensionProps } from '../../../components/Radar'
import { ArchetypeProps } from '../../../components/Radar/Radar.helpers'
import { useResultsQuery } from '../../../generated'
import { RESULTS_CONTENT_QUERY } from './contentful-queries'
import PDFDownload from './PDFReport'
import client from '../../../contentful'
import RichTextRenderer from '../../../modules/RichTextRenderer'
import parse from 'html-react-parser'
import draftToHtml from 'draftjs-to-html'

interface ResultsContainerProps extends RouteComponentProps {
  user?: User
  assignmentId: string
}

const Results: React.FC<ResultsContainerProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeItem, setActiveItem] = useState<DimensionProps>(radarApi[0].dimensions[0])
  const [radarImageBuffer, setRadarImageBuffer] = useState<ArrayBuffer>()

  const { data: contentfulData } = useQuery(RESULTS_CONTENT_QUERY, {
    client,
    variables: { contentIdentifier: 'app-results-message-individual' }
  })

  const { loading, data } = useResultsQuery({
    variables: {
      assignmentId: props.assignmentId
    }
  })

  const archetypes = useMemo<Array<ArchetypeProps>>(() => {
    const a = []
    if (data) {
      for (const res of data.basicResults.results) {
        const dimensions = []
        for (const r of res.responses) {
          dimensions.push({
            score: r.score,
            name: r.factor.headline || r.factor.name,
            description: r.factor.description ?? '',
            content: r.factor.content ?? ''
          })
        }
        a.push({ dimensions, color: res.color as Color, name: res.factorType, description: res.factorTypeDescription })
      }
    }
    return a
  }, [data])

  const pdfs = useMemo(() => {
    if (data && radarImageBuffer) {
      return (
        <>
          <PDFDownload
            key="individual-report"
            assignmentId={props.assignmentId}
            radarImage={radarImageBuffer}
            archetypes={archetypes}
            statementCount={data.basicResults.statementCount}
            isIndividual={true}
            reportName={props.user?.organization.name}
          />
          {data.basicResults.areOrgResultsAvailable && (
            <PDFDownload
              key="org-report"
              assignmentId={props.assignmentId}
              radarImage={radarImageBuffer}
              archetypes={archetypes}
              statementCount={data.basicResults.statementCount}
              isIndividual={false}
              reportName={props.user?.organization.name}
            />
          )}
        </>
      )
    }
  }, [radarImageBuffer, data])

  if (loading) return <Loader color={Color.PURPLE} />
  if (!data) return null

  const onClick = (activeItem: DimensionProps) => {
    setIsOpen(true)
    setActiveItem(activeItem)
  }

  const { headline, content } = contentfulData?.contentCollection?.items?.[0] ?? {}

  return (
    <>
      <main>
        <div className="page-container-lg">
          <Card
            variant={CardVariant.STANDARD}
            subtitle={props.user?.organization.name}
            headline={`${headline}, ${props.user?.firstName}`}
            media={<Radar setImageBuffer={setRadarImageBuffer} archetypes={archetypes} />}
          >
            {content ? (
              <div className="mt-10">
                <RichTextRenderer content={content} />
              </div>
            ) : null}
            {archetypes.map((a, index) => (
              <FactorResult
                className={cn('mt-10', { 'mb-6': index === data.basicResults.results.length - 1 })}
                type={a.name}
                description={a.description}
                dimensions={a.dimensions}
                color={a.color}
                onClick={onClick}
              />
            ))}
            {pdfs}
            {contentfulData ? (
              <a href={contentfulData.assetPdfCollection.items[0].file.url} target="_blank" rel="noopener noreferrer">
                <Button
                  variant={ButtonVariant.PRIMARY}
                  iconLeft="pdf"
                  color={Color.BLUE}
                  size={ButtonSize.LARGE}
                  className="mb-6"
                >
                  {contentfulData.assetPdfCollection.items[0].label}
                </Button>
              </a>
            ) : null}
          </Card>
        </div>
      </main>

      <Modal isModalOpen={isOpen} title={activeItem.name} onClose={() => setIsOpen(false)}>
        {activeItem?.content ? <div className="prose">{parse(draftToHtml(JSON.parse(activeItem.content)))}</div> : null}
      </Modal>
    </>
  )
}

interface FactorResultProps {
  type: string
  description?: string
  color: Color
  dimensions: DimensionProps[]
  className?: string
  onClick: (dim: DimensionProps) => void
}

const FactorResult = (props: FactorResultProps) => {
  return (
    <div className={props.className}>
      <div className="prose">
        <p>
          <strong>{props.type}</strong>
          <br />
          {props.description}
        </p>
      </div>
      <div className="my-4">
        {props.dimensions.map((dim) => {
          return (
            <>
              <Bar
                key={dim.name}
                headline={dim.name}
                scorePercentage={dim.score}
                color={props.color}
                className="mb-6"
                onClick={() => props.onClick(dim)}
              />
            </>
          )
        })}
      </div>
    </div>
  )
}

export default Results

const GET_RESULTS = gql`
  query Results($assignmentId: String!) {
    basicResults(assignmentId: $assignmentId) {
      results {
        factorType
        factorTypeDescription
        color: factorTypeColor
        responses {
          factor {
            name
            description
            headline
            content
          }
          score
        }
      }
      statementCount
      areOrgResultsAvailable
    }
  }
`
