import React from 'react'
import { Color } from '../../color.enum'
import { classNames } from '../../utils/class-names'

export interface SectionCenterProps {
  /** Subtitle above the headline */
  subtitle?: string
  /** Headline of the component */
  headline?: string
  /** Descriptive text to tshow below the headline */
  description?: React.ReactNode | string
  /** Add a children to the component */
  children?: (color: Color) => JSX.Element
  /** Aditional Styles for the container */
  className?: string
  /** What color is the componet? */
  color: Color
  /** Id to identify specific component as provided by the CMS */
  id?: string
}

interface SectionProps {
  text: string
}

export const SECTION_COLOR_MAPS: Record<Color, SectionProps> = {
  [Color.GRAY]: {
    text: 'text-gray-500'
  },
  [Color.BLUE]: {
    text: 'text-brand-blue'
  },
  [Color.PURPLE]: {
    text: 'text-brand-purple'
  }
}

/**
 *  Component to show an Section that is center aligned
 */

const SectionCenter: React.FC<SectionCenterProps> = ({
  className,
  color,
  subtitle,
  headline,
  description,
  children,
  ...rest
}: SectionCenterProps) => {
  return (
    <div className={classNames('SectionCenter relative py-16 md:py-24', className)} {...rest}>
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        {subtitle && (
          <h2 className={`text-sm font-normal tracking-widest uppercase ${SECTION_COLOR_MAPS[color].text}`}>
            {subtitle}
          </h2>
        )}
        {headline && (
          <p className="mx-auto mt-2 text-4xl text-gray-900 tracking-tight md:text-5xl lg:max-w-4xl">{headline}</p>
        )}
        {description && <p className="mt-5 max-w-prose mx-auto text-lg">{description}</p>}
        {children && <div className="mt-12">{children(color)}</div>}
      </div>
    </div>
  )
}

SectionCenter.defaultProps = {
  color: Color.BLUE
}

export default SectionCenter
