export const language = {
    en: {
        site: {
            title: 'MCI - Mission Capacity Inventory',
            description: "Understanding Your Capacity is Key to the Growth and Longevity of Your Organisation"
        },
        auth: {
            title: {
                register: 'MCI - Mission Capacity Registration',
                login: 'MCI - Mission Capacity Login'
            },
            description: {
                register: "Let's get started Welcome to the Mission Capacity Inventory (MCI)",
                login: 'log in here to begin your assessment'
            }
        },
        admin: {
            dashBoard: {
                title: "MCI - Admin Dashboard",
                description: "View and Manage; Users, Organisations and Surveys"
            },

            survey: {
                edit: {
                    title: "MCI - Edit Survey",
                    description: "Edit the form to change the details of the survey"
                },
                create: {
                    title: "MCI - Create Survey",
                    description: "Fill the form to create a new survey "
                },
                list: {
                    title: "MCI- Surveys",
                    description: "Welcome to the surveys page. You can use this view to create a new survey, or view the status or surveys that have been created in the past."
                },
                emptyList: "You currently do not have any surveys",

                singleSurvey: {
                    title: "View single Survey",
                    description: "Single survey"
                },

                questionnaire: {
                    add: {
                        title: "MI - Add new  Questionnaire",
                        description: "Add new  Questionnaire"
                    },
                    edit: {
                        title: "MI - Edit new  Questionnaire",
                        description: "Edit new  Questionnaire"
                    }
                }

            },
            user: {
                singleUserView: {
                    title: 'View user details',
                    description: 'View user details'

                },
                add: {
                    title: "MCI - Add new user",
                    description: "Add new user"
                },
                edit: {
                    title: "MCI - Edit new user",
                    description: "Edit new user"
                },
                list: {
                    title: "MCI - Users",
                    description: "You can use this view to add new users, or view current users."
                }
            },
            factor: {
                add: {
                    title: "MCI - Add factor",
                    description: "MCI Add new factor"
                },
                edit: {
                    title: "MCI - Edit factor",
                    description: "MCI Edit ne factor"
                }
            },

            option: {
                title: "Survey Option",
                description: "Survey Option detaits"
            },

            question: {
                create: {
                    title: 'Create New Question',
                    description: 'Fill form to add new question to questionare'
                },
                edit: {
                    title: 'Edit Question',
                    description: 'Change form fields values to edit'
                }
            },

            statement: {
                title: 'View statement details',
                description: 'View statement details',
                create: {
                    title: 'Create New Statement',
                    description: 'Fill the form to add new statement'
                },
                edit: {
                    title: 'Edit Statement',
                    description: 'Change form fields values to edit'
                }

            },

            factorType: {
                title: 'View factor type details',
                description: 'View factor type details',
                create: {
                    title: 'Create New Factor Type',
                    description: 'Fill the form to add new factor type'
                },
                edit: {
                    title: 'Edit Factor Type',
                    description: 'Change form fields values to edit'
                }

            },
            assignments: {
                title: " MCI - Assignments",
                description: 'List of all assignments from belonging to different organisations'
            },
            organisations: {
                title: "MCI - Organisations",
                description: 'List of all organisations'
            }


        },
        orgOwner: {
            dashboard: {
                title: 'Administrator Dashboard',
                description: "Welcome to your dashboard where you can view the status and results of all of your current and past assessments."
            },
            assignment: {
                title: 'Respondent assesment',
                description: 'Create assessment, add repondents and fill pre-survey',

                results:{
                    title: 'MCI - Respondent assesment results',
                    description: 'MCI - Respondent assesment results',
                }
            }

        },
        respondent: {
            dashboard: {
                title: 'Assessment Dashboard',
                description: "Welcome to your dashboard. You can use this view to participate in the assessments you have been invited to."
            }
        },

    }
}