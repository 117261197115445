import { RouteComponentProps, useNavigate, useParams} from '@reach/router'
import {Helmet} from 'react-helmet';
import HeadingPage from '../../../../components/HeadingPage'
import { Color } from '../../../../color.enum'
import Button from '../../../../components/Button'
import { ButtonVariant, ButtonSize } from '../../../../components/Button'
import { gql, useQuery } from '@apollo/client'
import Loader from '../../../../components/Loader/Loader'
import queryString from 'query-string'
import FactorList from '../factors/factorList'
import { useBreadcrumbs } from '../../../../hooks/useBreadcrumbs'
import BreadCrumbs from '../../../../components/BreadCrumbs/BreadCrumbs'
import { language } from '../../../../common/i18n'

interface SinglePreSurveyQuestionOptionInterface extends RouteComponentProps<any> {
  history: any
}

interface DetailsInterface {
  code: string
}

const Details: React.FC<DetailsInterface> = ({ code }) => {
  return (
    <div className="mt-5 space-y-1">
      {code && (
        <div>
          <span className="inline text-brand-blue font-medium">Code:</span>
          <span className="ml-1">{code}</span>
        </div>
      )}
    </div>
  )
}

const SinglePreSurveyQuestionOption: React.FC<SinglePreSurveyQuestionOptionInterface> = (props) => {
  
  const navigate = useNavigate()
  const { optionId, questionId,surveyId, preSurveyId } = useParams()
  const [getBreadCrumbs] = useBreadcrumbs()
  const { loading, data } = useQuery(GET_SINGLE_PRE_SURVEY_QUESTION_OPTION, {
    fetchPolicy: 'no-cache',
    variables: { id: optionId }
  })
  const editPresurveyQuestionOptionUrl = queryString.stringifyUrl({
    url: `/dashboard/admin/survey/${surveyId}/preSurvey/${preSurveyId}/question/${questionId}/option/${optionId}/edit`
  })

  if (loading) return <Loader color={Color.PURPLE} />

  const bc = getBreadCrumbs('demographicQuestionOption', surveyId, '', 'Organisation Questionaires', preSurveyId, questionId)

  return (
    <div className="page-container-lg">
      <Helmet>
        <title>{language.en.admin.option.title}</title>
        <meta name="description" content={language.en.admin.option.description}/>
      </Helmet>
      <main>
      <BreadCrumbs pages={bc} className="py-0 pb-10" />
        <HeadingPage
          headline={data?.preSurveyQuestionOption?.text}
          color={Color.BLUE}
          description={<Details code={data?.preSurveyQuestionOption?.code} />}
          children={() => {
            return (
              <div className="flex">
                <Button
                  variant={ButtonVariant.PRIMARY}
                  color={Color.BLUE}
                  size={ButtonSize.SMALL}
                  onClick={() => {
                    navigate(editPresurveyQuestionOptionUrl, {
                      state: { preSurveyQuestionOption: data?.preSurveyQuestionOption }
                    })
                  }}
                >
                  Edit Option
                </Button>
              </div>
            )
          }}
        />

        {data?.preSurveyQuestionOption?.factor?.id && (
          <>
            <FactorList factors={[data.preSurveyQuestionOption.factor]} surveyId={surveyId} />
          </>
        )}
      </main>
    </div>
  )
}

export default SinglePreSurveyQuestionOption

const GET_SINGLE_PRE_SURVEY_QUESTION_OPTION = gql`
  query preSurveyQuestionOption($id: String!) {
    preSurveyQuestionOption(id: $id) {
      text
      code
      factor {
        name
        id
        factorType {
          name
        }
        statements {
          id
        }
        survey{
          status
        }
      }
    }
  }
`
