import { useEffect } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { Box, Input } from '@plusplusminus/plusplusdash'
import { useForm } from 'react-hook-form'
import { useNavigate } from '@reach/router'
import { InputValidator } from '../../services/validate'
import { Color } from '../../color.enum'
import { ButtonVariant, ButtonSize } from '../../components/Button'
import Button from '../../components/Button'
import Icon from '../../components/Icon/Icon'
import parse from 'html-react-parser'
import HeadingPageCenter from '../../components/HeadingPageCenter'
import Loader from '../../components/Loader/Loader'

const form = [
  {
    label: 'First Name',
    name: 'firstName',
    type: 'text',
    placeholder: 'First Name',
    options: {
      required: 'Last name is required',
      validate: (input: string) =>
        new InputValidator(input).validateAlphaNumericWithSpace() || 'Enter a valid first name'
    }
  },
  {
    label: 'Last Name',
    name: 'lastName',
    placeholder: 'Last Name',
    options: {
      required: 'Last name is required',
      validate: (input: string) =>
        new InputValidator(input).validateAlphaNumericWithSpace() || 'Enter a valid last name'
    },

    type: 'text'
  },
  {
    label: 'Email',
    name: 'email',
    placeholder: 'Email',
    options: {
      required: 'Email is required',
      validate: (input: string) => new InputValidator(input).validateEmail() || 'Enter a valid email'
    },
    type: 'email'
  }
]

interface Props {
  onSubmit?: (data: any, reset: any, formType: string) => void
  loading: boolean
  success: any
  userId: string
  formType: string
}

export const AddUserForm: React.FC<Props> = (props) => {

  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange' })

  const onRegister = async (data: any) => {
    if (props.onSubmit) {
      await props.onSubmit(data, reset, props.formType)
    }
  }

  const [loadUserData, { called, loading, data }] = useLazyQuery(GET_A_USER, {
    variables: { id: props.userId },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const { user } = data
      console.log(data)
      Object.keys(user).forEach((u) => {
        setValue(u, user[u])
      })
    }
  })

  useEffect(() => {
    if (props?.userId) {
      loadUserData()
    }
  }, [])

  
  
  if (loading) return <Loader color={Color.PURPLE} />

  return (
    <div>
      <HeadingPageCenter
        headline={props?.formType === 'edit' ? "Edit User": "Add New User" }
        color={Color.BLUE}
        description={props?.formType === 'create' && parse('Please fill in the user details below to add a new user. ')}
      />
      <div className="section-container-sm">
        <form
          action="#"
          autoComplete="no"
          onSubmit={handleSubmit(onRegister)}
          className="grid grid-cols-2 gap-y-4 sm:grid-cols-2 sm:gap-x-8"
        >
          {form.map((field) => {
            return (
              <Box className="col-span-2 sm:col-span-2" key={field.name}>
                <label htmlFor={field.name} className="block text-sm font-medium text-gray-900 mb-1">
                  {field.label}
                </label>
                <Input
                  as="input"
                  variant="standard"
                  width="full"
                  {...register(field.name, { ...field.options })}
                ></Input>
                {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
              </Box>
            )
          })}
          <div className="flex space-x-2 col-span-2">
            <Button
              className="flex-1 justify-center align-middle mb-2"
              variant={ButtonVariant.PLAIN}
              color={Color.BLUE}
              size={ButtonSize.LARGE}
              style={{ width: '100%' }}
              onClick={() => {
                navigate(props.formType === 'create' ? '/dashboard/admin/users': `/dashboard/admin/users/${props.userId}`)
              }}
            >
              <Icon className="pr-1" name="arrowLeft" />
              Back
            </Button>
            <Button
              className="flex-1 justify-center align-middle mb-2"
              color={Color.BLUE}
              size={ButtonSize.LARGE}
              type="submit"
              variant={ButtonVariant.PRIMARY}
            >
              {props.loading ? 'Loading...' : 'Submit'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}


const GET_A_USER = gql`
  query UserDetails($id:String!) {
    user(id:$id) {
      email
      firstName
      lastName
    }
  }
`