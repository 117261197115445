import React from 'react'

export interface TermsProps {
  /** Text preceding "Terms & Conditions and Privacy Policy"*/
  text: string
}

const TermsAndConditions: React.FC<TermsProps> = ({ text }) => {
  return (
    <>
      <p className="text-sm text-gray-500 prose">
        {text}{' '}
        <a href="https://maclellan-mci.netlify.app/terms-and-conditions" target="_blank">
          Terms &amp; Conditions
        </a>{' '}
        and{' '}
        <a href="https://maclellan-mci.netlify.app/privacy-policy" target="_blank">
          Privacy Policy
        </a>
        .
      </p>
    </>
  )
}

export default TermsAndConditions
