import { useState, useEffect } from 'react'
import { RouteComponentProps } from '@reach/router'
import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client'
import { User } from '../../../common/types'
import { Color } from '../../../color.enum'
import Button, { ButtonVariant, ButtonSize } from '../../../components/Button'
import HeadingSection from '../../../components/HeadingSection'
import { InputValidator } from '../../../services/validate'
import { useForm } from 'react-hook-form'
import { Box, Input } from '@plusplusminus/plusplusdash'
import parse from 'html-react-parser'
import HeadingPageCenter from '../../../components/HeadingPageCenter'
import Icon from '../../../components/Icon/Icon'
import Modal from '../../../components/Modal/Modal'
import Alert, { AlertSize, AlertVariant } from '../../../components/Alert'
import Notice, { NoticeVariant } from '../../../components/Notice'
import Loader from '../../../components/Loader/Loader'
import { useFormatApolloError } from '../../../hooks/useFormatApolloError'
import { useUserQuery } from '../../../hooks/useUserQuery'

const form = [
  {
    label: 'First Name',
    name: 'firstName',
    type: 'text',
    placeholder: 'First Name',
    options: {
      required: 'First name is required'
    }
  },
  {
    label: 'Last Name',
    name: 'lastName',
    type: 'text',
    placeholder: 'Last Name',
    options: {
      required: 'Last name is required'
    }
  },
  {
    label: 'Email',
    name: 'email',
    placeholder: 'Email',
    type: 'text',
    options: {
      required: 'Email is required',
      validate: (input: string) => new InputValidator(input).validateEmail() || 'Enter a valid email'
    }
  }
]

interface Respondent {
  user: User
  id: string
}
interface RespondentListProps {
  respondents: Array<Respondent>
  loading: boolean
  handleDeleteRespondent: (userId: string) => void
  deleteRespLoading: boolean
}

export const RespondentList: React.FC<RespondentListProps> = ({
  respondents = [],
  loading,
  handleDeleteRespondent,
  deleteRespLoading
}) => {
  if (loading) return <Loader color={Color.BLUE} className="h-full my-10" />
  return (
    <>
      {!respondents.length ? (
        <Notice variant={NoticeVariant.SMALL} className="py-6 flex-1.5 mb-8" icon="info">
          Please add your first participant. Please note that there is a maximum number of 10 participants allowed per
          Assessment.
        </Notice>
      ) : (
        <>
          <div className="mb-4">
            <HeadingSection className="mt-10 sm:mb-4" headline="Participants" color={Color.BLUE} />
            {respondents.map((respondent) => (
              <div className="mb-4 flex text-md text-brand-purple justify-between">
                <div className="flex flex-col">
                  <span className="block">
                    {respondent.user.firstName} {respondent.user.lastName}
                  </span>
                  <span className="block">{respondent.user.email}</span>
                </div>
                <Button
                  variant={ButtonVariant.PLAIN}
                  size={ButtonSize.SMALL}
                  color={Color.PURPLE}
                  onClick={() => handleDeleteRespondent(respondent.id)}
                >
                  {deleteRespLoading ? 'Removing...' : 'Remove'}
                </Button>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  )
}

interface AppParticipantProps extends RouteComponentProps {
  user?: User
  setStep: (step: { step: string; props: any }) => void
}

export const AppParticipant: React.FC<AppParticipantProps & any> = ({ setStep, ...props }) => {
  const { loading, data, loading: userLoading } = useQuery(USER_QUERY, { fetchPolicy: 'no-cache' })

  const [maxErrors, setErrors] = useState<any>()

  const [showCreate, setShowCreate] = useState(false)
  const [showSearch, setSearch] = useState(false)
  const [formatError] = useFormatApolloError()

  const { reset } = useForm()
  const {
    loading: resLoading,
    data: resData,
    refetch
  } = useQuery(GET_RESPONDENTS, {
    fetchPolicy: 'no-cache',
    variables: {
      assignmentId: props.assignmentId
    }
  })

  const [optIn, { loading: optInLoading }] = useMutation(OPT_IN, {
    onError: (error) => {
      setErrors(formatError(error))
    },
    onCompleted: (data) => {
      setErrors('')
      reset()
      refetch({
        assignmentId: props.assignmentId
      })
    }
  })

  useEffect(() => {
    if (resData?.respondents.length >= props.maxRespondents) {
      setErrors('You have reached maximum number of participants')
    }
  }, [resData])

  const [deleteRespondent, { loading: deleteRespLoading }] = useMutation(DELETE_RESPONDENT, {
    onCompleted: (data) => {
      setErrors('')
      refetch({
        assignmentId: props.assignmentId
      })
    }
  })

  const [updateAssignment] = useMutation(UPDATE_ASSIGNMENT)

  const handleDeleteRespondent = (userId: string) => {
    if (userId) {
      deleteRespondent({
        variables: {
          respondentId: userId,
          assignmentId: props.assignmentId
        }
      }).then((response) => {
        if (response?.data) {
          console.log(response?.data)
        }
      })
    }
  }

  if (loading || userLoading) return <Loader color={Color.PURPLE} />
  const { me: user } = data

  return (
    <main>
      <HeadingPageCenter
        className="text-center mb-10"
        headline="Survey Participants"
        color={Color.BLUE}
        description={parse(
          'Fill in the form below to add new participants to the Assessment. The assessment will be asking questions about your organisation, so it is important to invite participants who know your organisation well.'
        )}
      />
      <div className="section-container-sm">
        <div className="flex flex-col">
          <div className="flex-1 flex flex-col">
            <HeadingSection headline="Add participants" color={Color.BLUE} className="mb-4" />
            <AddParticipantsModal
              showCreate={showCreate}
              showSearch={showSearch}
              setShowCreate={setShowCreate}
              setSearch={setSearch}
              refetch={refetch}
              assignmentId={props.assignmentId}
              orgId={user.organization.id}
              respondentCount={resData?.respondents.length}
              maxRespondents={props.maxRespondents}
            />
            <div className="flex space-x-2">
              <div className="flex-1 ">
                <Button
                  iconRight="plus"
                  className="justify-center"
                  variant={ButtonVariant.PRIMARY}
                  size={ButtonSize.SMALL}
                  color={Color.BLUE}
                  style={{ width: '100%' }}
                  type="submit"
                  onClick={() => {
                    setShowCreate(true)
                  }}
                >
                  Add new
                </Button>
              </div>
              <div className="flex-1 ">
                <Button
                  className="justify-center"
                  variant={ButtonVariant.PLAIN}
                  size={ButtonSize.SMALL}
                  color={Color.BLUE}
                  style={{ width: '100%' }}
                  type="submit"
                  iconRight="search"
                  onClick={() => {
                    setSearch(true)
                  }}
                >
                  Add existing
                </Button>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col ">
            <RespondentList
              loading={resLoading}
              respondents={resData?.respondents}
              handleDeleteRespondent={handleDeleteRespondent}
              deleteRespLoading={deleteRespLoading}
            />
            <div className="flex items-center col-span-2">
              <input
                id="opt-in"
                type="checkbox"
                className="h-4 w-4 text-brand-purple focus:ring-purple-800 border-gray-300 rounded mt-2 mb-2"
                name="opt-in"
                defaultChecked={resData?.respondents.some((respondent: any) => respondent.user.email === user.email)}
                onChange={(e) => {
                  if (!e.target.checked) {
                    const respondentId = resData?.respondents.find((r: any) => r.user.email === user.email)
                    handleDeleteRespondent(respondentId.id)
                  } else {
                    optIn({
                      variables: {
                        assignmentId: props.assignmentId,
                        doSurvey: e.target.checked
                      }
                    })
                  }
                }}
              />
              <label htmlFor="opt-in" className="ml-2 block text-sm text-gray-900">
                {optInLoading ? 'Loading' : 'I am a participant'}
              </label>
            </div>
            <div className="flex items-center col-span-2">
              <input
                id="canViewResults"
                type="checkbox"
                className="h-4 w-4 text-brand-purple focus:ring-purple-800 border-gray-300 rounded mt-2 mb-2"
                onChange={(e) => {
                  updateAssignment({
                    variables: {
                      id: props.assignmentId,
                      input: {
                        canViewResults: e.target.checked
                      }
                    }
                  })
                }}
              />
              <label htmlFor="canViewResults" className="ml-2 block text-sm text-gray-900">
                Respondents can view organisational (rolled up) results in addition to their own results
              </label>
            </div>
            <div className="flex space-x-2">
              <div className="flex-1 ">
                <Button
                  className="justify-center"
                  variant={ButtonVariant.PLAIN}
                  size={ButtonSize.LARGE}
                  color={Color.BLUE}
                  style={{ width: '100%', height: '100%' }}
                  type="submit"
                  onClick={() => {
                    setStep({ step: 'create-assignment', props: props })
                  }}
                >
                  <Icon className="pr-1" name="arrowLeft" />
                  Back
                </Button>
              </div>
              <div className="flex-1">
                <Button
                  iconRight="arrowRight"
                  className="justify-center"
                  variant={ButtonVariant.PRIMARY}
                  size={ButtonSize.LARGE}
                  color={Color.BLUE}
                  style={{ width: '100%' }}
                  type="submit"
                  onClick={() => {
                    if (!resData?.respondents.length) {
                      setErrors('Please add a user before progressing')
                    } else {
                      setStep({ step: 'pre-survey', props: props })
                    }
                  }}
                >
                  Next
                </Button>
              </div>
            </div>

            {maxErrors && (
              <Alert variant={AlertVariant.ERROR} size={AlertSize.SMALL} children={maxErrors} className="w-100 mt-2" />
            )}
          </div>
        </div>
      </div>
    </main>
  )
}

interface ModalProps extends RouteComponentProps {
  showCreate: boolean
  showSearch: boolean
  setShowCreate: (showCreate: boolean) => void
  setSearch: (showSearch: boolean) => void
  refetch: any
  assignmentId: string
  orgId: string
  respondentCount: number
  maxRespondents: number
}

const AddParticipantsModal: React.FC<ModalProps> = ({
  showCreate,
  showSearch,
  setShowCreate,
  setSearch,
  refetch,
  assignmentId,
  orgId,
  maxRespondents,
  respondentCount
}) => {
  const [error, setErrors] = useState<any>()
  const [email, setEmail] = useState<string>('')

  const [formatError] = useFormatApolloError()

  const [getUser, { loading: userLoading, data: userData }] = useLazyQuery(GET_USER, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      if (!data.findUser) {
        setErrors('No users found. Please add a new participant by clicking "ADD NEW" button.')
      }
    }
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()

  const [createRespondent, { loading }] = useMutation(CREATE_RESPONDENT, {
    onError: (error) => {
      const err = formatError(error)
      //ugly hack of a solution but the createRespondent function on the api is being reused so error message is not relatable to adding a respondent
      if (err === 'User already exists') {
        setErrors('User already exists. Please use a different email address or add an existing user.')
      } else {
        setErrors(err)
      }
    },
    onCompleted: (data) => {
      setShowCreate(false)
      reset()
      refetch({
        assignmentId: assignmentId
      })
    }
  })

  const [createRespondentProfile, { loading: createProfileLoading }] = useMutation(CREATE_RESPONDENT_PROFILE, {
    onError: (error) => {
      setErrors(formatError(error))
    },
    onCompleted: (data) => {
      setSearch(false)
      refetch({
        assignmentId: assignmentId
      })
    }
  })

  useEffect(() => {
    if (showCreate || showSearch) {
      setErrors('')
    }
  }, [showCreate, showSearch])

  const onRegister = async (data: any) => {
    createRespondent({
      variables: {
        input: { ...data, organizationId: orgId },
        assignmentId: assignmentId
      }
    }).then((response) => {
      if (response?.data?.createRespondent?.id) {
        reset()
      }
    })
  }

  return (
    <>
      <Modal isModalOpen={showCreate} title="" onClose={() => setShowCreate(false)}>
        <div className="flex-1  flex flex-col">
          <HeadingSection headline="Add new participant" color={Color.BLUE} />
          <form
            action="#"
            autoComplete="no"
            onSubmit={handleSubmit(onRegister)}
            className="mt-0 grid grid-cols-2 gap-y-4  sm:gap-x-8"
          >
            {form.map((field) => {
              if (field.type === 'checkBox') {
                return (
                  <Box className="flex items-center col-span-2">
                    <input
                      id={field.name}
                      type="checkbox"
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                      {...register(field.name)}
                    />
                    <label htmlFor={field.name} className="ml-2 block text-sm text-gray-900">
                      {field.label}
                    </label>
                    {errors[field.name]?.message && (
                      <p className="text-sm text-red-500">{errors[field.name].message}</p>
                    )}
                  </Box>
                )
              }

              return (
                <Box className="col-span-2 sm:col-span-2" key={field.name}>
                  <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
                    {field.label}
                  </label>

                  {field.type === 'text' && (
                    <Input as="input" variant="standard" width="full" {...register(field.name, { ...field.options })} />
                  )}
                  {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
                </Box>
              )
            })}
            <div className="col-span-2">
              {respondentCount >= maxRespondents && (
                <Alert
                  size={AlertSize.SMALL}
                  variant={AlertVariant.ERROR}
                  children="Maximum number of respondents reached"
                />
              )}

              {error && (
                <Alert size={AlertSize.SMALL} variant={AlertVariant.ERROR} children={error} className="w-100" />
              )}

              <Button
                className="justify-center mb-2 mt-3"
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.MEDIUM}
                color={Color.BLUE}
                type="submit"
                disabled={respondentCount >= maxRespondents}
                iconRight="arrowRight"
              >
                {loading ? 'Loading...' : 'Add participant'}
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal isModalOpen={showSearch} title="" onClose={() => setSearch(false)}>
        <div className="flex-1  flex flex-col">
          <HeadingSection headline="Search user" color={Color.BLUE} />
          <div className="mt-0 grid grid-cols-2 gap-y-4  sm:gap-x-8">
            <Box className="col-span-2 sm:col-span-2" key="email">
              <label htmlFor="email" className="text-base font-medium text-brand-purple my-1 inline-block">
                Email
              </label>
              <Input
                as="input"
                variant="standard"
                width="full"
                onChange={(e) => {
                  e.preventDefault()
                  setEmail(e.target.value)
                }}
              />
            </Box>

            <div className="col-span-2">
              <Button
                className="justify-center mb-2"
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.MEDIUM}
                color={Color.BLUE}
                onClick={() => {
                  setErrors('')
                  getUser({ variables: { email } })
                }}
              >
                {userLoading ? 'Loading...' : 'Search'}
              </Button>
            </div>
          </div>
          <div className="flex-0.5 mb-2 py-3">
            {userData?.findUser && (
              <div className="flex text-md text-brand-purple justify-between">
                <div className="mt-2">{`${userData.findUser.firstName} ${userData.findUser.lastName} ${userData.findUser.email} `}</div>
                <Button
                  variant={ButtonVariant.PLAIN}
                  size={ButtonSize.SMALL}
                  color={Color.PURPLE}
                  className="mt-2 "
                  onClick={() => {
                    setErrors('')
                    createRespondentProfile({ variables: { userId: userData.findUser.id, assignmentId: assignmentId } })
                  }}
                >
                  {createProfileLoading ? 'Loading' : 'Add'}
                </Button>
              </div>
            )}
          </div>
          {error && <Alert size={AlertSize.SMALL} variant={AlertVariant.ERROR} children={error} className="w-100" />}
        </div>
      </Modal>
    </>
  )
}

export const CREATE_RESPONDENT = gql`
  mutation createRespondent($input: CreateUserInput!, $assignmentId: String!) {
    createRespondent(input: $input, assignmentId: $assignmentId) {
      id
    }
  }
`
export const CREATE_RESPONDENT_PROFILE = gql`
  mutation createRespondentProfile($userId: String!, $assignmentId: String!) {
    createRespondentProfile(userId: $userId, assignmentId: $assignmentId) {
      id
    }
  }
`
const GET_RESPONDENTS = gql`
  query respondents($assignmentId: String!) {
    respondents(assignmentId: $assignmentId) {
      id
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`

const OPT_IN = gql`
  mutation surveyOptIn($doSurvey: Boolean!, $assignmentId: String!) {
    surveyOptIn(doSurvey: $doSurvey, assignmentId: $assignmentId) {
      message
      status
      action
    }
  }
`
const DELETE_RESPONDENT = gql`
  mutation deleteRespondent($respondentId: String!, $assignmentId: String!) {
    deleteRespondent(respondentId: $respondentId, assignmentId: $assignmentId) {
      message
      status
    }
  }
`
const GET_USER = gql`
  query getUser($email: String!) {
    findUser(email: $email) {
      id
      firstName
      lastName
      email
    }
  }
`
const UPDATE_ASSIGNMENT = gql`
  mutation updateAssignment($input: UpdateAssignmentInput!, $id: String!) {
    updateAssignment(input: $input, assignmentId: $id) {
      id
    }
  }
`
const USER_QUERY = gql`
  query _user {
    me {
      organization {
        id
        name
      }
    }
  }
`
