import { Document, BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer'
import BlockquoteWrapper from './BlockquoteWrapper'
import StyleTextWrapper from './StyleTextWrapper'

export interface RichTextProps {
  content: { links?: any; json: Document }
}

const options = (links: any): Options => {
  // create an asset map
  // const assetMap = new Map()
  // loop through the assets and add them to the map
  // for (const asset of links.assets.block) {
  //   assetMap.set(asset.sys.id, asset)
  // }

  // create an entry map
  const entryMap = new Map()
  // loop through the block linked entries and add them to the map
  if (links) {
    for (const entry of links.entries.block) {
      entryMap.set(entry.sys.id, entry)
    }

    // loop through the inline linked entries and add them to the map
    for (const entry of links.entries.inline) {
      entryMap.set(entry.sys.id, entry)
    }
  }

  return {
    renderMark: {
      [MARKS.BOLD]: (text: any) => <span className="font-medium">{text}</span>
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.QUOTE]: (node, children) => {
        // return
        return <h1>quote</h1>
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        // find the entry in the entryMap by ID
        const entry = entryMap.get(node.data.target.sys.id)

        // render the entries as needed
        if (entry.__typename === 'LayoutBlockquote') {
          return <BlockquoteWrapper {...entry} />
        }

        return 'no block entry found'
      },
      // other options...
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        // find the entry in the entryMap by ID
        const entry = entryMap.get(node.data.target.sys.id)

        // render the entries as needed
        if (entry.__typename === 'StyleText') {
          return <StyleTextWrapper {...entry} />
        }
      }
    },

    renderText: (text: any) => text.replace('!', '?')
  }
}

const RichTextRenderer = ({ content }: RichTextProps): JSX.Element => {
  return <>{documentToReactComponents(content.json, options(content.links))}</>
}

export default RichTextRenderer
